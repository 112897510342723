import React from 'react';
import { Footer, Header, SideBar } from 'components';
import { Outlet } from 'react-router-dom';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

function Index() {
  return (
    <div className="min-h-full flex flex-col min-w-full w-fit">
      <Header />
      <main className="flex flex-row grow">
        <div className="h-full sticky right-0 top-0 md:block hidden">
          <SideBar />
        </div>
        <div className="bg-white flex w-full flex-col justify-between overflow-y-auto">
          <Outlet />
          <Footer />
        </div>
      </main>
    </div>
  );
}

export default Index;
