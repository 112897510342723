import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'lib/axios';
// import { paymentURL } from 'config';
import { pricingURL } from 'config';
import toast from 'react-hot-toast';

import {
  deleteDsOfficeFail,
  deleteDsOfficeRequested,
  deleteDsOfficeSuccess,
  loadDsOfficeFail,
  loadDsOfficeRequested,
  loadDsOfficeSuccess,
  storeDsOfficeFail,
  storeDsOfficeRequested,
  storeDsOfficeSuccess,
  updateDsOfficeFail,
  updateDsOfficeRequested,
  updateDsOfficeSuccess,
} from './dsOfficeSlice';



function* dsOfficeEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
    
  try {
    const { data } = yield call(
      axios.get,
      `${pricingURL}ds_division`
      
    );
    console.log('SSS',data)
    yield put(loadDsOfficeSuccess(data));
  } catch (error: any) {
    yield put(loadDsOfficeFail(error.message));
  }
}

function* updateDsOfficeEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.put,
      `${pricingURL}ds_division/${action.payload.id}`,
      action.payload
    );
    yield put(updateDsOfficeSuccess(data));

    if (data?.success) {
      toast.success(data?.message);
      yield put(loadDsOfficeRequested(data));
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(updateDsOfficeFail(error.message));
  }
}
function* storeDsOfficeEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const response = yield call(
      axios.post,
      `${pricingURL}ds_division`,
      action.payload
    );
    yield put(storeDsOfficeSuccess(response.data));
    if (response?.data) {
      toast.success(response?.data?.message);
      yield put(loadDsOfficeRequested(response.data));
    }
  } catch (error: any) {
    yield put(storeDsOfficeFail(error.message));
  }
}

function* deleteDsOfficeEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.put,
      `${pricingURL}vehicleModel/active?id=${action?.payload?.item?.id}`,
      action.payload.values
    );
    if (data?.success) {
      toast.success(data?.message);
      yield put(deleteDsOfficeSuccess(data));
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(deleteDsOfficeFail(error.message));
  }
}




export function* dsOfficeSaga(): Generator<any, void, any> {
  yield takeLatest(loadDsOfficeRequested, dsOfficeEffect);
  yield takeLatest(updateDsOfficeRequested, updateDsOfficeEffect);
  yield takeLatest(storeDsOfficeRequested, storeDsOfficeEffect);
  yield takeLatest(deleteDsOfficeRequested, deleteDsOfficeEffect);
}
