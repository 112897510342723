import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  error: null,
  standPremium: [],
  updateStandPremium: null,
  createStandPremium: null,
  standPremiumDelete: {},
};

const standPremiumSlice = createSlice({
  name: 'standPremium',
  initialState,
  reducers: {
    loadStandPremiumsRequested(state, action) {
      state.loading = true;
      state.error = null;
    },
    loadStandPremiumsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.standPremium = action.payload;
    },

    loadStandPremiumsFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    updateStandPremiumRequested(
      state,
      action: PayloadAction<{
        values: {
          id: string;
          name: string;
          amount: string;
          expiryDuration: string;
          releasePercentage: string;
          discountPercentage: string;
          discount: string;
          description: string;
        };
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    updateStandPremiumSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.updateStandPremium = action.payload;
    },

    updateStandPremiumFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    createStandPremiumRequested(
      state,
      action: PayloadAction<{
        values: {
          name: any;
          amount: any;
          expiryDuration: any;
          releasePercentage: any;
          discountPercentage: any;
          discount: any;
          description: any;
        };
      }>
    ) {
      state.loading = true;
      state.error = null;
    },

    createStandPremiumSuccess(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = null;
      state.createStandPremium = action.payload;
    },

    createStandPremiumFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    deleteStandPremiumRequested(
      state,
      action: PayloadAction<{
        item: any;
      }>
    ) {
      state.loading = false;
      state.error = null;
      state.standPremiumDelete = action.payload;
    },
    deleteStandPremiumSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.standPremiumDelete = action.payload;
    },

    deleteStandPremiumFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadStandPremiumsRequested,
  loadStandPremiumsSuccess,
  loadStandPremiumsFail,
  updateStandPremiumRequested,
  updateStandPremiumSuccess,
  updateStandPremiumFail,
  createStandPremiumRequested,
  createStandPremiumSuccess,
  createStandPremiumFail,
  deleteStandPremiumRequested,
  deleteStandPremiumSuccess,
  deleteStandPremiumFail,
} = standPremiumSlice.actions;

export default standPremiumSlice.reducer;
