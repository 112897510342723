import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  error: null,
  dashboard: {},
  tripStatus:{},
  userAnalytics:{},
};

const dashBoardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    loadDashBoardRequested(
      state,
      action: PayloadAction<{
        startDate?: string;
        endDate?: string;
        // data_type?: string;
        // district?: string;
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    loadDashBoardSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.dashboard = action.payload;
    },
    loadDashBoardFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    loadDashBoardTripRequested(
      state,
      action: PayloadAction<{
        startDate?: string;
        endDate?: string;
        // data_type?: string;
        // district?: string;
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    loadDashBoardTripSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.tripStatus = action.payload;
    },
    loadDashBoardTripFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    loadDashBoardUserAnalyticsRequested(
      state,
      action: PayloadAction<{
        startDate?: string;
        endDate?: string;
        // data_type?: string;
        // district?: string;
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    loadDashBoardUserAnalyticsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.userAnalytics = action.payload;
    },
    loadDashBoardUserAnalyticsFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadDashBoardRequested,
  loadDashBoardSuccess,
  loadDashBoardFail,
  loadDashBoardTripRequested,
  loadDashBoardTripSuccess,
  loadDashBoardTripFail,
  loadDashBoardUserAnalyticsRequested,
  loadDashBoardUserAnalyticsSuccess,
  loadDashBoardUserAnalyticsFail,
} = dashBoardSlice.actions;

export default dashBoardSlice.reducer;
