import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  error: null,
  withdrawal: [],
  withdrawalApprove: {},
  withdrawalById: {},
  paginationOption: {},
};

const withdrawalSlice = createSlice({
  name: 'withdrawal',
  initialState,
  reducers: {
    loadWithdrawalRequestsRequested(
      state,
      action: PayloadAction<{ filters: any; perPage: number; page: number }>
    ) {
      state.loading = true;
      state.error = null;
    },
    loadwithdrawalRequestsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.withdrawal = action.payload;
      state.paginationOption = {
        pageNumber: action.payload?.pageNumber,
        pageSize: 10,
        totalRecords: action.payload?.totalRecords,
      };
    },

    loadwithdrawalRequestsFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    loadWithdrawalRequested(state, action: PayloadAction<{ id: any }>) {
      state.loading = true;
      state.error = null;
    },
    loadWithdrawalSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.withdrawalById = action.payload;
    },

    loadWithdrawalFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    loadWithdrawalApproveRequested(
      state,
      action: PayloadAction<{
        id: any;
        values: {
          userId: string;
          adminReason: string;
          approvedAmount: string;
          status: string;
        };
      }>
    ) {
      state.loading = true;
      state.error = null;
      state.withdrawalApprove = undefined;
    },
    loadWithdrawalApproveSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.withdrawalApprove = action.payload;
    },

    loadWithdrawalApproveFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadWithdrawalRequestsRequested,
  loadwithdrawalRequestsSuccess,
  loadwithdrawalRequestsFail,
  loadWithdrawalApproveRequested,
  loadWithdrawalApproveSuccess,
  loadWithdrawalApproveFail,
  loadWithdrawalRequested,
  loadWithdrawalSuccess,
  loadWithdrawalFail,
} = withdrawalSlice.actions;

export default withdrawalSlice.reducer;
