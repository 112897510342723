import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  error: null,
  role: [],
  updateRole: null,
  createRole: '',
  roleDelete: {},
  assignRole: null,
};

const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    loadRolesRequested(state, action) {
      state.loading = true;
      state.error = null;
    },
    loadRolesSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.role = action.payload;
    },

    loadRolesFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    updateRoleRequested(
      state,
      action: PayloadAction<{
        values: {
          id: string;
          name: string;
        };
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    updateRoleSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.updateRole = action.payload;
    },

    updateRoleFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    createRoleRequested(
      state,
      action: PayloadAction<{
        values: {
          name: string;
        };
      }>
    ) {
      state.loading = true;
      state.error = null;
    },

    createRoleSuccess(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = null;
      state.createRole = action.payload;
    },

    createRoleFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    deleteRoleRequested(
      state,
      action: PayloadAction<{
        item: any;
      }>
    ) {
      state.loading = false;
      state.error = null;
      state.roleDelete = action.payload;
    },
    deleteRoleSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.roleDelete = action.payload;
    },

    deleteRoleFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },

    assignRolePermissionRequested(
      state,
      action: PayloadAction<{ roleId: any; permissions: any }>
    ) {
      state.loading = true;
      state.error = null;
    },

    assignRolePermissionSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.assignRole = action.payload;
    },

    assignRolePermissionFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadRolesRequested,
  loadRolesSuccess,
  loadRolesFail,
  createRoleRequested,
  createRoleSuccess,
  createRoleFail,
  updateRoleRequested,
  updateRoleSuccess,
  updateRoleFail,
  deleteRoleRequested,
  deleteRoleSuccess,
  deleteRoleFail,
  assignRolePermissionRequested,
  assignRolePermissionSuccess,
  assignRolePermissionFail,
} = roleSlice.actions;

export default roleSlice.reducer;
