import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'lib/axios';
import { paymentURL } from 'config';

import {
  createChallengeFail,
  createChallengeRequested,
  createChallengeSuccess,
  deleteChallengeFail,
  deleteChallengeRequested,
  deleteChallengeSuccess,
  loadChallengeFail,
  loadChallengeRequested,
  loadChallengeSuccess,
  loadChallengesFail,
  loadChallengesRequested,
  loadChallengesSuccess,
} from './challengesSlice';

function* loadChallengesEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const response = yield call(
      axios.get,
      `${paymentURL}challenges_policy`,
      action.payload
    );
    yield put(loadChallengesSuccess(response.data));
  } catch (error: any) {
    yield put(loadChallengesFail(error.message));
  }
}

function* loadChallengeEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.get,
      `${paymentURL}challenges?user_id=${action?.payload?.id}`
    );
    yield put(loadChallengeSuccess(data));
  } catch (error: any) {
    yield put(loadChallengeFail(error.message));
  }
}

function* createChallengeEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const response = yield call(
      axios.post,
      `${paymentURL}challenges_policy`,
      action.payload.values
    );

    yield put(createChallengeSuccess(response.data));
  } catch (error: any) {
    yield put(createChallengeFail(error.message));
  }
}

function* deleteChallengeEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const response = yield call(
      axios.delete,
      `${paymentURL}challenges_policy/${action?.payload?.item?.id}`,
      action.payload.values
    );
    yield put(deleteChallengeSuccess(response.data));
  } catch (error: any) {
    yield put(deleteChallengeFail(error.message));
  }
}

export function* challengesSaga(): Generator<any, void, any> {
  yield takeLatest(loadChallengesRequested, loadChallengesEffect);
  yield takeLatest(createChallengeRequested, createChallengeEffect);
  yield takeLatest(deleteChallengeRequested, deleteChallengeEffect);
  yield takeLatest(loadChallengeRequested, loadChallengeEffect);
}
