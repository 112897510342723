import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'lib/axios';
import { tripURL } from 'config';

import { 
     loadSheduleRideFail,
     loadSheduleRideRequested, 
     loadSheduleRidesFail, 
     loadSheduleRidesRequested, 
     loadSheduleRidesSuccess, 
     loadSheduleRideSuccess } from './sheduleRideSlice';



  function* loadSheduleRidesEffect(action: {
    type: string;
    payload: {
      filters: any;
      perPage: number;
      page: number;
    };
  }): Generator<any, void, any> {
    const { filters, perPage, page } = action.payload;
    try {
      const response = yield call(
        axios.get,
        `${tripURL}trip-app/scheduled-ride/trip-histories?${
          filters?.provincePlace?.label
            ? '&province=' + filters?.provincePlace?.label
            : ''
        }${
          filters?.districtPlace?.label
            ? '&district=' + filters?.districtPlace?.label
            : ''
        }${
          filters?.cityPlace?.label ? '&city=' + filters?.cityPlace?.label : ''
        }${filters?.cabType?.value ? '&cabType=' + filters?.cabType?.value : ''}${
          filters?.tripStatus?.id ? '&status=' + filters?.tripStatus?.id : ''
        }&offset=${page}&limit=${perPage}`
      );
      yield put(loadSheduleRidesSuccess(response.data));
    } catch (error: any) {
      yield put(loadSheduleRidesFail(error.message));
    }
  }
  
  function* loadSheduleRideEffect(action: {
    type: string;
    payload: any;
  }): Generator<any, void, any> {
    try {
      
      const { data } = yield call(
        axios.get,
        `${tripURL}trip-app/scheduled-ride/${action?.payload?.id}`
      );
      yield put(loadSheduleRideSuccess(data));
      console.log('TTt',data)
    } catch (error: any) {
      yield put(loadSheduleRideFail(error.message));
    }
  } 

  export function* sheduleRidesSaga(): Generator<any, void, any> {
    yield takeLatest(loadSheduleRidesRequested, loadSheduleRidesEffect);
    yield takeLatest(loadSheduleRideRequested, loadSheduleRideEffect);
    
  }
  