import React from 'react';
import { Steps } from 'primereact/steps';

const Stepper: React.FC<{
  items: object | any;
  activeIndex: number;
}> = ({ items, activeIndex }) => {
  return (
    <div className="card">
      <Steps model={items} activeIndex={activeIndex} />
    </div>
  );
};

export default Stepper;
