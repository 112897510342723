import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducer';
import { clearFilters, setSelectedOption } from 'features';

import Columns from './Menu';

function Index() {
  const navigation = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const [columns] = useState<any>([]);
  const { auth } = useSelector(
    (state: RootState) => state.auth
  );
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  console.log(loading);
  // console.log(selectedOption);

  async function filterOptions() {
    for (let i = 0; i < Columns.length; i++) {
      // console.log('q', Columns[i]);
      for (let j = 0; j < auth?.roles?.length; j++) {
        for (let k = 0; k < Columns.length; k++) {
          // console.log(auth?.roles[j]?.permissions[k]?.value ,Columns[i]?.name)

          if (
            auth?.roles[j]?.permissions[k]?.value === Columns[i]?.name &&
            !columns.includes(Columns[i])
          ) {
            columns.push(Columns[i]);
          }
        }
      }
    }
  }

  useEffect(() => {
    filterOptions().then(() => setLoading(false));
  }, [Columns]);

  return (
    <React.Fragment>
      <div
        className={` ${
          open ? 'w-72  ' : ' w-16'
        }  transition-all  bg-gray-100 p-2 relative overscroll-y-contain duration-300 md:block hidden h-screen overflow-y-scroll no-scrollbar overflow-x-visible`}
        // style={{ height: 'inherit' }}
      >
        <img
          src={require('assets/images/png/control.png')}
          className={`absolute z-10 cursor-pointer -right-3 top-4 w-7 border-gray-100
           border-4 rounded-full  ${!open && 'rotate-180'}`}
          onClick={() => setOpen(!open)}
          alt="control"
        />

        {columns.map((item: any, key: any) => {
          return item?.subMenu?.length ? (
            <details className="group mt-3  space-y-1 cursor-pointer" key={key}>
              <summary className="flex items-center p-[5px] px-3 font-medium rounded-lg hover:bg-green-200">
                {open && item?.icon && (
                  <item.icon
                    className={`mr-2 w-5 h-10  ${
                      location.pathname === item?.to
                        ? 'text-white'
                        : 'text-[#6389da]'
                    }  `}
                  />
                )}

                <span
                  className={`ml-3 text-sm  ${
                    location.pathname === item?.to ? 'text-white' : 'text-black'
                  }  tracking-widest font-semibold`}
                >
                  {open && item?.name}{' '}
                </span>

                {open && (
                  <AiOutlineCaretDown className="ml-auto transition duration-300 shrink-0 group-open:-rotate-180" />
                )}
              </summary>

              {item?.subMenu?.map((value: any, index: any) => (
                <div key={index}>
                  <div
                    className="mt-3 ml-8 flex flex-col"
                    onClick={() => {
                      dispatch(clearFilters());
                      navigation(`${value?.to}`);
                    }}
                  >
                    {open && (
                      <div
                        className={`flex items-center p-[5px] px-3 font-medium rounded ${
                          location.pathname === value?.to
                            ? 'bg-[#6389da]'
                            : null
                        }`}
                      >
                        {open && item?.icon && (
                          <value.icon
                            className={`mr-2 w-5 h-10  ${
                              location.pathname === value?.to
                                ? 'text-white'
                                : 'text-[#6389da]'
                            }  `}
                          />
                        )}
                        <span
                          className={`ml-3 text-sm  ${
                            location.pathname === value?.to
                              ? 'text-white'
                              : 'text-black'
                          }  tracking-widest font-semibold`}
                        >
                          {open && value?.name}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </details>
          ) : (
            <div key={key}>
              <div
                className={`flex flex-col mt-4 cursor-pointer`}
                onClick={() => {
                  dispatch(setSelectedOption(item?.name));
                  dispatch(clearFilters());
                  navigation(`${item?.to}`);
                }}
              >
                <div
                  className={`flex items-center py-[5px] px-2 font-medium rounded ${
                    location.pathname === item?.to ? 'bg-[#6389da]' : null
                  }`}
                >
                  {item?.icon && (
                    <item.icon
                      className={`mr-2 w-5 h-10  ${
                        location.pathname === item?.to
                          ? 'text-white'
                          : 'text-[#6389da]'
                      }  `}
                    />
                  )}

                  <span
                    className={`${open && 'ml-3'} text-sm  ${
                      location.pathname === item?.to
                        ? 'text-white'
                        : 'text-black'
                    }  tracking-widest font-semibold`}
                  >
                    {open && item?.name}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
}

export default Index;
