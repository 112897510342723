import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  driverloading: false,
  error: null,
  drivers: [],
  premiumDrivers: [],
  nonPaginationDrivers: [],
  driver: null,
  updateDriver: {},
  UpdateDriverImg: {},
  paginationOption: {},
  storeDriver: null,
  driverWithdrawal: {},
  referrerCode: {},
  topUp: {},
  Offer :{},
  payableAmount: {},
  standPremiumImage: null,
  standPremiumDetails: null,
  standPremiumDocVerification: null,
  attendance: null,
  transactionSummary:{},
  exportPdf:{},
  live:{},
  CntNew: 0,
  CntBike: 0,
  CntAuto: 0,
  CntCar: 0,
  CntVan: 0,
  luxuryCarCount : 0,
  carCount : 0,
  semiLuxuryCount : 0,
  normalCarCount : 0,
  totalCount: 0,
  tripCount: 0,

};

const driversSlice = createSlice({
  name: 'drivers',
  initialState,
  reducers: {
    loadDriversRequested(
      state,
      action: PayloadAction<{ filters: any; perPage: number; page: number }>
    ) {
      state.driverloading = true;
      state.error = null;
      state.driver = null;
    },
    loadDriversSuccess(state, action: PayloadAction<any>) {
      console.log("payload",action.payload)
      state.driverloading = false;
      state.error = null;
      state.drivers = action.payload.records;
      state.paginationOption = {
        pageNumber: action.payload?.pageNumber,
        pageSize: 10,
        totalRecords: action.payload?.totalRecords,
      };
    },

    loadDriversFail(state, action: PayloadAction<string>) {
      state.driverloading = false;
      state.error = action.payload;
    },

    loadPremiumDriversRequested(
      state,
      action: PayloadAction<{ filters: any; perPage: number; page: number }>
    ) {
      state.driverloading = true;
      state.error = null;
    },
    loadPremiumDriversSuccess(state, action: PayloadAction<any>) {
      state.driverloading = false;
      state.error = null;
      state.premiumDrivers = action.payload.records;
      state.paginationOption = {
        pageNumber: action.payload?.pageNumber,
        pageSize: 10,
        totalRecords: action.payload?.totalRecords,
      };
    },

    loadPremiumDriversFail(state, action: PayloadAction<string>) {
      state.driverloading = false;
      state.error = action.payload;
    },

    loadNonPaginationDriversRequested(state, action: PayloadAction<any>) {
      state.driverloading = true;
      state.error = null;
    },
    loadNonPaginationDriversSuccess(state, action: PayloadAction<any>) {
      state.driverloading = false;
      state.error = null;
      state.nonPaginationDrivers = action.payload;
    },
    loadNonPaginationDriversFail(state, action: PayloadAction<string>) {
      state.driverloading = false;
      state.error = action.payload;
    },

    loadDriverRequested(state, action: PayloadAction<{ id: any }>) {
      state.driverloading = true;
      state.error = null;
      state.driver = null;
    },
    loadDriverSuccess(state, action: PayloadAction<any>) {
      state.driverloading = false;
      state.error = null;
      state.driver = action.payload;
    },

    loadDriverFail(state, action: PayloadAction<string>) {
      state.driverloading = false;
      state.error = action.payload;
    },

    updateDriverRequested(
      state,
      action: PayloadAction<{
        id: any;
        values: {
          firstName: string;
          lastName: string;
          dateOfBirth: string;
          addressLine1: string;
          addressLine2: string;
          city: string;
          province: string;
          // country: string;
          // email: string;
          licenceNo: string;
          licenceAddress: string;
          licenceIssueDate: any;
          licenceExpiryDate: any;
          bloodGroup: any;
          documentAddress: any;
          allowedVehicleTypes: any;
        };
      }>
    ) {
      state.driverloading = true;
      state.error = null;
    },
    updateDriverSuccess(state, action: any) {
      state.driverloading = false;
      state.error = null;
      state.updateDriver = action.payload;
    },

    updateDriverFail(state, action: PayloadAction<string>) {
      state.driverloading = false;
      state.error = action.payload;
    },

    updateDriverDocumentRequested(
      state,
      action: PayloadAction<{
        id: any;
        driverProfileImg: any;
        addressVerifyDoc: any;
        addressBackImg: any;
        licenceVerifyDoc: any;
        licenceBackImg: any;
      }>
    ) {
      state.driverloading = true;
      state.error = null;
    },
    updateDriverDocumentSuccess(state, action: any) {
      state.driverloading = false;
      state.error = null;
      state.UpdateDriverImg = action.payload;
    },

    updateDriverDocumentFail(state, action: PayloadAction<string>) {
      state.driverloading = false;
      state.error = action.payload;
    },

    storeDriverRequested(
      state,
      action: PayloadAction<{
        values: {
          addressLine1: string;
          addressLine2: string;
          city: { label: string; value: string };
          province: { id: string; name: string };
          dateOfBirth: string;
          email: string;
          firstName: string;
          lastName: string;
          phoneNo: string;
          district: { label: string; value: string };
        };
        addressVerifyDoc: any;
        addressBackImg: any;
        licenceVerifyDoc: any;
        licenceBackImg: any;
        driverProfileImg: any;
      }>
    ) {
      state.driverloading = true;
      state.error = null;
    },
    storeDriverSuccess(state, action: PayloadAction<any>) {
      state.driverloading = false;
      state.error = null;
      state.storeDriver = action.payload;
    },
    storeDriverFail(state, action: PayloadAction<string>) {
      state.driverloading = false;
      state.error = action.payload;
    },

    driverDocumentsVerificationRequested(
      state,
      action: PayloadAction<{
        values: { documentType: string; isVerified: boolean; reason: string };
        id: any;
      }>
    ) {
      state.driverloading = true;
      state.error = null;
    },
    driverDocumentsVerificationSuccess(state, action: PayloadAction<any>) {
      state.driverloading = false;
      state.error = null;
      state.storeDriverDocumentVerification = action.payload;
    },
    driverDocumentsVerificationFail(state, action: PayloadAction<string>) {
      state.driverloading = false;
      state.error = action.payload;
    },

    driverVerificationRequested(
      state,
      action: PayloadAction<{
        id: any;
      }>
    ) {
      state.driverloading = true;
      state.error = null;
    },
    driverVerificationSuccess(state, action: PayloadAction<any>) {
      state.driverloading = false;
      state.error = null;
      state.storeDriverVerification = action.payload;
    },
    driverVerificationFail(state, action: PayloadAction<string>) {
      state.driverloading = false;
      state.error = action.payload;
    },

    deleteDriverRequested(
      state,
      action: PayloadAction<{
        item: any;
      }>
    ) {
      state.driverloading = false;
      state.error = null;
      state.driver = action.payload;
    },
    deleteDriverSuccess(state, action: PayloadAction<any>) {
      state.driverloading = false;
      state.error = null;
      state.driver = action.payload;
    },

    deleteDriverFail(state, action: PayloadAction<any>) {
      state.driverloading = false;
      state.error = action.payload;
    },

    loadDriverWithdrawalRequested(state, action: PayloadAction<{ id: any }>) {
      state.driverloading = true;
      state.error = null;
    },
    loadDriverWithdrawalSuccess(state, action: PayloadAction<any>) {
      state.driverloading = false;
      state.error = null;
      state.driverWithdrawal = action.payload.records;
    },

    loadDriverWithdrawalFail(state, action: PayloadAction<string>) {
      state.driverloading = false;
      state.error = action.payload;
    },

    loadDriverTopUpRequested(state, action: PayloadAction<{ id: any }>) {
      state.driverloading = true;
      state.error = null;
    },
    loadDriverTopUpSuccess(state, action: PayloadAction<any>) {
      state.driverloading = false;
      state.error = null;
      state.topUp = action.payload.records;
    },

    loadDriverTopUpFail(state, action: PayloadAction<string>) {
      state.driverloading = false;
      state.error = action.payload;
    },

    loadDriverReferrerCodeRequested(state, action: PayloadAction<{ id: any }>) {
      state.driverloading = true;
      state.error = null;
    },
    loadDriverReferrerCodeSuccess(state, action: PayloadAction<any>) {
      state.driverloading = false;
      state.error = null;
      state.referrerCode = action.payload.records;
    },

    loadDriverReferrerCodeFail(state, action: PayloadAction<string>) {
      state.driverloading = false;
      state.error = action.payload;
    },
    updateStandPremiumDocumentRequested(
      state,
      action: PayloadAction<{
        user_id: any;
        cab_id: any;
        membershipFrontImage: any;
        standPremiumId: any;
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    updateStandPremiumDocumentSuccess(state, action: any) {
      state.loading = false;
      state.error = null;
      state.standPremiumImage = action.payload;
    },
    updateStandPremiumDocumentFail(state, action: any) {
      state.loading = false;
      state.error = action.payload;
    },
    loadStandPremiumDocumentRequest(
      state,
      action: PayloadAction<{
        user_id: any;
        image: boolean;
      }>
    ) {
      state.standPremiumImage = null;
      state.loading = true;
      state.error = null;
    },
    loadStandPremiumDocumentSuccess(state, action: any) {
      console.log('stand premium state', action);
      state.loading = false;
      state.error = null;
      if (action?.payload?.membershipFrontImage) {
        state.standPremiumImage = action.payload;
      } else {
        state.standPremiumImage = null;
      }
    },
    loadStandPremiumDocumentFail(state, action: any) {
      state.loading = false;
      state.error = action.payload;
    },
    updateStandPremiumDetailsRequested(
      state,
      action: PayloadAction<{
        standPremium: string;
        id: string;
        user_id: string;
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    updateStandPremiumDetailsSuccess(state, action: any) {
      state.loading = false;
      state.error = null;
      state.standPremiumDetails = action.payload;
    },
    updateStandPremiumDetailsFail(state, action: any) {
      state.loading = false;
      state.error = action.payload;
    },
    verifyStandPremiumDetailsRequested(
      state,
      action: PayloadAction<{
        id: string;
        reasonForStatus: any;
        status: any;
        user_id: any;
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    verifyStandPremiumDetailsSuccess(state, action: any) {
      state.loading = false;
      state.error = null;
      state.standPremiumDocVerification = action.payload;
    },
    verifyStandPremiumDetailsFail(state, action: any) {
      state.loading = false;
      state.error = action.payload;
    },
    loadDriverAttendanceRequested(
      state,
      action: PayloadAction<{ id: any; date: any }>
    ) {
      state.driverloading = true;
      state.attendance = null;
      state.error = null;
    },
    loadDriverAttendanceSuccess(state, action: PayloadAction<any>) {
      state.driverloading = false;
      state.error = null;
      state.attendance = action.payload;
    },

    loadDriverAttendanceFail(state, action: PayloadAction<string>) {
      state.driverloading = false;
      state.error = action.payload;
    },
    loadDriverPayableAmountRequested(
      state,
      action: PayloadAction<{ id: any }>
    ) {
      state.driverloading = true;
      state.error = null;
    },
    loadDriverPayableAmountSuccess(state, action: PayloadAction<any>) {
      state.driverloading = false;
      state.error = null;
      state.payableAmount = action.payload;
    },

    loadDriverPayableAmountFail(state, action: PayloadAction<string>) {
      state.driverloading = false;
      state.error = action.payload;
    },
    loadDriversSummaryRequested(
      state,
      action: PayloadAction<{ actionType: string | undefined }>
    ) {
      state.loading = true;
      state.error = null;
    },
    loadDriversSummarySuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.CntNew = action.payload.data.TODAY_JOINERS;
      state.CntBike = action.payload.data.BIKE;
      state.CntAuto = action.payload.data.TUK_TUK;
      state.CntCar = action.payload.data.TOTAL_CAR;
      state.CntVan = action.payload.data.VAN;
      state.luxuryCarCount = action.payload.data.LUXURY_CAR;
      state.semiLuxuryCount = action.payload.data.SEMI_LUXURY_CAR;
      state.carCount = action.payload.data.CAR;
      state.normalCarCount = action.payload.data.NORMAL_CAR;
      state.totalCount =
        action.payload.data.BIKE +
        action.payload.data.TUK_TUK +
        action.payload.data.TOTAL_CAR +
        action.payload.data.VAN;
    },
    loadDriversSummaryFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    loadDriverTripCountRequested(
      state,
      action: PayloadAction<{ user_id: any }>
    ) {
      state.loading = true;
      state.error = null;
      state.tripCount = 0;
    },
    loadDriverTripCountSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.tripCount = action.payload;
    },
    loadDriverTripCountFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    loadDriverOfferRequested(state, action: PayloadAction<{ id: any }>) {
      state.driverloading = true;
      state.error = null;
    },
    loadDriverOfferSuccess(state, action: PayloadAction<any>) {
      state.driverloading = false;
      state.error = null;
      state.Offer = action.payload.records;
    },

    loadDriverOfferFail(state, action: PayloadAction<string>) {
      state.driverloading = false;
      state.error = action.payload;
    },

    createOfferRequest(
      state,
      action: PayloadAction<{
        values: {
          cab_id?: string;
          user_id: string;
          amount: any;
          message:string;
          phone_no:string;
        };
      }>
    ) {
      state.createdOffer = null;
      state.loading = true;
      state.error = null;
      state.Offer = null;
    },

    createOfferSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.createdOffer = action.payload;
    },

    createOfferFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    loadDriverTransactionSummaryRequested(
      state,
      action: PayloadAction<{ id: any }>
    ) {
      state.driverloading = true;
      state.transactionSummary = null;
      state.error = null;
    },
    loadDriverTransactionSummarySuccess(state, action: PayloadAction<any>) {
      state.driverloading = false;
      state.error = null;
      state.transactionSummary = action.payload;
    },

    loadDriverTransactionSummaryFail(state, action: PayloadAction<string>) {
      state.driverloading = false;
      state.error = action.payload;
    },
    loadDriverExportPdfRequested(
      state,
      action: PayloadAction
    ) {
      state.driverloading = true;
      state.exportPdf = null;
      state.error = null;
     
    },
    loadDriverExportPdfSuccess(state, action: PayloadAction<any>) {
      state.driverloading = false;
      state.error = null;
      state.exportPdf = action.payload;
      
    },

    loadDriverExportPdfFail(state, action: PayloadAction<string>) {
      state.driverloading = false;
      state.error = action.payload;
    },
    loadDriverExportExcelRequested(
      state,
      action: PayloadAction
    ) {
      state.driverloading = true;
      state.exportExcel = null;
      state.error = null;
     
    },
    loadDriverExportExcelSuccess(state, action: PayloadAction<any>) {
      state.driverloading = false;
      state.error = null;
      state.exportExcel = action.payload;
      
    },

    loadDriverExportExcelFail(state, action: PayloadAction<string>) {
      state.driverloading = false;
      state.error = action.payload;
    },
    loadDriverLiveLocationRequested(
      state,
      action: PayloadAction<{ id: any;}>
    ) {
      state.driverloading = true;
      state.live = null;
      state.error = null;
     
    },
    loadDriverLiveLocationSuccess(state, action: PayloadAction<any>) {
      state.driverloading = false;
      state.error = null;
      state.live = action.payload;
      
    },

    loadDriverLiveLocationFail(state, action: PayloadAction<string>) {
      state.driverloading = false;
      state.error = action.payload;
    },


  },
});

export const {
  loadDriversRequested,
  loadDriversSuccess,
  loadDriversFail,
  loadPremiumDriversRequested,
  loadPremiumDriversSuccess,
  loadPremiumDriversFail,
  loadNonPaginationDriversRequested,
  loadNonPaginationDriversSuccess,
  loadNonPaginationDriversFail,
  loadDriverRequested,
  loadDriverSuccess,
  loadDriverFail,
  updateDriverRequested,
  updateDriverFail,
  updateDriverSuccess,
  updateDriverDocumentRequested,
  updateDriverDocumentSuccess,
  updateDriverDocumentFail,
  storeDriverRequested,
  storeDriverSuccess,
  storeDriverFail,
  driverDocumentsVerificationFail,
  driverDocumentsVerificationRequested,
  driverDocumentsVerificationSuccess,
  driverVerificationFail,
  driverVerificationRequested,
  driverVerificationSuccess,
  deleteDriverRequested,
  deleteDriverSuccess,
  deleteDriverFail,
  loadDriverWithdrawalRequested,
  loadDriverWithdrawalSuccess,
  loadDriverWithdrawalFail,
  loadDriverTopUpRequested,
  loadDriverTopUpSuccess,
  loadDriverTopUpFail,
  loadDriverReferrerCodeRequested,
  loadDriverReferrerCodeSuccess,
  loadDriverReferrerCodeFail,
  updateStandPremiumDocumentFail,
  updateStandPremiumDocumentRequested,
  updateStandPremiumDocumentSuccess,
  loadStandPremiumDocumentRequest,
  loadStandPremiumDocumentSuccess,
  loadStandPremiumDocumentFail,
  updateStandPremiumDetailsFail,
  updateStandPremiumDetailsRequested,
  updateStandPremiumDetailsSuccess,
  verifyStandPremiumDetailsFail,
  verifyStandPremiumDetailsRequested,
  verifyStandPremiumDetailsSuccess,
  loadDriverAttendanceRequested,
  loadDriverAttendanceSuccess,
  loadDriverAttendanceFail,
  loadDriverPayableAmountRequested,
  loadDriverPayableAmountSuccess,
  loadDriverPayableAmountFail,
  loadDriversSummaryRequested,
  loadDriversSummarySuccess,
  loadDriversSummaryFail,
  loadDriverTripCountRequested,
  loadDriverTripCountSuccess,
  loadDriverTripCountFail,
  loadDriverOfferRequested,
  loadDriverOfferSuccess,
  loadDriverOfferFail,
  createOfferRequest,
  createOfferSuccess,
  createOfferFail,
  loadDriverTransactionSummaryRequested,
  loadDriverTransactionSummarySuccess,
  loadDriverTransactionSummaryFail,
  loadDriverExportPdfRequested,
  loadDriverExportPdfSuccess,
  loadDriverExportPdfFail,
  loadDriverExportExcelRequested,
  loadDriverExportExcelSuccess,
  loadDriverExportExcelFail,
  loadDriverLiveLocationRequested,
  loadDriverLiveLocationSuccess,
  loadDriverLiveLocationFail
} = driversSlice.actions;

export default driversSlice.reducer;
