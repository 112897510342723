import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  error: null,
  brands: [],
  updateBrand: null,
  createBrand: null,
  brand: {},
  nonPaginatedBrands:[],
};

const brandSlice = createSlice({
  name: 'brand',
  initialState,
  reducers: {
    loadBrandsRequested(state, action) {
      state.loading = true;
      state.error = null;
    },
    loadBrandsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.brands = action?.payload?.records;
      state.paginationOption = {
        pageNumber: action.payload?.pageNumber,
        pageSize: 10,
        totalRecords: action.payload?.totalRecords,
      };
    },

    loadBrandsFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    loadNonPaginatedBrandsRequested(state, action: PayloadAction<{ id: any}>) {
      state.loading = true;
      state.error = null;
    },
    loadNonPaginatedBrandsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.nonPaginatedBrands = action?.payload;
    },

    loadNonPaginatedBrandsFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    updateBrandRequested(
      state,
      action: PayloadAction<{
        values: {
          id: string;
          vehicleBrandName: string;
        };
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    updateBrandSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.updateBrand = action.payload;
    },

    updateBrandFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    createBrandRequested(
      state,
      action: PayloadAction<{
        values: {
          cabType: string;
          vehicleBrandName: string;
        };
      }>
    ) {
      state.loading = true;
      state.error = null;
    },

    createBrandSuccess(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = null;
      state.createBrand = action.payload;
    },

    createBrandFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    deleteBrandRequested(
      state,
      action: PayloadAction<{
        item: any;
      }>
    ) {
      state.loading = false;
      state.error = null;
      state.brand = action.payload;
    },
    deleteBrandSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.brand = action.payload;
    },

    deleteBrandFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadBrandsFail,
  loadBrandsSuccess,
  loadBrandsRequested,
  updateBrandRequested,
  updateBrandSuccess,
  updateBrandFail,
  createBrandRequested,
  createBrandSuccess,
  createBrandFail,
  deleteBrandRequested,
  deleteBrandSuccess,
  deleteBrandFail,
  loadNonPaginatedBrandsFail,
  loadNonPaginatedBrandsRequested,
  loadNonPaginatedBrandsSuccess,
} = brandSlice.actions;

export default brandSlice.reducer;
