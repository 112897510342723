import * as React from 'react';
import Lottie from 'lottie-react';
import Nodata from 'assets/animations/NoData/nodata-1.json';

export default function NoData() {
  return (
    <React.Fragment>
      <div className="flex justify-center py-40">
        <Lottie className="w-96 h-1/12" animationData={Nodata} />
      </div>
    </React.Fragment>
  );
}
