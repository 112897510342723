import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'lib/axios';
import { paymentURL } from 'config';
import toast from 'react-hot-toast';

import {
  loadChallengesUserRequested,
  loadChallengesUserSuccess,
  loadChallengesUserFail,
  adminBuyChallengeRequested,
  adminBuyChallengeSuccess,
  adminBuyChallengeFail,
} from './challengesuserSlice';

function* loadChallengesUserEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const response = yield call(
      axios.get,
      `${paymentURL}challenges/get_challenges`,
      action.payload
    );
    // console.log(response);
    yield put(loadChallengesUserSuccess(response.data));
  } catch (error: any) {
    yield put(loadChallengesUserFail(error.message));
  }
}

function* adminBuyChalllengeEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.post,
      `${paymentURL}challenges/admin/buy?user_id=${action?.payload?.id}${
        action?.payload?.cabId ? `&cab_id=${action?.payload?.cabId}` : ''
      }&policy_id=${action?.payload?.challengeId}`
    );
    yield put(adminBuyChallengeSuccess(data));
    if (data?.success) {
      toast.success(data?.message);
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(adminBuyChallengeFail(error.message));
    toast.error('Something went wrong! Check the user and try again.');
  }
}

export function* challengesuserSaga(): Generator<any, void, any> {
  yield takeLatest(loadChallengesUserRequested, loadChallengesUserEffect);
  yield takeLatest(adminBuyChallengeRequested, adminBuyChalllengeEffect);
}
