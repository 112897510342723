// src/store/rootSaga.ts
import { all } from 'redux-saga/effects';
import {
  usersSaga,
  authSaga,
  driversSaga,
  cabsSaga,
  brandsSaga,
  alertsSaga,
  ridesSaga,
  modelsSaga,
  tripsSaga,
  offersSaga,
  passengersSaga,
  rolesSaga,
  promotionsSaga,
  cancelReasonsSaga,
  pricingSaga,
  bannersSaga,
  withdrawalSaga,
  challengesSaga,
  topUpSaga,
  placeSaga,
  permissionSaga,
  standPremiumSaga,
  challengesuserSaga,
  subscriptionPlanSaga,
  subscriptionPlanUserSaga,
  sheduleRidesSaga,
  dashboardSaga,
  notificationSaga,
  dsOfficeSaga,
  
  
} from 'features';

export function* rootSaga() {
  yield all([
    usersSaga(),
    authSaga(),
    driversSaga(),
    cabsSaga(),
    brandsSaga(),
    modelsSaga(),
    alertsSaga(),
    ridesSaga(),
    tripsSaga(),
    offersSaga(),
    passengersSaga(),
    rolesSaga(),
    promotionsSaga(),
    cancelReasonsSaga(),
    pricingSaga(),
    bannersSaga(),
    withdrawalSaga(),
    challengesSaga(),
    topUpSaga(),
    placeSaga(),
    permissionSaga(),
    standPremiumSaga(),
    challengesuserSaga(),
    subscriptionPlanSaga(),
   subscriptionPlanUserSaga(),
   sheduleRidesSaga(),
    dashboardSaga(),
    notificationSaga(),
    dsOfficeSaga(),
  ]);
}
