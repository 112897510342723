import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'lib/axios';
// import { paymentURL } from 'config';
import { apiURL, paymentURL,tripURL } from 'config';

import {
  loadDashBoardFail,
  loadDashBoardRequested,
  loadDashBoardSuccess,
  loadDashBoardTripFail,
  loadDashBoardTripRequested,
  loadDashBoardTripSuccess,
  loadDashBoardUserAnalyticsFail,
  loadDashBoardUserAnalyticsRequested,
  loadDashBoardUserAnalyticsSuccess,
} from './dashBoardSlice';

function* loadDashBoardSummaryEffect(action: {
  type: any;
  payload: any;
}): Generator<any, void, any> {
  try {
    const response = yield call(
      axios.get,
      `${paymentURL}get-revenue?start=${action.payload.endDate}&end=${action.payload.startDate}`
    );

    const defaultReponseIncome = [
      { name: 'JANUARY', amount: 0 },
      { name: 'FEBRUARY', amount: 0 },
      { name: 'MARCH', amount: 0 },
      { name: 'APRIL', amount: 0 },
      { name: 'MAY', amount: 0 },
      { name: 'JUNE', amount: 0 },
      { name: 'JULY', amount: 0 },
      { name: 'AUGUST', amount: 0 },
      { name: 'SEPTEMBER', amount: 0 },
      { name: 'OCTOBER', amount: 0 },
      { name: 'NOVEMBER', amount: 0 },
      { name: 'DECEMBER', amount: 0 },
    ];
    const defaultReponseProfit = [
      { name: 'JANUARY', amount: 0 },
      { name: 'FEBRUARY', amount: 0 },
      { name: 'MARCH', amount: 0 },
      { name: 'APRIL', amount: 0 },
      { name: 'MAY', amount: 0 },
      { name: 'JUNE', amount: 0 },
      { name: 'JULY', amount: 0 },
      { name: 'AUGUST', amount: 0 },
      { name: 'SEPTEMBER', amount: 0 },
      { name: 'OCTOBER', amount: 0 },
      { name: 'NOVEMBER', amount: 0 },
      { name: 'DECEMBER', amount: 0 },
    ];

    console.log(response)

    const formattedResponseIncome = defaultReponseIncome.map((item: any) => {
      if (response.data.income.some((i: any) => i.name === item.name)) {
        return response.data.income.find((i: any) => i.name === item.name);
      } else {
        return item;
      }
    });
    const formattedResponseProfit = defaultReponseProfit.map((item: any) => {
      if (response.data.profit.some((i: any) => i.name === item.name)) {
        return response.data.profit.find((i: any) => i.name === item.name);
      } else {
        return item;
      }
    });
    console.log(formattedResponseIncome)
    yield put(loadDashBoardSuccess({income:formattedResponseIncome, profit:formattedResponseProfit}));
  } catch (error: any) {
    yield put(loadDashBoardFail(error.message));
  }
}

function* loadDashBoardTripSummaryEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  //const { id} = action.payload;
  console.log('SSS',action.payload)

  try {
    const { data } = yield call(
      axios.get,
      `${tripURL}trip-app/trip/trip-count/group-by-date?start=${action.payload.startDate}&end=${action.payload.endDate}`
    );
    console.log('DDD',data);
    yield put(loadDashBoardTripSuccess(data));
  } catch (error: any) {
    yield put(loadDashBoardTripFail(error.message));
  }
}

function* loadDashBoardUserAnalyticsEffect(action: {
  type: any;
  payload: any;
}): Generator<any, void, any> {
  try {
    const response = yield call(
      axios.get,
      `${apiURL}admin/analytics/user-registrations?start=${action.payload.endDate}&end=${action.payload.startDate}&timePeriod=MONTH`
    );

    const defaultReponseDriver = [
      { name: 'JANUARY', amount: 0 },
      { name: 'FEBRUARY', amount: 0 },
      { name: 'MARCH', amount: 0 },
      { name: 'APRIL', amount: 0 },
      { name: 'MAY', amount: 0 },
      { name: 'JUNE', amount: 0 },
      { name: 'JULY', amount: 0 },
      { name: 'AUGUST', amount: 0 },
      { name: 'SEPTEMBER', amount: 0 },
      { name: 'OCTOBER', amount: 0 },
      { name: 'NOVEMBER', amount: 0 },
      { name: 'DECEMBER', amount: 0 },
    ];
    const defaultReponseRider = [
      { name: 'JANUARY', amount: 0 },
      { name: 'FEBRUARY', amount: 0 },
      { name: 'MARCH', amount: 0 },
      { name: 'APRIL', amount: 0 },
      { name: 'MAY', amount: 0 },
      { name: 'JUNE', amount: 0 },
      { name: 'JULY', amount: 0 },
      { name: 'AUGUST', amount: 0 },
      { name: 'SEPTEMBER', amount: 0 },
      { name: 'OCTOBER', amount: 0 },
      { name: 'NOVEMBER', amount: 0 },
      { name: 'DECEMBER', amount: 0 },
    ];

    console.log(response)

    const formattedResponseDriver = defaultReponseDriver.map((item: any) => {
      if (response.data.DRIVER.some((i: any) => i.name === item.name)) {
        return response.data.DRIVER.find((i: any) => i.name === item.name);
      } else {
        return item;
      }
    });
    const formattedResponseRider = defaultReponseRider.map((item: any) => {
      if (response.data.RIDER.some((i: any) => i.name === item.name)) {
        return response.data.RIDER.find((i: any) => i.name === item.name);
      } else {
        return item;
      }
    });
    console.log(formattedResponseDriver)
    yield put(loadDashBoardUserAnalyticsSuccess({DRIVER:formattedResponseDriver, RIDER:formattedResponseRider}));
    
  } catch (error: any) {
    yield put(loadDashBoardUserAnalyticsFail(error.message));
  }
}


export function* dashboardSaga(): Generator<any, void, any> {
  yield takeLatest(loadDashBoardRequested, loadDashBoardSummaryEffect);
  yield takeLatest(loadDashBoardTripRequested, loadDashBoardTripSummaryEffect);
  yield takeLatest(loadDashBoardUserAnalyticsRequested, loadDashBoardUserAnalyticsEffect);
}
