import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  Loading: false,
  error: null,
  subscriptionPlan: [],
  paginationOption: {},
  storeSubscriptionPlan:{},
    updateSubscriptionPlan:{},
};

const subscriptionPlanSlice = createSlice({
  name: 'subscriptionPlan',
  initialState,
  reducers: {
    loadSubscriptionPlanRequested(
      state,
      action: PayloadAction<{
        perPage: number;
        page: number;
        filters: any;
      }>
    ) {
      console.log('sdsdfsdsdfsdf');
      state.Loading = true;
      state.error = null;
    },
    loadSubscriptionPlanSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.subscriptionPlan = action.payload;
      state.paginationOption = {
        pageNumber: action.payload?.pageNumber,
        pageSize: 10,
        totalRecords: action.payload?.totalRecords,
      };
      console.log('asdafsdsv');
    },
    loadSubscriptionPlanFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    storeSubscriptionPlanRequested(
      state,
      action: PayloadAction<{
        values: {
         id:string;
         name:string;
         cutOffPercentage:string;
         price:string;
         cabType:string;
         province:string;
         releasePercentage:string;
         validityDuration:string;
         description:string
        };
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    storeSubscriptionPlanSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.storeSubscriptionPlan = action.payload;
    },

    storeSubscriptionPlanFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    updateSubscriptionPlanRequested(
      state,
      action: PayloadAction<{
        values: {
         id:string;
         name:string;
         cutOffPercentage:string;
         price:string;
         cabType:string;
         province:string;
         releasePercentage:string;
         validityDuration:string;
         description:string
        };
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    updateSubscriptionPlanSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.updateSubscriptionPlan = action.payload;
    },

    updateSubscriptionPlanFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    deleteSubscriptionPlanRequested(
      state,
      action: PayloadAction<{
        item: any;
      }>
    ) {
      state.loading = false;
      state.error = null;
      state.driver = action.payload;
    },
    deleteSubscriptionPlanSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.driver = action.payload;
    },

    deleteSubscriptionPlanFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadSubscriptionPlanRequested,
  loadSubscriptionPlanSuccess,
  loadSubscriptionPlanFail,
  storeSubscriptionPlanRequested,
  storeSubscriptionPlanSuccess,
  storeSubscriptionPlanFail,
  updateSubscriptionPlanRequested,
  updateSubscriptionPlanFail,
  updateSubscriptionPlanSuccess,
  deleteSubscriptionPlanRequested,
  deleteSubscriptionPlanSuccess,
  deleteSubscriptionPlanFail,


} = subscriptionPlanSlice.actions;

export default subscriptionPlanSlice.reducer;
