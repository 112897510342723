import React from 'react';
import { ConfirmPopup } from 'primereact/confirmpopup';

import { Button } from '../Button';

interface IConfirmationProps {
  setVisible: (data: any) => void;
  accept?: () => void;
  visible?: boolean;
  message?: any;
}

function Confirmation(props: IConfirmationProps) {
  const modalFooter = (
    <div className="flex justify-between px-4">
      <Button name="X No" onClick={() => props.setVisible(false)} />

      <Button name="✓ Yes" onClick={props.accept} />
    </div>
  );

  return (
    <div>
      <ConfirmPopup
        visible={props.visible}
        onHide={() => props.setVisible(false)}
        message={props.message}
        className="text-sm p-6"
        icon="pi pi-exclamation-triangle"
        reject={() => props.setVisible(false)}
        footer={modalFooter}
      />
    </div>
  );
}

export default Confirmation;
