import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
    loading: false,
    error: null,
    dsOffice: {},
    paginationOption: {},
  };

  const dsOfficeSlice = createSlice({
    name: 'dsOffice',
    initialState,
    reducers: {
      loadDsOfficeRequested(
        state,
        action: PayloadAction<{
          
           district?: string;
        }>
      ) {
        state.loading = true;
        state.error = null;
      },
      loadDsOfficeSuccess(state, action: PayloadAction<any>) {
        state.loading = false;
        state.error = null;
        state.dsOffice = action.payload;
      },
      loadDsOfficeFail(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },
      loadNonPaginatedDsOfficeRequested(state, action: PayloadAction<any>) {
        state.loading = true;
        state.error = null;
      },
      loadNonPaginatedDsOfficeSuccess(state, action: PayloadAction<any>) {
        state.loading = false;
        state.error = null;
        state.nonPaginatedDsOffice = action?.payload;
      },
  
      loadNonPaginatedDsOfficeFail(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },
      updateDsOfficeRequested(
        state,
        action: PayloadAction<{
          id: any;
          values: {
            id: string;
            district: string;
            dsDivisionName: string;
            latitude:string,
            longitude:string,
          };
        }>
      ) {
        state.loading = true;
        state.error = null;
      },
      updateDsOfficeSuccess(state, action: PayloadAction<any>) {
        state.loading = false;
        state.error = null;
        state.updateDsOffice = action.payload;
      },
  
      updateDsOfficeFail(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },
      storeDsOfficeRequested(
        state,
        action: PayloadAction<{
          values: {
            id: string;
            district: string;
            dsDivisionName: string;
            latitude:string,
            longitude:string,
            
          };
          
        }>
      ) {
        state.loading = true;
        state.error = null;
      },
      storeDsOfficeSuccess(state, action: PayloadAction<any>) {
        state.loading = false;
        state.error = null;
        state.storeDsOffice = action.payload;
        
      },
  
      storeDsOfficeFail(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },
  
      deleteDsOfficeRequested(
        state,
        action: PayloadAction<{
          item: any;
        }>
      ) {
        state.loading = false;
        state.error = null;
        state.DsOffice = action.payload;
      },
      deleteDsOfficeSuccess(state, action: PayloadAction<any>) {
        state.loading = false;
        state.error = null;
        state.DsOffice = action.payload;
      },
      deleteDsOfficeFail(state, action: PayloadAction<any>) {
        state.loading = false;
        state.error = action.payload;
      },
  
    },
  });
  
  export const {
    loadDsOfficeRequested,
    loadDsOfficeSuccess,
    loadDsOfficeFail,
    loadNonPaginatedDsOfficeRequested,
    loadNonPaginatedDsOfficeSuccess,
    loadNonPaginatedDsOfficeFail,
    storeDsOfficeRequested,
    storeDsOfficeSuccess,
    storeDsOfficeFail,
    updateDsOfficeRequested,
    updateDsOfficeSuccess,
    updateDsOfficeFail,
    deleteDsOfficeRequested,
    deleteDsOfficeSuccess,
    deleteDsOfficeFail,
  } = dsOfficeSlice.actions;
  
  export default dsOfficeSlice.reducer;
  