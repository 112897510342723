import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  error: null,
  promotions: [],
  createPromotion: '',
  updatePromotion: null,
  promotion: {},
};

const promotionsSlice = createSlice({
  name: 'promotions',
  initialState,
  reducers: {
    loadPromotionsRequested(state, action) {
      state.loading = true;
      state.error = null;
    },
    loadPromotionsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.promotions = action.payload;
    },

    loadPromotionsFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    createPromotionRequested(
      state,
      action: PayloadAction<{
        values: {
          title: string;
          description: string;
          startDate: string;
          endDate: string;
        };
        promotionImage: any;
      }>
    ) {
      state.loading = true;
      state.error = null;
    },

    createPromotionSuccess(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = null;
      state.createPromotion = action.payload;
    },

    createPromotionFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    updatePromotionRequested(
      state,
      action: PayloadAction<{
        values: {
          id: any;
          title: string;
          description: string;
          startDate: any;
          endDate: any;
        };
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    updatePromotionSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.updatePromotion = action.payload;
    },

    updatePromotionFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    deletePromotionRequested(
      state,
      action: PayloadAction<{
        item: any;
      }>
    ) {
      state.loading = false;
      state.error = null;
      state.promotion = action.payload;
    },
    deletePromotionSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.promotion = action.payload;
    },

    deletePromotionFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadPromotionsRequested,
  loadPromotionsSuccess,
  loadPromotionsFail,
  createPromotionRequested,
  createPromotionSuccess,
  createPromotionFail,
  updatePromotionRequested,
  updatePromotionSuccess,
  updatePromotionFail,
  deletePromotionRequested,
  deletePromotionSuccess,
  deletePromotionFail,
} = promotionsSlice.actions;

export default promotionsSlice.reducer;
