import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BreadCrumb, Button, Modal, Search, SelectNV } from 'components';
import {
  cabTypeRequested,
  loadDestrictsRequested,
  loadModelsRequested,
  loadBrandsRequested,
  loadPlacesRequested,
  updateFilters,
} from 'features';
import { Link } from 'react-router-dom';
import { RootState } from 'store/reducer';
import Provinces from 'mock/Province';
import Cities from 'mock/City';
import DriverStatus from 'mock/DriverStatus';
import CabTypeBrand from 'mock/CabTypeBrand';
import TripStatus from 'mock/TripStatus';
import Verified from 'mock/Verified';
import PremiumDriverStatus from 'mock/PremiumDriverStatus';
// @ts-expect-error its working but error is showing
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import { MdClose } from 'react-icons/md';

type Props = {
  breadCrumbRoutes?: any;
  createRoute?: any;
  createModal?: any;
};

const districtsAll = [
  { id: 'Jaffna', name: 'Jaffna' },
  { id: 'Trincomalee', name: 'Trincomalee' },
  { id: 'Colombo', name: 'Colombo' },
  { id: 'Gampaha', name: 'Gampaha' },
  { id: 'Kalutara', name: 'Kalutara' },
  { id: 'Anuradhapura', name: 'Anuradhapura' },
  { id: 'Polonnaruwa', name: 'Polonnaruwa' },
  { id: 'Kurunegala', name: 'Kurunegala' },
  { id: 'Puttalam', name: 'Puttalam' },
  { id: 'Kegalle', name: 'Kegalle' },
  { id: 'Batticaloa', name: 'Batticaloa' },
  { id: 'Matale', name: 'Matale' },
  { id: 'Nuwara Eliya', name: 'Nuwara Eliya' },
  { id: 'Vavuniya', name: 'Vavuniya' },
  { id: 'Mannar', name: 'Mannar' },
  { id: 'Mullaitivu', name: 'Mullaitivu' },
  { id: 'Kilinochchi', name: 'Kilinochchi' },
  { id: 'Ampara', name: 'Ampara' },
  { id: 'Kandy', name: 'Kandy' },
  { id: 'Matara', name: 'Matara' },
  { id: 'Badulla', name: 'Badulla' },
  { id: 'Galle', name: 'Galle' },
  { id: 'Hambantota', name: 'Hambantota' },
  { id: 'Monaragala', name: 'Monaragala' },
  { id: 'Ratnapura', name: 'Ratnapura' },
  { id: 'Polonnaruwa', name: 'Polonnaruwa' },
];

const Filteration = (props: Props) => {
  const dispatch = useDispatch();

  const { filters } = useSelector((state: any) => state.state);
  const { districts } = useSelector((state: RootState) => state.pricing);
  const { cabType } = useSelector((state: RootState) => state.cab);
  const { brands } = useSelector((state: RootState) => state.brand);
  const { models } = useSelector((state: RootState) => state.model);
  const { places } = useSelector((state: RootState) => state.place);
  const { auth } = useSelector((state: RootState) => state.auth);
  console.log(districts)

  const filter = filters ? Object.keys(filters) : [];

  const [open, setOpen] = useState<boolean>(false);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const visible = (value: any) => {
    return filter?.find((e) => e === value) ? false : true;
  };

  const pushFilter = async (filter: any) => {
    await dispatch(updateFilters(filter));
  };
  const [searchQuery, setSearchQuery] = useState<string>('');
  useEffect(() => {
    dispatch(loadBrandsRequested({}));
    dispatch(loadDestrictsRequested({ id: '' }));
    dispatch(cabTypeRequested({}));
    dispatch(loadModelsRequested({}));
    dispatch(loadPlacesRequested({ id: '' }));
    // if (!auth?.roles.some((i: any) => i?.name === 'Admins')) {
    //   // debugger
    //   const updatedFilters = { ...filters };
    //   console.log(updatedFilters);
    //   updatedFilters.provincePlace = {
    //     label: auth?.province,
    //     value: auth?.province,
    //   };
    //   pushFilter(updatedFilters);
    // }
  }, [dispatch]);

  const modifiedDistricts = districts?.map((item: string) => ({
    id: item,
    name: item,
  }));
  // console.log(Provinces);

  const provinceDataSet = Object.keys(places);

  const provinceOption = provinceDataSet.map((item) => ({
    label: item
      .replace(/_/g, ' ') // Replace underscores with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()),
    value: item,
    //const selectedProvince = places[filters?.provincePlace?.value];
  }));

  const selectedProvince = places[filters?.provincePlace?.value];

  const districtOptions = selectedProvince
    ? Object.keys(selectedProvince).map((districtValue: string) => ({
        label: districtValue,
        value: districtValue,
        cities: selectedProvince[districtValue]?.cities || [],
      }))
    : [];

  const selectedDistrict = districtOptions.find(
    (district) => district.value === filters?.districtPlace?.value
  );

  const cityOptions = selectedDistrict
    ? selectedProvince[selectedDistrict.value]?.cities.map(
        (cityValue: string) => ({
          label: cityValue,
          value: cityValue,
        })
      ) || []
    : [];

  return (
    <div className="flex flex-col justify-between">
      <div className="flex justify-between items-center">
        <BreadCrumb routes={props.breadCrumbRoutes} />
        {props.createRoute && (
          <Link to={props.createRoute}>
            <Button
              name="Create"
              className="bg-[#6389da] text-[#ffffff] font-bold text-xs rounded p-3 w-36"
            />
          </Link>
        )}

        {props.createModal && (
          <Button
            name="Create"
            className="bg-[#6389da] text-white font-bold text-xs rounded p-3 w-36"
            onClick={() => props.createModal(true)}
          />
        )}
      </div>

      <div className="py-2 px-2 bg-gray-200 rounded-md items-center flex justify-between min-h-[52px]">
        <div className="flex items-center gap-5 h-fit">
          <div hidden={visible('driverStatus')} className="w-40">
            <SelectNV
              placeholder="Driver Status"
              options={DriverStatus}
              onChange={(item: any) => {
                const updatedFilters = { ...filters };
                updatedFilters.driverStatus = item;
                pushFilter(updatedFilters);
              }}
              defaulValue={filters.driverStatus}
              getOptionLabel="name"
              getOptionValue="id"
              values={filters.type}
            />
          </div>

          <div hidden={visible('active')} className="w-40">
            <SelectNV
              placeholder="Active"
              options={[
                {
                  label: 'True',
                  value: true,
                },
                {
                  label: 'False',
                  value: false,
                }
              ]}
              onChange={(item: any) => {
                const updatedFilters = { ...filters };
                updatedFilters.active = item;
                pushFilter(updatedFilters);
              }}
              defaulValue={filters.active}
              getOptionLabel="label"
              getOptionValue="value"
              values={filters.active}
            />
          </div>

          {auth?.roles.some((i: any) => i?.name === 'Admins') && (
            <div hidden={visible('province')} className="w-40">
              <SelectNV
                placeholder="Province"
                options={Provinces}
                onChange={(item: any) => {
                  const updatedFilters = { ...filters };
                  updatedFilters.province = item;
                  pushFilter(updatedFilters);
                }}
                defaulValue={filters.province}
                getOptionLabel="name"
                getOptionValue="id"
                values={filters.type}
              />
            </div>
          )}
          {auth?.roles.some((i: any) => i?.name === 'Admins') && (
            <div hidden={visible('districtPlace')} className="w-40">
              {filters.district && (
                <SelectNV
                  placeholder="District"
                  options={modifiedDistricts}
                  onChange={(item: any) => {
                    const updatedFilters = { ...filters };

                    if (item === null) {
                      updatedFilters.district = '';
                    } else {
                      updatedFilters.district = item;
                    }

                    pushFilter(updatedFilters);
                  }}
                  getOptionLabel="name"
                  getOptionValue="id"
                  values={filters.district}
                  required={true}
                  defaulValue={filters.district}
                  isClearable={false}
                />
              )}
            </div>
          )}

          {auth?.roles.some((i: any) => i?.name === 'Admins') && (
            <div hidden={visible('district')} className="w-40">
              {filters.district && (
                <SelectNV
                  placeholder="District"
                  options={modifiedDistricts}
                  onChange={(item: any) => {
                    const updatedFilters = { ...filters };

                    if (item === null) {
                      updatedFilters.district = '';
                    } else {
                      updatedFilters.district = item;
                    }

                    pushFilter(updatedFilters);
                  }}
                  getOptionLabel="name"
                  getOptionValue="id"
                  values={filters.district}
                  required={true}
                  defaulValue={filters.district}
                  isClearable={false}
                />
              )}
            </div>
          )}

          {auth?.roles.some((i: any) => i?.name === 'Admins') && (
            <div hidden={visible('provincePlace')} className="w-40">
              <SelectNV
                placeholder="Province"
                options={provinceOption}
                onChange={(item: any) => {
                  const updatedFilters = { ...filters };
                  console.log(item);
                  if (item === null) {
                    updatedFilters.provincePlace = '';
                  } else {
                    updatedFilters.provincePlace = item;
                  }

                  pushFilter(updatedFilters);
                }}
                defaulValue={filters.provincePlace}
                getOptionLabel="label"
                getOptionValue="value"
                values={filters.provincePlace}
              />
            </div>
          )}

          {auth?.roles.some((i: any) => i?.name === 'Admins') && (
            <div hidden={visible('districtPlace')} className="w-40">
              <SelectNV
                placeholder="District"
                options={districtOptions}
                onChange={(item: any) => {
                  const updatedFilters = { ...filters };
                  console.log(item);
                  if (item === null) {
                    updatedFilters.districtPlace = '';
                  } else {
                    updatedFilters.districtPlace = item;
                  }

                  pushFilter(updatedFilters);
                }}
                defaulValue={filters.districtPlace}
                getOptionLabel="label"
                getOptionValue="value"
                values={filters.districtPlace}
              />
            </div>
          )}

          {auth?.roles.some((i: any) => i?.name === 'Admins') && (
            <div hidden={visible('districtAll')} className="w-40">
              <SelectNV
                placeholder="District"
                options={districtsAll}
                onChange={(item: any) => {
                  const updatedFilters = { ...filters };

                  if (item === null) {
                    updatedFilters.districtAll = '';
                  } else {
                    updatedFilters.districtAll = item;
                  }

                  pushFilter(updatedFilters);
                }}
                defaulValue={filters.districtAll}
                getOptionLabel="name"
                getOptionValue="id"
                values={filters.districtAll}
              />
            </div>
          )}

          <div hidden={visible('cityPlace')} className="w-40">
            <SelectNV
              placeholder="City"
              options={cityOptions}
              onChange={(item: any) => {
                const updatedFilters = { ...filters };

                if (item === null) {
                  updatedFilters.cityPlace = '';
                } else {
                  updatedFilters.cityPlace = item;
                }

                pushFilter(updatedFilters);
              }}
              defaulValue={filters.cityPlace}
              getOptionLabel="label"
              getOptionValue="value"
              values={filters.cityPlace}
            />
          </div>

          <div hidden={visible('city')} className="w-40">
            <SelectNV
              placeholder="City"
              options={Cities}
              onChange={(item: any) => {
                const updatedFilters = { ...filters };
                updatedFilters.city = item;
                pushFilter(updatedFilters);
              }}
              getOptionLabel="name"
              getOptionValue="id"
              values={filters.type}
              defaulValue={filters.city}
            />
          </div>

          <div hidden={visible('cabType')} className="w-40">
            <SelectNV
              defaulValue={filters.cabType}
              placeholder="Cab Type"
              options={cabType}
              onChange={(item: any) => {
                const updatedFilters = { ...filters };
                updatedFilters.cabType = item;
                pushFilter(updatedFilters);
              }}
              getOptionLabel="label"
              getOptionValue="value"
              values={filters.cabType}
            />
          </div>

          <div hidden={visible('userType')} className="w-40">
            <SelectNV
              placeholder="User Type"
              options={[
                { label: 'DRIVER', value: 'DRIVER' },
                { label: 'PASSENGER', value: 'RIDER' },
              ]}
              onChange={(item: any) => {
                const updatedFilters = { ...filters };
                updatedFilters.userType = item;
                pushFilter(updatedFilters);
              }}
              defaulValue={filters.userType}
              getOptionLabel="label"
              getOptionValue="value"
              values={filters.userType}
            />
          </div>

          <div hidden={visible('defaultCabtype')} className="w-40">
            <SelectNV
              placeholder="cab type"
              options={CabTypeBrand}
              onChange={(item: any) => {
                const updatedFilters = { ...filters };
                // updatedFilters.defaultCabtype = item;
                // pushFilter(updatedFilters);

                if (item === null) {
                  updatedFilters.defaultCabtype = {};
                } else {
                  updatedFilters.defaultCabtype = item;
                }

                pushFilter(updatedFilters);
              }}
              defaulValue={filters.defaultCabtype}
              getOptionLabel="name"
              getOptionValue="id"
              values={filters.type}
            />
          </div>

          <div hidden={visible('brand')} className="w-40">
            <SelectNV
              placeholder="Brand"
              options={brands}
              onChange={(item: any) => {
                const updatedFilters = { ...filters };
                updatedFilters.brand = item;
                pushFilter(updatedFilters);
              }}
              getOptionLabel="vehicleBrandName"
              getOptionValue="id"
              values={filters.brand}
              defaulValue={filters.brand}
            />
          </div>

          <div hidden={visible('model')} className="w-40">
            <SelectNV
              placeholder="Model"
              options={models}
              onChange={(item: any) => {
                const updatedFilters = { ...filters };
                updatedFilters.model = item;
                pushFilter(updatedFilters);
              }}
              getOptionLabel="vehicleModelName"
              getOptionValue="id"
              values={filters.model}
              defaulValue={filters.model}
            />
          </div>

          <div hidden={visible('verify')} className="w-40">
            <SelectNV
              placeholder="Verify"
              options={Verified}
              onChange={(item: any) => {
                const updatedFilters = { ...filters };
                updatedFilters.verify = item;
                pushFilter(updatedFilters);
              }}
              defaulValue={filters.verify}
              getOptionLabel="name"
              getOptionValue="id"
              values={filters.type}
            />
          </div>

          <div hidden={visible('tripStatus')} className="w-48">
            <SelectNV
              placeholder="Status"
              defaulValue={filters.tripStatus}
              options={TripStatus}
              onChange={(item: any) => {
                const updatedFilters = { ...filters };
                updatedFilters.tripStatus = item;
                pushFilter(updatedFilters);
              }}
              getOptionLabel="name"
              getOptionValue="id"
              values={filters.type}
            />
          </div>

          <div hidden={visible('premiumStatus')} className="w-40">
            <SelectNV
              defaulValue={filters.premiumStatus}
              placeholder="Status"
              options={PremiumDriverStatus}
              onChange={(item: any) => {
                const updatedFilters = { ...filters };
                updatedFilters.premiumStatus = item;
                pushFilter(updatedFilters);
              }}
              getOptionLabel="name"
              getOptionValue="id"
              values={filters.type}
            />
          </div>
          <div hidden={visible('dateRange')} className="w-48 h-full relative">
            {filters?.dateRange && (
              <div
                onClick={() => {
                  const updatedFilters = { ...filters };
                  updatedFilters.dateRange = '';
                  setState([
                    {
                      startDate: new Date(),
                      endDate: new Date(),
                      key: 'selection',
                    },
                  ]);
                  pushFilter(updatedFilters);
                }}
                className="cursor-pointer absolute right-2 top-1/2 transform -translate-y-1/2 hover:opacity-70"
              >
                <MdClose />
              </div>
            )}
            <div
              onClick={() => setOpen(true)}
              className="w-full h-full cursor-pointer bg-white rounded-[4px] text-gray-400 text-xs py-[10px] px-3"
            >
              {filters?.dateRange
                ? `${filters?.dateRange?.start_date} - ${filters?.dateRange?.end_date}`
                : 'Date Range'}
            </div>
          </div>
        </div>
        <div hidden={visible('query')}>
          <Search
            placeholder="Search by Name or ID ....."
            onSearch={(item: any) => {
              const updatedFilters = { ...filters };
              updatedFilters.query = item?.target?.value;
              setSearchQuery(item?.target?.value);
              pushFilter(updatedFilters);
            }}
            value={filters?.query ?? searchQuery}
          />
        </div>
      </div>
      <Modal
        isOpen={open}
        setISOpen={setOpen}
        title={'Select Date Range'}
        content={
          <DateRangePicker
            onChange={(item: any) => {
              const updatedFilters = { ...filters };
              updatedFilters.dateRange = {
                start_date: moment(item?.selection?.startDate).format(
                  'YYYY-MM-DD'
                ),
                end_date: moment(item?.selection?.endDate).format('YYYY-MM-DD'),
              };
              console.log(updatedFilters);
              console.log(item);
              setState([item.selection]);
              pushFilter(updatedFilters);
            }}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={state}
            direction="horizontal"
          />
        }
      />
    </div>
  );
};

export default Filteration;
