export const HOME = '/';
export const LOGIN = '/login';

//
export const USERS = '/users';
export const DRIVERS = '/drivers';
export const PREMIUM_DRIVERS = '/premium-drivers';
export const SUBSCRIPTION_PLAN = '/subscription-plan';
export const SUBSCRIPTION_PLAN_USER = '/subscription-plan-user';
export const PASSENGERS = '/passengers';
export const DRIVER_DETAIL = '/drivers/detail/:id';
export const PASSENGERS_DETAIL = '/passengers/detail/:id';
export const DRIVER_CREATE = '/drivers/create';
export const ALERTS = '/alerts';
export const ALERT_DETAIL = '/alerts/detail/:id';
export const RIDES = '/rides-history';
export const RIDE_DETAIL = '/rides-history/ride/:id';
export const SHEDULE_RIDE = '/scheduleRides-history';
export const SHEDULE_RIDE_DETAIL = '/scheduleRides-history/ride/:id';
export const PRICING = '/pricing';
export const ROLE = '/roles';
export const CABS = '/cabs';
export const CHALLENGES = '/challenges';
export const CHALLENGES_USERS = '/challengers_user';
export const PERMISSIONS = '/permissions';
export const DRIVER_STAND_PREMIUM = '/drivers/stand-premium/:id';
export const DRIVER_CAB_VERIFICATION = '/drivers/cab-verification/:id';
export const DRIVER_CAB_UPDATE = '/drivers/cab-update/:id';
export const DRIVER_VERIFICATION = '/drivers/verify/:id';


//
export const BRAND = '/master-data/brands';
export const MODEL = '/master-data/models';
export const BANNER = '/master-data/banners';
export const PROMOTIONS = '/master-data/promotions';
export const CANCELLATION = '/master-data/cancellation';
export const OFFERS = '/master-data/offers';
export const WITHDRAWAL = '/master-data/withdrawal';
export const WITHDRAWAL_DETAILS = '/master-data/withdrawal/:id';
export const SUBSCRIPTION_PLAN_USER_DETAILS = '/subscription-plan-user/detail/:id';
export const TOPUP = '/master-data/topup';
export const TOPUP_DETAILS = '/master-data/topup/:id';
export const STANDPREMIUM = '/master-data/standpremium';
export const D_S_OFFICE = '/master-data/d_s_office';
