import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'lib/axios';
import { apiURL } from 'config';

import {
  loadNotificationsFail,
  loadNotificationsRequested,
  loadNotificationsSuccess,
  markNotificationFail,
  markNotificationRequested,
  markNotificationSuccess,
} from './notificationSlice';

function* notificationEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.get,
      `${apiURL}notifications/admin/${action.payload.id}?page=${action.payload.page}&type=${action.payload.type}&adminId=${action.payload.id}`
    );
    yield put(loadNotificationsSuccess(data));
  } catch (error: any) {
    yield put(loadNotificationsFail(error.message));
  }
}

function* markNotificationsEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.post,
      `${apiURL}notifications/admin/${action.payload.id}/time-stamp`, {
        adminId: action.payload.id,
        lastNotificationsTimeStamp: action.payload.timeStamp,
      }
    );
    yield put(markNotificationSuccess(data));
  } catch (error: any) {
    yield put(markNotificationFail(error.message));
  }
}

export function* notificationSaga(): Generator<any, void, any> {
  yield takeLatest(loadNotificationsRequested, notificationEffect);
  yield takeLatest(markNotificationRequested, markNotificationsEffect);
}
