import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'lib/axios';
import { apiURL } from 'config';
import toast from 'react-hot-toast';

import {
  loadOffersRequested,
  loadOffersSuccess,
  loadOffersFail,
  createOfferssSuccess,
  createOfferssFail,
  createOfferssRequested,
  updateOfferSuccess,
  updateOfferFail,
  updateOfferRequested,
  deleteOfferSuccess,
  deleteOfferFail,
  deleteOfferRequested,
} from './offersSlice';

function* loadOffersEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const response = yield call(axios.get, `${apiURL}offers`, action.payload);
    yield put(loadOffersSuccess(response.data));
  } catch (error: any) {
    yield put(loadOffersFail(error.message));
  }
}

function* createOfferssEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  const { values } = action.payload;
  try {
    const formattedValues = {
      ...values,
      startDate: `${values.startDate}T00:00:00`,
      endDate: `${values.endDate}T23:59:59`,
    };

    const formData = new FormData();
    formData.append('offerCreateRequest', JSON.stringify(formattedValues));
    formData.append('offerImage', action.payload.offerImage);

    const response = yield call(axios.post, `${apiURL}offer`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    yield put(createOfferssSuccess(response.data));
  } catch (error: any) {
    yield put(createOfferssFail(error.message));
  }
}

function* updateOfferEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  const { values } = action.payload;
  const formattedValues = {
    ...values,
    startDate: `${values.startDate}T00:00:00`,
    endDate: `${values.endDate}T23:59:59`,
  };

  const formData = new FormData();
  formData.append('offer_request', JSON.stringify(formattedValues));
  formData.append('offerImage', action.payload.offerImage);

  try {
    const response = yield call(
      axios.put,
      `${apiURL}offer/offerUpdate?${action.payload.id}`,
      // action.payload.values
    );
    yield put(updateOfferSuccess(response.data));
  } catch (error: any) {
    yield put(updateOfferFail(error.message));
  }
}

function* deleteOfferEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.delete,
      `${apiURL}offer/${action?.payload?.item?.id}`,
      action.payload.values
    );

    if (data?.success) {
      toast.success(data?.message);
      yield put(deleteOfferSuccess(data));
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(deleteOfferFail(error.message));
  }
}

export function* offersSaga(): Generator<any, void, any> {
  yield takeLatest(loadOffersRequested, loadOffersEffect);
  yield takeLatest(createOfferssRequested, createOfferssEffect);
  yield takeLatest(updateOfferRequested, updateOfferEffect);
  yield takeLatest(deleteOfferRequested, deleteOfferEffect);
}
