import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  error: null,
  pricings: [],
  pricingsByDestrict: [],
  districts: [],
  storePricing: null,
};

const pricingSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    loadPricingsRequested(state, action) {
      state.loading = true;
      state.error = null;
    },
    loadPricingsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.pricings = action.payload.data;
      state.paginationOption = {
        pageNumber: action.payload?.pageNumber,
        pageSize: 10,
        totalRecords: action.payload?.totalRecords,
      };
    },

    loadPricingsFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    loadDestrictsRequested(state, action: PayloadAction<{ id: any }>) {
      state.loading = true;
      state.error = null;
    },
    loadDestrictsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.districts = action.payload;
    },

    loadDestrictsFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    storePricingRequested(
      state,
      action: PayloadAction<{
        values: {
          base_price: string;
          price_incrementer_distance: string;
          price_incrementer_time: 0;
          author: string;
          latitude: string;
          longitude: string;
          ds: string;
        };
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    storePricingSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.storePricing = action.payload;
    },

    storePricingFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadPricingsRequested,
  loadPricingsSuccess,
  loadPricingsFail,
  loadDestrictsFail,
  loadDestrictsRequested,
  loadDestrictsSuccess,
  storePricingFail,
  storePricingRequested,
  storePricingSuccess,
} = pricingSlice.actions;

export default pricingSlice.reducer;
