import React, { useMemo } from 'react';
import SelectComponent from 'react-select';

type Props = {
  name: string;
  selectedValue?:any,
  label?: string;
  values: any;
  placeholder: any;
  options: any;
  onChange: any;
  getOptionLabel: any;
  getOptionValue: any;
  disabled?: any;
  errors: any;
  touches: any;
  onBlur: any;
  onKeyDown?: any;
  backgroundColor?: any;
  defaultValue?: any;
};

function Select(props: Props) {
  const isInvalid = useMemo(() => {
    return props.touches[props.name] &&
      !props.values[props.name] &&
      props.errors[props.name]
      ? true
      : null;
  }, [props.touches, props.errors, props.name]);

  // console.log(props?.options, props?.name);
  return (
    <div className="flex flex-col gap-3">
      <label
        htmlFor={props.name}
        className="block text-[11px] font-bold text-sky-900"
      >
        {props.label}
      </label>

      <SelectComponent
        isClearable
        isSearchable
        value={props?.selectedValue}
        placeholder={props.placeholder}
        options={props.options}
        defaultValue={
          props?.defaultValue ? props?.defaultValue 
            // ? props.options.find((i: any) => {
            //     debugger;
            //     // console.log(
            //     //   i,
            //     //   i[props.getOptionValue] === props.defaultValue[props.getOptionValue]
            //     // );
            //     console.log(
            //       i[props.getOptionLabel].toLowerCase() ===
            //         props.defaultValue[props.getOptionLabel].toLowerCase()
            //     );
            //     if (
            //       i[props.getOptionLabel].toLowerCase() ===
            //       props.defaultValue[props.getOptionLabel].toLowerCase()
            //     )
            //       console.log(i);
            //     return (
            //       i[props.getOptionLabel].toLowerCase() ===
            //       props.defaultValue[props.getOptionLabel].toLowerCase()
            //     );
            //   })
            : null
        }
        name={props.name}
        onChange={props.onChange}
        getOptionLabel={(option) => option[props.getOptionLabel]}
        getOptionValue={(option) => option[props.getOptionValue]}
        onKeyDown={props?.onKeyDown}
        isDisabled={props.disabled}
        theme={(theme) => ({
          ...theme,
          borderRadius: 5,

          colors: {
            ...theme.colors,

            text: '#0F1A2D',
            primary25: '#E5E7EB',
            primary50: '#E5E7EB',

            primary: '#111B30',
          },
        })}
        className={`text-xs`}
        styles={{
          control: () => ({
            display: 'flex',
            backgroundColor: props?.backgroundColor
              ? props?.backgroundColor
              : '#F3F3F3',
            borderRadius: '4px',
          }),
        }}
      />

      {isInvalid ? (
        <p className="text-red-500 font-[600] text-[10px] mt-1">
          {props.label ? props.label : props.name} is required
        </p>
      ) : null}
    </div>
  );
}

export default Select;
