import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  error: null,
  challengeuser: [],
  challenge: {},
  // createChallenge: '',
  // challenge: {},
  // challengeById: {},
};

const challengesuserSlice = createSlice({
  name: 'challengesUser',
  initialState,
  reducers: {
    loadChallengesUserRequested(state, action: PayloadAction) {
      state.loading = true;
      state.error = null;
    },
    loadChallengesUserSuccess(state, action: PayloadAction<any>) {
      // console.log(action);
      state.loading = false;
      state.error = null;
      state.challengeuser = action.payload;
    },

    loadChallengesUserFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    adminBuyChallengeRequested(
      state,
      action: PayloadAction<{
        payload: { user_id: string; cab_id?: string; policy_id: string };
      }>
    ) {
      state.loading = true;
      state.error = null;
      state.challenge = null;
    },
    adminBuyChallengeSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.challenge = action.payload;
    },
    adminBuyChallengeFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadChallengesUserRequested,
  loadChallengesUserSuccess,
  loadChallengesUserFail,
  adminBuyChallengeRequested,
  adminBuyChallengeSuccess,
  adminBuyChallengeFail,
} = challengesuserSlice.actions;

export default challengesuserSlice.reducer;
