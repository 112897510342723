import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//import { Verify } from 'templates';

const initialState: any = {
  Loading: false,
  error: null,
  subscriptionPlanUsers: [],
  subscriptionPlanUser: undefined,
  paginationOption: {},
  Verify: null,
};

const subscriptionPlanUserSlice = createSlice({
  name: 'subscriptionPlanUsers',
  initialState,
  reducers: {
    loadSubscriptionPlanUsersRequested(state) {
      console.log('sdsdfsdsdfsdf');
      state.Loading = true;
      state.error = null;
    },
    loadSubscriptionPlanUsersSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.subscriptionPlanUsers = action.payload;
      state.paginationOption = {
        pageNumber: action.payload?.pageNumber,
        pageSize: 10,
        totalRecords: action.payload?.totalRecords,
      };
      console.log('asdafsdsv');
    },
    loadSubscriptionPlanUsersFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    loadSubscriptionPlanUserRequested(
      state,
      action: PayloadAction<{ id: string | undefined }>
    ) {
      console.log('sdsdfsdsdfsdf');
      state.subscriptionPlanUser = undefined;
      state.Loading = true;
      state.error = null;
    },
    loadSubscriptionPlanUserSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.subscriptionPlanUser = action.payload;
      console.log('asdafsdsv');
    },
    loadSubscriptionPlanUserFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    loadVerifyRequested(
      state,
      action: PayloadAction<{
        values: {
          id: any;
          reason: string;
          //Verify: string;
          status: string;
        };
      }>
    ) {
      state.loading = true;
      state.error = null;
      state.Verify = null;
    },
    loadVerifySuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.Verify = action.payload;
    },

    loadVerifyFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadSubscriptionPlanUsersRequested,
  loadSubscriptionPlanUsersSuccess,
  loadSubscriptionPlanUsersFail,
  loadSubscriptionPlanUserRequested,
  loadSubscriptionPlanUserSuccess,
  loadSubscriptionPlanUserFail,
  loadVerifyRequested,
  loadVerifySuccess,
  loadVerifyFail,
} = subscriptionPlanUserSlice.actions;

export default subscriptionPlanUserSlice.reducer;
