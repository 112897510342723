import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer, PURGE } from 'redux-persist';
import persistConfig from 'persist';
import authReducer from 'features/auth/authSlice';
import userReducer from 'features/users/userSlice';
import stateReducer from 'features/state/stateSlice';
import driverReducer from 'features/drivers/driverSlice';
import cabReducer from 'features/cabs/cabSlice';
import brandReducer from 'features/brand/brandSlice';
import modelReducer from 'features/model/modelSlice';
import alertReducer from 'features/alerts/alertSlice';
import rideReducer from 'features/rides/rideSlice';
import tripReducer from 'features/trip/tripSlice';
import offerReducer from 'features/offers/offersSlice';
import passengerReducer from 'features/passengers/passengerSlice';
import roleReducer from 'features/role/roleSlice';
import promotionReducer from 'features/promotions/promotionsSlice';
import cancelReasonReducer from 'features/cancelReason/cancelReasonSlice';
import pricingReducer from 'features/pricing/pricingSlice';
import bannerReducer from 'features/banners/bannersSlice';
import withdrawalReducer from 'features/withdrawal/withdrawalSlice';
import challengeReducer from 'features/challenges/challengesSlice';
import topUpReducer from 'features/topup/topUpSlice';
import placeReducer from 'features/places/placeSlice';
import permissionSlice from 'features/permissions/permissionSlice';
import standPremiumReducer from 'features/standPremium/standPremiumSlice';
import challengersUserReducer from 'features/challengesUser/challengesuserSlice';
//import dashBoardReducer from 'features/dashboard/dashBoardSlice';
import notificationReducer from 'features/notifications/notificationSlice';
import subcriptionPlanReducer from 'features/subscriptionPlan/subcriptionPlanSlice';
import subcriptionPlanUserReducer from 'features/subscriptionPlanUser/subcriptionPlanUserSlice';
import sheduleRideReducer from 'features/sheduleRides/sheduleRideSlice';
import dashBoardReducer from 'features/dashboard/dashBoardSlice';
import dsOfficeReducer from 'features/dsOffice/dsOfficeSlice'; 

const appReducer = combineReducers({
  auth: persistReducer(persistConfig, authReducer),
  user: userReducer,
  driver: driverReducer,
  state: stateReducer,
  cab: cabReducer,
  brand: brandReducer,
  model: modelReducer,
  alert: alertReducer,
  ride: rideReducer,
  trip: tripReducer,
  offer: offerReducer,
  passenger: passengerReducer,
  role: roleReducer,
  promotion: promotionReducer,
  cancelReason: cancelReasonReducer,
  pricing: pricingReducer,
  banner: bannerReducer,
  withdrawal: withdrawalReducer,
  challenge: challengeReducer,
  topUp: topUpReducer,
  place: placeReducer,
  permissions: permissionSlice,
  standPremium: standPremiumReducer,
  challengesUser:challengersUserReducer,
  subscriptionPlan:subcriptionPlanReducer,
  subscriptionPlanUsers:subcriptionPlanUserReducer,
  sheduleRide:sheduleRideReducer,
  dashBoard: dashBoardReducer,
  notification: notificationReducer,
  dsOffice :dsOfficeReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === PURGE) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
