import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'lib/axios';
import { tripURL } from 'config';

import {
  loadRidesRequested,
  loadRidesSuccess,
  loadRidesFail,
  loadRideSuccess,
  loadRideFail,
  loadRideRequested,
  loadRidesSummaryRequested,
  loadRidesSummaryFail,
  loadRidesSummarySuccess,
} from './rideSlice';

function* loadRidesEffect(action: {
  type: string;
  payload: {
    filters: any;
    perPage: number;
    page: number;
  };
}): Generator<any, void, any> {
  const { filters, perPage, page } = action.payload;
  console.log(filters);
  try {
    const cabTypeValue = filters?.cabType?.value;
    const tripStatusId = filters?.tripStatus?.id;
    const disctrictAll = filters?.districtAll?.id;
    const start = filters?.dateRange?.start_date;
    const end = filters?.dateRange?.end_date;

    // Construct the URL with conditional parameters
    const url =
      `${tripURL}trip-app/trip-histories?offset=${page}&limit=${perPage}` +
      (cabTypeValue ? `&cabType=${cabTypeValue}` : '') +
      (tripStatusId ? `&status=${tripStatusId}` : '') +
      (disctrictAll ? `&district=${disctrictAll}` : '') +
      (start ? `&start=${start}` : '') +
      (end ? `&end=${end}` : '');

    const response = yield call(axios.get, url);
    yield put(loadRidesSuccess(response.data));
  } catch (error: any) {
    yield put(loadRidesFail(error.message));
    console.log(error);
  }
}

function* loadRideEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.get,
      `${tripURL}trip-app/trip/${action?.payload?.id}`
    );
    yield put(loadRideSuccess(data));
  } catch (error: any) {
    yield put(loadRideFail(error.message));
  }
}

function* loadRidesSummaryEffect(action: {
  type: string;
  payload: {
    actionType: any;
  };
}): Generator<any, void, any> {
  try {
    // debugger
    const response = yield call(
      axios.get,
      `${tripURL}trip-app/tripSummary${
        action.payload.actionType ? `?status=${action.payload.actionType}` : ''
      }`
    );

    yield put(
      loadRidesSummarySuccess({
        data: response.data,
        actionType: action.payload.actionType,
      })
    );
  } catch (error: any) {
    yield put(loadRidesSummaryFail(error.message));
  }
}

export function* ridesSaga(): Generator<any, void, any> {
  yield takeLatest(loadRidesRequested, loadRidesEffect);
  yield takeLatest(loadRideRequested, loadRideEffect);
  yield takeLatest(loadRidesSummaryRequested, loadRidesSummaryEffect);
}
