import { takeLatest, put, call, takeEvery } from 'redux-saga/effects';
import axios from 'lib/axios';
import { apiURL } from 'config';
import toast from 'react-hot-toast';

import {
  loadBrandsFail,
  loadBrandsRequested,
  loadBrandsSuccess,
  updateBrandRequested,
  updateBrandSuccess,
  updateBrandFail,
  createBrandRequested,
  createBrandSuccess,
  createBrandFail,
  deleteBrandSuccess,
  deleteBrandFail,
  deleteBrandRequested,
  loadNonPaginatedBrandsSuccess,
  loadNonPaginatedBrandsFail,
  loadNonPaginatedBrandsRequested,
} from './brandSlice';

export function* brandsEffect(action: {
  type: string;
  payload: {
    filters: any;
    perPage: number;
    page: number;
  };
}): Generator<any, void, any> {
  const { filters } = action.payload;
  try {
    const { data } = yield call(
      axios.get,
      `${apiURL}vehicleBrands/brand-filter?pageSize=${action?.payload?.perPage ?? 1000}&pageCount=${
        action?.payload?.page ?? 1
      }${filters?.query ? '&search=' + filters?.query : ''}${
        filters?.defaultCabtype?.id
          ? '&cabType=' + filters?.defaultCabtype?.id
          : ''
      }`
    );

    yield put(loadBrandsSuccess(data));
  } catch (error: any) {
    yield put(loadBrandsFail(error.message));
  }
}

function* nonPaginatedBrandsEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  const { filters } = action.payload;

  try {
    let response;
    if (action.payload?.id) {
      if (action.payload?.id?.includes('CAR')) {
        response = yield call(axios.get, `${apiURL}vehicleBrands?cabType=CAR`);
      } else {
        response = yield call(
          axios.get,
          `${apiURL}vehicleBrands${
            action.payload?.id ? '?cabType=' + action.payload?.id : ''
          }`
        );
      }
    } else {
      response = yield call(
        axios.get,
        `${apiURL}vehicleBrands${
          filters?.cabType?.id ? '?cabType=' + filters?.cabType?.id : ''
        }`
      );
    }

    yield put(loadNonPaginatedBrandsSuccess(response.data));
  } catch (error: any) {
    yield put(loadNonPaginatedBrandsFail(error.message));
  }
}

export function* updateBrandEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.put,
      `${apiURL}vehicleBrand`,
      action.payload.values
    );
    yield put(updateBrandSuccess(data));

    if (data?.success) {
      toast.success(data?.message);
      yield put(loadBrandsRequested({ filters: '', perPage: 1000, page: 1 }));
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(updateBrandFail(error.message));
  }
}

function* createBrandEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.post,
      `${apiURL}vehicleBrand`,
      action.payload.values
    );
    yield put(createBrandSuccess(data));

    if (data?.success) {
      toast.success(data?.message);
      yield put(loadBrandsRequested({ filters: '', perPage: 100, page: 1 }));
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(createBrandFail(error.message));
  }
}

function* deleteBrandEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.put,
      `${apiURL}vehicleBrand/active?id=${action?.payload?.item?.id}`,
      action.payload.values
    );
    yield put(deleteBrandSuccess(data));

    if (data?.success) {
      toast.success(data?.message);
      yield put(loadBrandsRequested({ filters: '', perPage: 10, page: 1 }));
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(deleteBrandFail(error.message));
  }
}

export function* brandsSaga(): Generator<any, void, any> {
  yield takeEvery(loadBrandsRequested, brandsEffect);
  yield takeLatest(updateBrandRequested, updateBrandEffect);
  yield takeLatest(createBrandRequested, createBrandEffect);
  yield takeLatest(deleteBrandRequested, deleteBrandEffect);
  yield takeLatest(loadNonPaginatedBrandsRequested, nonPaginatedBrandsEffect);
}
