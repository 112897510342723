import { takeLatest, put, call, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { pricingURL } from 'config';
import toast from 'react-hot-toast';

import {
  loadPricingsRequested,
  loadPricingsSuccess,
  loadPricingsFail,
  loadDestrictsFail,
  loadDestrictsRequested,
  loadDestrictsSuccess,
  storePricingFail,
  storePricingRequested,
  storePricingSuccess,
} from './pricingSlice';

function* loadPricingsEffect(action: {
  type: string;
  payload: {
    filters: any;
    perPage: number;
    page: number;
  };
}): Generator<any, void, any> {
  const { filters, perPage, page } = action.payload;

  try {
    const response = yield call(
      axios.get,
      `${pricingURL}prices-by-district?${filters?.district?.id ? `district=${filters?.district?.id}`:''}${
        filters?.query ? '&query=' + filters?.query : ''
      }${
        filters?.cabType?.value ? '&cab=' + filters?.cabType?.value : ''
      }&offset=${page}&limit=${perPage}`
    );
    yield put(loadPricingsSuccess(response.data));
  } catch (error: any) {
    yield put(loadPricingsFail(error.message));
  }
}

function* loadDistrictsEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(axios.get, `${pricingURL}get-all-districts`);
    yield put(loadDestrictsSuccess(data));
  } catch (error: any) {
    yield put(loadDestrictsFail(error.message));
  }
}

function* storePricingEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.put,
      `${pricingURL}pricing`,
      action.payload.values
    );

    if (data?.success) {
      toast.success(data?.message);
    } else {
      toast.error(data?.message);
    }

    yield put(storePricingSuccess(data));
  } catch (error: any) {
    yield put(storePricingFail(error.message));
  }
}

export function* pricingSaga(): Generator<any, void, any> {
  yield takeEvery(loadPricingsRequested, loadPricingsEffect);
  yield takeLatest(loadDestrictsRequested, loadDistrictsEffect);
  yield takeLatest(storePricingRequested, storePricingEffect);
}
