import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  error: null,
  notifications: [],
  unreadNotification: [],
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    loadNotificationsRequested(
      state,
      action: PayloadAction<{ id: string; page: string; size: string; type:string }>
    ) {
      state.loading = true;
      state.error = null;
    },
    loadNotificationsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.notifications = action.payload;
      state.unreadNotification = action.payload.newNotifications;
    },

    loadNotificationsFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    markNotificationRequested(
      state,
      action: PayloadAction<{ id: string; timeStamp: string }>
    ) {
      state.loading = true;
      state.error = null;
    },
    markNotificationSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.notifications = [];
      state.unreadNotification = [];
    },

    markNotificationFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadNotificationsRequested,
  loadNotificationsSuccess,
  loadNotificationsFail,
  markNotificationRequested,
  markNotificationSuccess,
  markNotificationFail,
} = notificationSlice.actions;

export default notificationSlice.reducer;
