export const lang = process.env.REACT_APP_LANG;
export const name = process.env.REACT_APP_NAME;
export const url = process.env.REACT_APP_URL;
export const apiURL = process.env.REACT_APP_API_URL;
export const authURL = process.env.REACT_APP_AUTH_URL;
export const secretKey = (process.env.REACT_APP_KEY as string) || 'example key';
export const tripURL = process.env.REACT_APP_TRIP_URL;
export const pricingURL = process.env.REACT_APP_PRICING_URL;
export const paymentURL = process.env.REACT_APP_PAYMENT_URL;
export const riderURL = process.env.REACT_APP_RIDER_URL;
export const locationURL = process.env.REACT_APP_LOCATION_URL;
export const googleMapApi = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
