import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'lib/axios';
import { apiURL } from 'config';
import toast from 'react-hot-toast';

import {
  loadPromotionsRequested,
  loadPromotionsSuccess,
  loadPromotionsFail,
  createPromotionRequested,
  createPromotionSuccess,
  createPromotionFail,
  updatePromotionRequested,
  updatePromotionSuccess,
  updatePromotionFail,
  deletePromotionSuccess,
  deletePromotionFail,
  deletePromotionRequested,
} from './promotionsSlice';

function* promotionsEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const response = yield call(
      axios.get,
      `${apiURL}promotions`,
      action.payload
    );
    yield put(loadPromotionsSuccess(response.data));
  } catch (error: any) {
    yield put(loadPromotionsFail(error.message));
  }
}

function* createPromotionEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  const { values, promotionImage } = action.payload;
  try {
    const formattedValues = {
      ...values,
      startDate: `${values.startDate}T00:00:00`,
      endDate: `${values.endDate}T23:59:59`,
    };

    const formData = new FormData();
    formData.append('promotionCreateRequest', JSON.stringify(formattedValues));
    formData.append('promotionImage', promotionImage);

    const response = yield call(axios.post, `${apiURL}promotion`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    
    yield put(createPromotionSuccess(response.data));
  } catch (error: any) {
    yield put(createPromotionFail(error.message));
  }
}

function* updatePromotionEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const response = yield call(
      axios.put,
      `${apiURL}promotion`,
      action.payload.values
    );
    yield put(updatePromotionSuccess(response.data));
  } catch (error: any) {
    yield put(updatePromotionFail(error.message));
  }
}

function* deletePromotionEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.delete,
      `${apiURL}promotion/${action?.payload?.item?.id}`,
      action.payload.values
    );

    if (data?.success) {
      toast.success(data?.message);
      yield put(deletePromotionSuccess(data));
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(deletePromotionFail(error.message));
  }
}

export function* promotionsSaga(): Generator<any, void, any> {
  yield takeLatest(loadPromotionsRequested, promotionsEffect);
  yield takeLatest(createPromotionRequested, createPromotionEffect);
  yield takeLatest(updatePromotionRequested, updatePromotionEffect);
  yield takeLatest(deletePromotionRequested, deletePromotionEffect);
}
