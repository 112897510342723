import { takeEvery, put, call, takeLatest } from 'redux-saga/effects';
import axios from 'lib/axios';
import { apiURL } from 'config';
import toast from 'react-hot-toast';

import {
  loadUsersRequested,
  loadUsersSuccess,
  loadUsersFail,
  deleteUerSuccess,
  deleteUerFail,
  deleteUerRequested,
  storeAdminSuccess,
  storeAdminFail,
  storeAdminRequested,
  updateAdminSuccess,
  updateAdminFail,
  updateAdminRequested,
  loadAdminsRequested,
  loadAdminsSuccess,
  loadAdminsFail,
  loadUsersByPhoneNumberSuccess,
  loadUsersByPhoneNumberFail,
  loadUsersByPhoneNumberRequested,
} from './userSlice';

function* loadUsersEffect(action: {
  type: string;
  payload: {
    filters: any;
    perPage: number;
    page: number;
  };
}): Generator<any, void, any> {
  // const { filters } = action.payload;

  try {
    const { data } = yield call(
      axios.get,
      `${apiURL}admin/filter?pageSize=${action.payload.perPage}&pageCount=${action.payload.page}`
    );
    yield put(loadUsersSuccess(data));
  } catch (error: any) {
    yield put(loadUsersFail(error.message));
  }
}

function* deleteUerEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.put,
      `${apiURL}admin/archive-admin?id=${action?.payload?.item?.id}`,
      action.payload.values
    );

    if (data?.success) {
      toast.success(data?.message);
      yield put(deleteUerSuccess(data));
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(deleteUerFail(error.message));
  }
}

function* storeAdminEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.post,
      `${apiURL}admin/create-admin`,
      action.payload.values
    );
    yield put(storeAdminSuccess(data));

    if (data?.success) {
      toast.success(data?.message);
      yield put(loadAdminsRequested({ filters: '', perPage: 100, page: 1 }));
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(storeAdminFail(error.message));
  }
}

export function* updateAdminEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    // console.log(action?.payload?.values?.id)
    const { data } = yield call(
      axios.put,
      `${apiURL}admin/update-admin?id=${action?.payload?.values?.id}`,
      action.payload.values
    );
    yield put(updateAdminSuccess(data));

    if (data?.success) {
      toast.success(data?.message);
      yield put(loadAdminsRequested({ filters: '', perPage: 100, page: 1 }));
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(updateAdminFail(error.message));
  }
}

export function* loadAdminsEffect(action: {
  type: string;
  payload: {
    filters: any;
    perPage: number;
    page: number;
  };
}): Generator<any, void, any> {
  // const { filters } = action.payload;
  try {
    const { data } = yield call(
      axios.get,
      `${apiURL}admin/filter?pageSize=${action.payload.perPage}&pageCount=${
        action.payload.page
      }${
        action.payload.filters?.provincePlace?.label
          ? '&province=' + action.payload.filters?.provincePlace?.label
          : ''
      }${
        action.payload.filters?.districtPlace?.label
          ? '&district=' + action.payload.filters?.districtPlace?.label
          : ''
      }`
    );
    yield put(loadAdminsSuccess(data));
  } catch (error: any) {
    yield put(loadAdminsFail(error.message));
  }
}

export function* loadUsersByPhoneNUmberEffect(action: {
  type: string;
  payload: { phoneNumber: string; userType: string };
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.get,
      `${apiURL}users/filter?userType=${action.payload.userType}&phoneNo=${action.payload.phoneNumber}`
    );
    yield put(loadUsersByPhoneNumberSuccess(data));
    if (data?.records?.length > 0) {
      toast.success('User found!');
    } else {
      toast.error('User Not found!');
    }
  } catch (error: any) {
    yield put(loadUsersByPhoneNumberFail(error));
    toast.error('User Not found!');
  }
}

export function* usersSaga(): Generator<any, void, any> {
  yield takeEvery(loadUsersRequested, loadUsersEffect);
  yield takeLatest(deleteUerRequested, deleteUerEffect);
  yield takeLatest(storeAdminRequested, storeAdminEffect);
  yield takeLatest(updateAdminRequested, updateAdminEffect);
  yield takeLatest(loadAdminsRequested, loadAdminsEffect);
  yield takeLatest(
    loadUsersByPhoneNumberRequested,
    loadUsersByPhoneNUmberEffect
  );
}
