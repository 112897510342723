const Cities = [
  { name: 'Jaffna', id: 'Jaffna' },
  { name: 'Point Pedro', id: 'Point Pedro' },
  { name: 'Chavakachcheri', id: 'Chavakachcheri' },
  { name: 'Nallur', id: 'Nallur' },
  { name: 'Delft', id: 'Delft' },
  { name: 'Kayts', id: 'Kayts' },
  { name: 'Velanai', id: 'Velanai' },
  { name: 'Karainagar', id: 'Karainagar' },
  { name: 'Maruthankerney', id: 'Maruthankerney' },
  { name: 'Karaveddy', id: 'Karaveddy' },
  { name: 'Kopay', id: 'Kopay' },
  { name: 'Nallur', id: 'Nallur' },
  { name: 'Delft', id: 'Delft' },
  { name: 'Kayts', id: 'Kayts' },
  { name: 'Velanai', id: 'Velanai' },
  { name: 'Karainagar', id: 'Karainagar' },
  { name: 'Trincomalee', id: 'Trincomalee' },
  { name: 'Kinniya', id: 'Kinniya' },
  { name: 'Kantalai', id: 'Kantalai' },
  { name: 'Kuchchaveli', id: 'Kuchchaveli' },
  { name: 'Batticaloa', id: 'Batticaloa' },
  { name: 'Kattankudy', id: 'Kattankudy' },
  { name: 'Valaichchenai', id: 'Valaichchenai' },
  { name: 'Eravur', id: 'Eravur' },
  { name: 'Colombo', id: 'Colombo' },
  { name: 'Dehiwala-Mount Lavinia', id: 'Dehiwala-Mount Lavinia' },
  { name: 'Moratuwa', id: 'Moratuwa' },
  { name: 'Sri Jayawardenepura Kotte', id: 'Sri Jayawardenepura Kotte' },
  { name: 'Galle', id: 'Galle' },
  { name: 'Ambalangoda', id: 'Ambalangoda' },
  { name: 'Hikkaduwa', id: 'Hikkaduwa' },
  { name: 'Unawatuna', id: 'Unawatuna' },
  { name: 'Matara', id: 'Matara' },
  { name: 'Weligama', id: 'Weligama' },
  { name: 'Mirissa', id: 'Mirissa' },
  { name: 'Hakmana', id: 'Hakmana' },
  { name: 'Kandy', id: 'Kandy' },
  { name: 'Peradeniya', id: 'Peradeniya' },
  { name: 'Gampola', id: 'Gampola' },
  { name: 'Katugastota', id: 'Katugastota' },
  { name: 'Anuradhapura', id: 'Anuradhapura' },
  { name: 'Polonnaruwa', id: 'Polonnaruwa' },
  { name: 'Dambulla', id: 'Dambulla' },
  { name: 'Sigiriya', id: 'Sigiriya' },
  { name: 'Matale', id: 'Matale' },
  { name: 'Nuwara Eliya', id: 'Nuwara Eliya' },
  { name: 'Bandarawela', id: 'Bandarawela' },
  { name: 'Badulla', id: 'Badulla' },
  { name: 'Monaragala', id: 'Monaragala' },
  { name: 'Ratnapura', id: 'Ratnapura' },
  { name: 'Kegalle', id: 'Kegalle' },
  { name: 'Puttalam', id: 'Puttalam' },
  { name: 'Chilaw', id: 'Chilaw' },
  { name: 'Negombo', id: 'Negombo' },
  { name: 'Kalutara', id: 'Kalutara' },
  { name: 'Panadura', id: 'Panadura' },
  { name: 'Gampaha', id: 'Gampaha' },
  { name: 'Kuliyapitiya', id: 'Kuliyapitiya' },
  { name: 'Kurunegala', id: 'Kurunegala' },
  { name: 'Puttalam', id: 'Puttalam' },
  { name: 'Kilinochchi', id: 'Kilinochchi' },
  { name: 'Mannar', id: 'Mannar' },
  { name: 'Vavuniya', id: 'Vavuniya' },
  { name: 'Mullaitivu', id: 'Mullaitivu' },
  { name: 'Batticaloa', id: 'Batticaloa' },
  { name: 'Ampara', id: 'Ampara' },
  { name: 'Hambantota', id: 'Hambantota' },
  { name: 'Tissamaharama', id: 'Tissamaharama' },
  { name: 'Matara', id: 'Matara' },
  { name: 'Ambalangoda', id: 'Ambalangoda' },
  { name: 'Hikkaduwa', id: 'Hikkaduwa' },
  { name: 'Unawatuna', id: 'Unawatuna' },
  { name: 'Mirissa', id: 'Mirissa' },
  { name: 'Weligama', id: 'Weligama' },
  { name: 'Dickwella', id: 'Dickwella' },
  { name: 'Tangalle', id: 'Tangalle' },
  { name: 'Bentota', id: 'Bentota' },
  { name: 'Beruwala', id: 'Beruwala' },
  { name: 'Koggala', id: 'Koggala' },
  { name: 'Ahungalla', id: 'Ahungalla' },
  { name: 'Balapitiya', id: 'Balapitiya' },
  { name: 'Kataragama', id: 'Kataragama' },
  { name: 'Ella', id: 'Ella' },
  { name: 'Haputale', id: 'Haputale' },
  { name: 'Nuwara Eliya', id: 'Nuwara Eliya' },
  { name: 'Dikoya', id: 'Dikoya' },
  { name: 'Hatton', id: 'Hatton' },
  { name: 'Nuwara Eliya', id: 'Nuwara Eliya' },
  { name: 'Ambewela', id: 'Ambewela' },
  { name: 'Nuwara Eliya', id: 'Nuwara Eliya' },
  { name: 'Galle', id: 'Galle' },
  { name: 'Koggala', id: 'Koggala' },
  { name: 'Unawatuna', id: 'Unawatuna' },
  { name: 'Bentota', id: 'Bentota' },
  { name: 'Beruwala', id: 'Beruwala' },
  { name: 'Hikkaduwa', id: 'Hikkaduwa' },
  { name: 'Ambalangoda', id: 'Ambalangoda' },
  { name: 'Balapitiya', id: 'Balapitiya' },
  { name: 'Kalpitiya', id: 'Kalpitiya' },
  { name: 'Mannar', id: 'Mannar' },
  { name: 'Wilpattu', id: 'Wilpattu' },
  { name: 'Anuradhapura', id: 'Anuradhapura' },
  { name: 'Polonnaruwa', id: 'Polonnaruwa' },
  { name: 'Habarana', id: 'Habarana' },
  { name: 'Dambulla', id: 'Dambulla' },
  { name: 'Sigiriya', id: 'Sigiriya' },
  { name: 'Mihintale', id: 'Mihintale' },
  { name: 'Kandy', id: 'Kandy' },
  { name: 'Peradeniya', id: 'Peradeniya' },
  { name: 'Gampola', id: 'Gampola' },
  { name: 'Katugastota', id: 'Katugastota' },
  { name: 'Kandy', id: 'Kandy' },
  { name: 'Pinnawala', id: 'Pinnawala' },
  { name: 'Hikkaduwa', id: 'Hikkaduwa' },
  { name: 'Ambalangoda', id: 'Ambalangoda' },
  { name: 'Unawatuna', id: 'Unawatuna' },
  { name: 'Mirissa', id: 'Mirissa' },
  { name: 'Weligama', id: 'Weligama' },
  { name: 'Dickwella', id: 'Dickwella' },
  { name: 'Tangalle', id: 'Tangalle' },
  { name: 'Kataragama', id: 'Kataragama' },
  { name: 'Kirinda', id: 'Kirinda' },
  { name: 'Yala', id: 'Yala' },
  { name: 'Tissamaharama', id: 'Tissamaharama' },
  { name: 'Arugam Bay', id: 'Arugam Bay' },
  { name: 'Batticaloa', id: 'Batticaloa' },
  { name: 'Pasikuda', id: 'Pasikuda' },
  { name: 'Ampara', id: 'Ampara' },
  { name: 'Kumana', id: 'Kumana' },
  { name: 'Bundala', id: 'Bundala' },
  { name: 'Hambantota', id: 'Hambantota' },
  { name: 'Tissamaharama', id: 'Tissamaharama' },
  { name: 'Yala', id: 'Yala' },
  { name: 'Udawalawe', id: 'Udawalawe' },
  { name: 'Matara', id: 'Matara' },
  { name: 'Weligama', id: 'Weligama' },
  { name: 'Mirissa', id: 'Mirissa' },
  { name: 'Dickwella', id: 'Dickwella' },
  { name: 'Tangalle', id: 'Tangalle' },
  { name: 'Kataragama', id: 'Kataragama' },
  { name: 'Kirinda', id: 'Kirinda' },
  { name: 'Yala', id: 'Yala' },
  { name: 'Tissamaharama', id: 'Tissamaharama' },
  { name: 'Arugam Bay', id: 'Arugam Bay' },
  { name: 'Batticaloa', id: 'Batticaloa' },
  { name: 'Pasikuda', id: 'Pasikuda' },
  { name: 'Ampara', id: 'Ampara' },
  { name: 'Kumana', id: 'Kumana' },
  { name: 'Bundala', id: 'Bundala' },
  { name: 'Hambantota', id: 'Hambantota' },
  { name: 'Tissamaharama', id: 'Tissamaharama' },
  { name: 'Yala', id: 'Yala' },
  { name: 'Udawalawe', id: 'Udawalawe' },
  { name: 'Bentota', id: 'Bentota' },
  { name: 'Beruwala', id: 'Beruwala' },
  { name: 'Kalutara', id: 'Kalutara' },
  { name: 'Panadura', id: 'Panadura' },
  { name: 'Wadduwa', id: 'Wadduwa' },
  { name: 'Negombo', id: 'Negombo' },
  { name: 'Waikkal', id: 'Waikkal' },
  { name: 'Chilaw', id: 'Chilaw' },
  { name: 'Puttalam', id: 'Puttalam' },
  { name: 'Kalpitiya', id: 'Kalpitiya' },
  { name: 'Anuradhapura', id: 'Anuradhapura' },
  { name: 'Mihintale', id: 'Mihintale' },
  { name: 'Wilpattu', id: 'Wilpattu' },
  { name: 'Vavuniya', id: 'Vavuniya' },
  { name: 'Mannar', id: 'Mannar' },
  { name: 'Jaffna', id: 'Jaffna' },
  { name: 'Point Pedro', id: 'Point Pedro' },
  { name: 'Chavakachcheri', id: 'Chavakachcheri' },
  { name: 'Nallur', id: 'Nallur' },
  { name: 'Kayts', id: 'Kayts' },
  { name: 'Velanai', id: 'Velanai' },
  { name: 'Karainagar', id: 'Karainagar' },
  { name: 'Maruthankerney', id: 'Maruthankerney' },
  { name: 'Karaveddy', id: 'Karaveddy' },
  { name: 'Kopay', id: 'Kopay' },
  { name: 'Delft', id: 'Delft' },
  { name: 'Trincomalee', id: 'Trincomalee' },
  { name: 'Kinniya', id: 'Kinniya' },
  { name: 'Kantalai', id: 'Kantalai' },
  { name: 'Kuchchaveli', id: 'Kuchchaveli' },
  { name: 'Batticaloa', id: 'Batticaloa' },
  { name: 'Kattankudy', id: 'Kattankudy' },
  { name: 'Valaichchenai', id: 'Valaichchenai' },
  { name: 'Eravur', id: 'Eravur' },
  { name: 'Colombo', id: 'Colombo' },
  { name: 'Dehiwala-Mount Lavinia', id: 'Dehiwala-Mount Lavinia' },
  { name: 'Moratuwa', id: 'Moratuwa' },
  { name: 'Sri Jayawardenepura Kotte', id: 'Sri Jayawardenepura Kotte' },
  { name: 'Galle', id: 'Galle' },
  { name: 'Ambalangoda', id: 'Ambalangoda' },
  { name: 'Hikkaduwa', id: 'Hikkaduwa' },
  { name: 'Unawatuna', id: 'Unawatuna' },
  { name: 'Matara', id: 'Matara' },
  { name: 'Weligama', id: 'Weligama' },
  { name: 'Mirissa', id: 'Mirissa' },
  { name: 'Hakmana', id: 'Hakmana' },
  { name: 'Kandy', id: 'Kandy' },
  { name: 'Peradeniya', id: 'Peradeniya' },
  { name: 'Gampola', id: 'Gampola' },
  { name: 'Katugastota', id: 'Katugastota' },
];

export default Cities;
