import { put, call, takeEvery, takeLatest, delay } from 'redux-saga/effects';
import axios from 'lib/axios';
import { apiURL, pricingURL, riderURL } from 'config';
import {
  storeCabFail,
  storeCabRequested,
  storeCabSuccess,
  loadCabsRequested,
  loadCabsSuccess,
  loadCabsFail,
  storeCabImagesRequested,
  storeCabImagesFail,
  storeCabImagesSuccess,
  updateCabSuccess,
  updateCabFail,
  updateCabRequested,
  loadCabSuccess,
  loadCabFail,
  loadCabRequested,
  cabDocumentsVerificationSuccess,
  cabDocumentsVerificationFail,
  cabDocumentsVerificationRequested,
  cabTypeSuccess,
  cabTypeFail,
  cabTypeRequested,
  deleteCabSuccess,
  deleteCabFail,
  deleteCabRequested,
  deleteDriverCabSuccess,
  deleteDriverCabFail,
  deleteDriverCabRequested,
  updateCabDocumentSuccess,
  updateCabDocumentFail,
  updateCabDocumentRequested,
  loadNearbyCabsRequested,
  loadNearbyCabsFail,
  loadNearbyCabsSuccess,
} from 'features/cabs/cabSlice';
import toast from 'react-hot-toast';

function* cabsEffect(action: {
  type: string;
  payload: {
    filters: any;
    perPage: number;
    page: number;
  };
}): Generator<any, void, any> {
  const { filters } = action.payload;

  try {
    const { data } = yield call(
      axios.get,
      `${apiURL}cabs/filters?pageSize=${action.payload.perPage}&pageCount=${
        action.payload.page
      }${filters.cabType?.label ? '&cabType=' + filters?.cabType?.label : ''}${
        filters.brand?.vehicleBrandName
          ? '&brand=' + filters?.brand?.vehicleBrandName
          : ''
      } ${filters.model?.id ? '&model=' + filters?.model?.id : ''}  `
    );
    yield put(loadCabsSuccess(data));
  } catch (error: any) {
    yield put(loadCabsFail(error.message));
  }
}

function* cabEffect(action: {
  type: string;
  payload: {
    id: any;
  };
}): Generator<any, void, any> {
  // debugger
  try {
    const { data } = yield call(
      axios.get,
      `${apiURL}cabs/${action?.payload?.id}?image=true`
    );

    yield put(loadCabSuccess(data));
  } catch (error: any) {
    yield put(loadCabFail(error.message));
  }
}

function* storeCabEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  const { values, cabImages } = action.payload;
  try {
    const formData = new FormData();
    formData.append('cab', JSON.stringify(values));

    const { data } = yield call(axios.post, `${apiURL}cabs`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (data?.success === 'true') {
      yield put(storeCabSuccess(data));
      toast.success('Cab Created Successfully');
    } else {
      toast.error(data?.message);
    }

    yield put(storeCabImagesRequested({ id: data?.id, cabImages: cabImages }));
  } catch (error: any) {
    yield put(storeCabFail(error.message));
  }
}

function* storeCabImagesEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  console.log(action?.payload);
  try {
    const { id, cabImages } = action.payload;
    const imagesFormData = new FormData();

    imagesFormData.append('taxDocumentImage', cabImages?.taxDocumentImage);
    imagesFormData.append(
      'insuranceDocumentImage',
      cabImages?.insuranceDocumentImage
    );
    imagesFormData.append('veichleBookImage', cabImages?.veichleBookImage);

    const response = yield call(
      axios.post,
      `${apiURL}cabs/upload?id=${id}`,
      imagesFormData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );

    yield put(storeCabImagesSuccess(response.data));
  } catch (error: any) {
    yield put(storeCabImagesFail(error.message));
  }
}

function* updateCabEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  console.log(action?.payload)
  const { values } = action.payload;
  try {
    const response = yield call(
      axios.put,
      `${apiURL}cabs/${values?.cab_id}/update-details`,
      values
    );

    if (response?.data?.success) {
      yield delay(1000);
      yield put(updateCabSuccess(response.data));
      toast.success(response?.data?.message);
    } else {
      toast.error(response?.data?.message);
    }
  } catch (error: any) {
    yield put(updateCabFail(error.message));
  }
}

function* updateCabDocumentEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  const { taxDocumentImage, insuranceDocumentImage, veichleBookImage, id } =
    action.payload;
  try {
    const formData = new FormData();
    formData.append('taxDocumentImage', taxDocumentImage);
    formData.append('insuranceDocumentImage', insuranceDocumentImage);
    formData.append('veichleBookImage', veichleBookImage);

    const { data } = yield call(
      axios.put,
      `${apiURL}cabs/update?id=${id}`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );

    if (data?.success) {
      toast.success(data?.message);
      yield put(updateCabDocumentSuccess(data));
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(updateCabDocumentFail(error.message));
  }
}

function* cabDocumentsVerificationEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    // console.log(action?.payload)
    const { data } = yield call(
      axios.put,
      `${apiURL}cabs/${action?.payload?.id}/document_verify`,
      action.payload.values
    );
    // yield put(cabDocumentsVerificationSuccess(data));
    if (data?.success) {
      toast.success(data?.message);
      yield put(cabDocumentsVerificationSuccess(data));
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(cabDocumentsVerificationFail(error.message));
  }
}

function* cabTypeEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(axios.get, `${pricingURL}cab-types`);
    yield put(cabTypeSuccess(data));
  } catch (error: any) {
    yield put(cabTypeFail(error.message));
  }
}

function* deleteCabEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.put,
      `${apiURL}cabs/active?cabId=${action?.payload?.item?.cab_id}`,
      action.payload.values
    );

    if (data?.success) {
      toast.success(data?.message);
      yield put(deleteCabSuccess(data));
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(deleteCabFail(error.message));
  }
}

function* deleteDriverCabEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.put,
      `${apiURL}cabs/active?cabId=${action?.payload?.item}`,
      action.payload.values
    );
    yield put(deleteDriverCabSuccess(data));

    if (data?.success) {
      toast.success(data?.message);
      yield put(loadCabsRequested(data));
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(deleteDriverCabFail(error.message));
  }
}

function * nearbyCabsEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    console.log(action?.payload)
    const { data } = yield call(
      axios.get,
      `${riderURL}near-by-cabs?latitude=${action?.payload?.position?.lat}&longitude=${action?.payload?.position?.lng}${action?.payload?.cabType ? `&cabType=${action?.payload?.cabType}` : ''}&max_radius=20`
    );
    yield put(loadNearbyCabsSuccess(data?.driver_locations));
  } catch (error: any) {
    yield put(loadNearbyCabsFail(error.message));
  }
}

export function* cabsSaga(): Generator<any, void, any> {
  yield takeEvery(loadCabsRequested, cabsEffect);
  yield takeEvery(loadCabRequested, cabEffect);
  yield takeLatest(storeCabRequested, storeCabEffect);
  yield takeLatest(storeCabImagesRequested, storeCabImagesEffect);
  yield takeLatest(updateCabRequested, updateCabEffect);
  yield takeLatest(updateCabDocumentRequested, updateCabDocumentEffect);
  yield takeLatest(
    cabDocumentsVerificationRequested,
    cabDocumentsVerificationEffect
  );
  yield takeEvery(cabTypeRequested, cabTypeEffect);
  yield takeLatest(deleteCabRequested, deleteCabEffect);
  yield takeLatest(deleteDriverCabRequested, deleteDriverCabEffect);
  yield takeLatest(loadNearbyCabsRequested, nearbyCabsEffect);
}
