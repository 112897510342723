import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  error: null,
  models: [],
  updateModel: null,
  storeModel: null,
  model: {},
  nonPaginatedModels: [],
};

const modelSlice = createSlice({
  name: 'model',
  initialState,
  reducers: {
    loadModelsRequested(state, action) {
      state.loading = true;
      state.error = null;
    },
    loadModelsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.models = action?.payload?.records;
      state.paginationOption = {
        pageNumber: action.payload?.pageNumber,
        pageSize: 10,
        totalRecords: action.payload?.totalRecords,
      };
    },

    loadModelsFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    loadNonPaginatedModelsRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    loadNonPaginatedModelsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.nonPaginatedModels = action?.payload;
    },

    loadNonPaginatedModelsFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    updateModelRequested(
      state,
      action: PayloadAction<{
        id: any;
        values: {
          id: string;
          vehicleModelName: string;
          vehicleBrandId: string;
        };
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    updateModelSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.updateModel = action.payload;
    },

    updateModelFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    storeModelRequested(
      state,
      action: PayloadAction<{
        values: {
          vehicleBrandId: string;
          vehicleModelName: string;
        };
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    storeModelSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.storeModel = action.payload;
    },

    storeModelFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    deleteModelRequested(
      state,
      action: PayloadAction<{
        item: any;
      }>
    ) {
      state.loading = false;
      state.error = null;
      state.model = action.payload;
    },
    deleteModelSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.model = action.payload;
    },
    deleteModelFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadModelsRequested,
  loadModelsFail,
  loadModelsSuccess,
  updateModelRequested,
  updateModelSuccess,
  updateModelFail,
  storeModelFail,
  storeModelRequested,
  storeModelSuccess,
  deleteModelRequested,
  deleteModelSuccess,
  deleteModelFail,
  loadNonPaginatedModelsRequested,
  loadNonPaginatedModelsSuccess,
  loadNonPaginatedModelsFail
} = modelSlice.actions;

export default modelSlice.reducer;
