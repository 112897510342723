import React, { useEffect, useRef } from 'react';
import { Menu } from 'primereact/menu';
import { IoLogOut, IoMail, IoMailOpen } from 'react-icons/io5';
import User from 'assets/images/png/user.png';
import Logo from 'assets/images/png/logo.png';
import moment from 'moment';
import Clock from 'react-live-clock';
import { MdMail } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadNotificationsRequested,
  markNotificationRequested,
} from 'features';
import { RootState } from 'store/reducer';
import Animation from 'assets/animations/Loading/insider-loading.json';
import Lottie from 'lottie-react';

function Header() {
  const dispatch = useDispatch();
  const { auth } = useSelector((state: RootState) => state.auth);
  const { notifications, unreadNotification, loading } = useSelector(
    (state: RootState) => state.notification
  );

  const menu = useRef<any>();
  const notificationMenu = useRef<any>();
  // const [notificationOpen, setNotificationOpen] = useState<boolean>(false);
  const getGreetingMessage = () => {
    const hour = new Date().getHours();

    if (hour >= 12) {
      if (hour >= 16) {
        return 'Good Evening !';
      } else {
        return 'Good Afternoon !';
      }
    } else {
      return 'Good Morning !';
    }
  };

  useEffect(() => {
    dispatch(
      loadNotificationsRequested({ id: auth?.userId, page: '1', size: '10', type: 'new' })
    );
  }, []);

  const onLogout = () => {
    window.location.href = './login';
    localStorage.clear();
  };

  const items = [
    {
      icon: <IoLogOut className="mr-2 w-5 h-5" />,
      label: 'Sign Out',
      className: 'text-sm',
      command: () => onLogout(),
    },
  ];

  const NoNotificationItems = [
    {
      icon: <IoMailOpen className="mr-2 w-5 h-5" />,
      label: 'No new notifications',
      className: 'text-sm w-fit',
    },
  ];

  return (
    <header className="flex px-3 h-14 items-center justify-between z-20 bg-[#e9e9f3] w-full sticky top-0">
      <div className="flex items-center">
        <img src={Logo} alt="logo" className="w-10 h-10 mr-4" />
        <h1 className="text-black text-sm font-bold">Oh Ride Administration</h1>
      </div>

      <div className="mr-5 flex text-indigo-800 items-center gap-2">
        <div className="w-36 h-11 tracking-widest text-sm font-bold rounded-sm mr-6 flex flex-col justify-center items-center">
          <span className="text-[11px]">
            {moment(new Date()).format('DD-MM-YYYY')}
          </span>{' '}
          <Clock className="" format={'HH:mm:ss'} ticking={true} />
        </div>

        <div className="font-display font-bold text-xs flex-col text-black tracking-widest mr-4 md:block hidden">
          <span className="text-[12px]">{getGreetingMessage()}</span>
          <br />
          Administrator
        </div>

        <div
          onClick={(e) => notificationMenu.current.toggle(e)}
          className="relative z-10 cursor-pointer"
        >
          <MdMail className="h-8 w-8 text-[#6389da]" fill={'#6389da'} />
          {notifications?.newNotifications?.length > 0 && !loading && (
            <div className="h-5 min-w-5 p-1 bg-red-500 rounded-full absolute top-0 -right-1 flex justify-center items-center text-sm text-white font-semibold">
              {notifications?.newNotifications?.length <= 9
                ? notifications?.newNotifications?.length
                : '10+'}
            </div>
          )}
          {loading && (
            <div className="absolute  -top-2 -right-5">
              <Lottie className="w-12" animationData={Animation} />
            </div>
          )}
        </div>

        <img
          className="h-8 w-8 rounded-full hover:border-[2px] hover:border-red-400"
          src={User}
          alt="user"
          onClick={(e) => menu.current.toggle(e)}
        />
        <Menu
          popup
          ref={notificationMenu}
          model={
            notifications?.newNotifications?.length === 0
              ? NoNotificationItems
              : unreadNotification.map((notification: any) => {
                  return {
                    icon: <IoMail className="mr-2 w-7 h-7" />,
                    label: (
                      <div className="flex flex-col gap-1">
                        <div className="font-bold">{notification?.title}</div>
                        <div className="text-gray-500 font-semibold">
                          {notification?.title}
                        </div>
                        <div className="text-gray-400">
                          {moment(notification?.timeStamp)
                            .startOf('day')
                            .fromNow()}
                        </div>
                      </div>
                    ),

                    className: 'text-sm min-w-48 w-fit',
                  };
                })
          }
          popupAlignment="right"
          className="w-fit"
          onShow={() => console.log('menu')}
          onHide={() => {
            if (unreadNotification?.length !== 0) {
              dispatch(
                markNotificationRequested({
                  id: auth?.userId,
                  timeStamp: unreadNotification[0]?.timeStamp,
                })
              );
            }
          }}
        />
        <Menu model={items} popup ref={menu} />
      </div>
    </header>
  );
}

export default Header;
