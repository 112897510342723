import React from 'react';

type Props = {
  placeholder?: string;
  onSearch?: any;
  value?: string;
};

const Search = (props: Props) => {
  return (
    <div>
      <input
        onChange={props.onSearch}
        value={props.value}
        type="text"
        placeholder={props.placeholder}
        className="text-xs text-black w-56 rounded-sm p-[10px] bg-[#f8f9fa]"
      />
    </div>
  );
};

export default Search;
