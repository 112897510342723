import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'lib/axios';
import { apiURL } from 'config';
import toast from 'react-hot-toast';

import {
  loadCancelReasonsRequested,
  loadCancelReasonsSuccess,
  loadCancelReasonsFail,
  updateCancelReasonSuccess,
  updateCancelReasonFail,
  updateCancelReasonRequested,
  createCancelReasonSuccess,
  createCancelReasonFail,
  createCancelReasonRequested,
  deleteCancelReasonSuccess,
  deleteCancelReasonFail,
  deleteCancelReasonRequested,
} from './cancelReasonSlice';

function* cancelReasonEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const response = yield call(
      axios.get,
      `${apiURL}cancelReasons`,
      action.payload
    );
    yield put(loadCancelReasonsSuccess(response.data));
  } catch (error: any) {
    yield put(loadCancelReasonsFail(error.message));
  }
}

function* updateCancelReasonEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const response = yield call(
      axios.put,
      `${apiURL}cancelReason/${action.payload.values?.id}/cancelReasonUpdate`,
      action.payload.values
    );
    yield put(updateCancelReasonSuccess(response.data));

    if (response?.data?.success) {
      toast.success(response?.data?.message);
      yield put(loadCancelReasonsRequested({ filters: '', perPage: 1000, page: 1 }));
    } else {
      toast.error(response?.data?.message);
    }
  } catch (error: any) {
    yield put(updateCancelReasonFail(error.message));
  }
}

function* createCancelReasonEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const response = yield call(
      axios.post,
      `${apiURL}cancelReason`,
      action.payload.values
    );
    // yield put(createCancelReasonSuccess(response?.data));
        yield put(createCancelReasonSuccess(response?.data));

    if (response?.data?.success) {
      toast.success(response?.data?.message);
      yield put(loadCancelReasonsRequested({ filters: '', perPage: 1000, page: 1 }));
    } else {
      toast.error(response?.data?.message);
    } 
  } catch (error: any) {
    yield put(createCancelReasonFail(error.message));
  }
}

function* deleteCancelReasonEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.delete,
      `${apiURL}cancelReason/${action?.payload?.item?.id}/cancelReasonDelete`,
      action.payload.values
    );

    if (data?.success) {
      toast.success(data?.message);
      yield put(deleteCancelReasonSuccess(data));
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(deleteCancelReasonFail(error.message));
  }
}

export function* cancelReasonsSaga(): Generator<any, void, any> {
  yield takeLatest(loadCancelReasonsRequested, cancelReasonEffect);
  yield takeLatest(updateCancelReasonRequested, updateCancelReasonEffect);
  yield takeLatest(createCancelReasonRequested, createCancelReasonEffect);
  yield takeLatest(deleteCancelReasonRequested, deleteCancelReasonEffect);
}
