import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  error: null,
  rides: [],
  ride: null,
  paginationOption: {},
  CntCompleted: 0,
  CntProgress: 0,
  CntRequested: 0,
  CntCancelled: 0,
};

const ridesSlice = createSlice({
  name: 'rides',
  initialState,
  reducers: {
    loadRidesRequested(
      state,
      action: PayloadAction<{ filters: any; page: number; perPage: number }>
    ) {
      state.loading = true;
      state.error = null;
    },
    loadRidesSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.rides = action.payload.records;
      state.paginationOption = {
        pageNumber: action.payload?.pageNumber,
        pageSize: 10,
        totalRecords: action.payload?.totalRecords,
      };
    },

    loadRidesFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    loadRideRequested(state, action: PayloadAction<{ id: any }>) {
      state.ride = null;
      state.loading = true;
      state.error = null;
    },
    loadRideSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.ride = action.payload;
    },

    loadRideFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    loadRidesSummaryRequested(
      state,
      action: PayloadAction<{ actionType: string | undefined }>
    ) {
      state.loading = true;
      state.error = null;
    },
    loadRidesSummarySuccess(state, action: PayloadAction<any>) {
      console.log(action.payload);
      state.loading = false;
      state.error = null;
      state.CntCompleted = action.payload.data.COMPLETED;
      state.CntProgress = action.payload.data.INPROGRESS ?? 0;
      state.CntRequested = action.payload.data.REQUESTED;
      state.CntCancelled = action.payload.data.CANCELLED;
    },
    loadRidesSummaryFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadRidesRequested,
  loadRidesSuccess,
  loadRidesFail,
  loadRideRequested,
  loadRideSuccess,
  loadRideFail,
  loadRidesSummaryRequested,
  loadRidesSummarySuccess,
  loadRidesSummaryFail,
} = ridesSlice.actions;

export default ridesSlice.reducer;
