import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'axios';
import { paymentURL } from 'config';
import toast from 'react-hot-toast';

import {
    loadSubscriptionPlanUsersRequested,
    loadSubscriptionPlanUsersSuccess,
    loadSubscriptionPlanUsersFail,
    loadSubscriptionPlanUserRequested,
    loadSubscriptionPlanUserSuccess,
    loadSubscriptionPlanUserFail,
    loadVerifySuccess,
    loadVerifyFail,
    loadVerifyRequested,
    
  } from './subcriptionPlanUserSlice';


  function* subcriptionPlanUsersEffect(action: {
    type: string;
    payload: any;
  }): Generator<any, void, any> {
    //const { filters, perPage, page } = action.payload;
    console.log(action.payload);
    try {
      const response = yield call(axios.get, `${paymentURL}subscription/search`);
      console.log(response)
      yield put(loadSubscriptionPlanUsersSuccess(response.data.records));
    } catch (error: any) {
      console.log(error);
      yield put(loadSubscriptionPlanUsersFail(error.message));
    }
  }
  function* subcriptionPlanUserEffect(action: {
    type: string;
    payload: any;
  }): Generator<any, void, any> {
    try {
      const response  = yield call(
        axios.get,
        // `${apiURL}admin/driver/${action?.payload?.id}`
        `${paymentURL}subscription/${action?.payload?.id}`
      );
      console.log(response)
      yield put(loadSubscriptionPlanUserSuccess(response.data));
    } catch (error: any) {
      console.log(error)
      yield put(loadSubscriptionPlanUserFail(error.message));
    }
  }
  function* VerifyEffect(action: {
    type: string;
    payload: any;
  }): Generator<any, void, any> {
    try {
       console.log('payloadvvvvv', action?.payload);
      const { data } = yield call(
        axios.put,
        `${paymentURL}subscription/change-status?id=${action?.payload?.values?.id}&status=${action?.payload?.values?.status}&reason=${action?.payload?.values?.reason}`,
        action.payload.values
      );
  
      if (data?.success) {
        toast.success(data?.message);
        yield put(loadVerifySuccess(data));
      } else {
        toast.error(data?.message);
      }
    } catch (error: any) {
      yield put(loadVerifyFail(error.message));
    }
  }
  export function* subscriptionPlanUserSaga(): Generator<any, void, any> {
    yield takeLatest(loadSubscriptionPlanUsersRequested, subcriptionPlanUsersEffect);
    yield takeLatest(loadSubscriptionPlanUserRequested, subcriptionPlanUserEffect);
    yield takeLatest(loadVerifyRequested, VerifyEffect);
  }