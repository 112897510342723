import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'lib/axios';
import { apiURL } from 'config';
import toast from 'react-hot-toast';

import {
  loadRolesRequested,
  loadRolesSuccess,
  loadRolesFail,
  createRoleRequested,
  createRoleSuccess,
  createRoleFail,
  updateRoleRequested,
  updateRoleSuccess,
  updateRoleFail,
  deleteRoleSuccess,
  deleteRoleFail,
  deleteRoleRequested,
  assignRolePermissionRequested,
  assignRolePermissionSuccess,
  assignRolePermissionFail,
} from './roleSlice';

function* RolesEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(axios.get, `${apiURL}role`);
    yield put(loadRolesSuccess(data));
  } catch (error: any) {
    yield put(loadRolesFail(error.message));
  }
}

function* updateRoleEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.put,
      `${apiURL}role`,
      action.payload.values
    );

    if (data) {
      yield put(updateRoleSuccess(data));
      toast.success(data?.message);
    }
  } catch (error: any) {
    yield put(updateRoleFail(error.message));
  }
}

function* assignRoleEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    // console.log(action)
    const { data } = yield call(
      axios.post,
      `${apiURL}role/permissions/assign-permissions`,
      action.payload
    );
    // console.log(data)

    if (data?.success) {
      yield put(assignRolePermissionSuccess(data));
      toast.success(data?.message);
    } else {
      yield put(assignRolePermissionFail(data));
      toast.success(data?.message);
    }
  } catch (error: any) {
    // console.log('catch', error)
    yield put(assignRolePermissionFail(error.message));
  }
}

function* createRoleEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.post,
      `${apiURL}role`,
      action.payload.values
    );

    if (data) {
      yield put(createRoleSuccess(data));
      toast.success(data);
    }
  } catch (error: any) {
    yield put(createRoleFail(error.message));
  }
}

function* deleteRoleEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.delete,
      `${apiURL}role/${action?.payload?.item?.id}`
    );

    if (data?.success) {
      toast.success(data?.message);
      yield put(deleteRoleSuccess(data));
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(deleteRoleFail(error.message));
  }
}

export function* rolesSaga(): Generator<any, void, any> {
  yield takeLatest(loadRolesRequested, RolesEffect);
  yield takeLatest(updateRoleRequested, updateRoleEffect);
  yield takeLatest(createRoleRequested, createRoleEffect);
  yield takeLatest(deleteRoleRequested, deleteRoleEffect);
  yield takeLatest(assignRolePermissionRequested, assignRoleEffect);
}
