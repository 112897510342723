import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'lib/axios';
import { apiURL } from 'config';
import toast from 'react-hot-toast';

import {
  createBannerFail,
  createBannerRequested,
  createBannerSuccess,
  deleteBannerFail,
  deleteBannerRequested,
  deleteBannerSuccess,
  loadBannersFail,
  loadBannersRequested,
  loadBannersSuccess,
} from './bannersSlice';

function* loadBannersEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const response = yield call(axios.get, `${apiURL}user/get-all-banner-images`, action.payload.values);
    yield put(loadBannersSuccess(response.data));
  } catch (error: any) {
    yield put(loadBannersFail(error.message));
  }
}

function* createBannerEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const formData = new FormData();
    formData.append('banner-image', action.payload.bannerImage);

    const response = yield call(axios.post, `${apiURL}admin/add-banner-image`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    yield put(createBannerSuccess(response.data));
  } catch (error: any) {
    yield put(createBannerFail(error.message));
  }
}

function* deleteBannerEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.delete,
      `${apiURL}admin/image/${action?.payload?.item?.key}/delete-image`,
      action.payload.values
    );
    yield put(deleteBannerSuccess(data));

    if (data?.success) {
      toast.success(data?.message);
      yield put(loadBannersRequested({}));
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(deleteBannerFail(error.message));
  }
}

export function* bannersSaga(): Generator<any, void, any> {
  yield takeLatest(loadBannersRequested, loadBannersEffect);
  yield takeLatest(createBannerRequested, createBannerEffect);
  yield takeLatest(deleteBannerRequested, deleteBannerEffect);
}
