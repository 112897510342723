import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  error: null,
  challenges: [],
  createChallenge: '',
  challenge: {},
  challengeById: {},
};

const challengesSlice = createSlice({
  name: 'challenges',
  initialState,
  reducers: {
    loadChallengesRequested(state, action) {
      state.loading = true;
      state.error = null;
    },
    loadChallengesSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.challenges = action.payload;
    },

    loadChallengesFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    loadChallengeRequested(state, action: PayloadAction<{ id: any }>) {
      state.loading = true;
      state.error = null;
    },
    loadChallengeSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.challengeById = action.payload;
    },

    loadChallengeFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    
    createChallengeRequested(
      state,
      action: PayloadAction<{
        values: {
          amount: string;
          expiryDuration: string;
          releasePercentage: string;
          taskPolicyType: string;
          description: string;
        };
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    createChallengeSuccess(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = null;
      state.createChallenge = action.payload;
    },
    createChallengeFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    deleteChallengeRequested(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.challenge = action.payload;
    },
    deleteChallengeSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.challenge = action.payload;
    },

    deleteChallengeFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    
  },
});

export const {
  loadChallengesRequested,
  loadChallengesSuccess,
  loadChallengesFail,
  createChallengeRequested,
  createChallengeSuccess,
  createChallengeFail,
  deleteChallengeRequested,
  deleteChallengeSuccess,
   deleteChallengeFail,
   loadChallengeRequested,
   loadChallengeSuccess,
   loadChallengeFail,
 //  loadPChallengeRequested,
  //loadPChallengeSuccess,
  //loadPChallengeFail,
} = challengesSlice.actions;

export default challengesSlice.reducer;
