import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  error: null,
  cancelReasons: [],
  updateCancelReason: null,
  createCancelReason: '',
  cancelReason: {},
};

const cancelReasonSlice = createSlice({
  name: 'cancelReasons',
  initialState,
  reducers: {
    loadCancelReasonsRequested(state, action) {
      state.loading = true;
      state.error = null;
    },
    loadCancelReasonsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.cancelReasons = action.payload;
    },

    loadCancelReasonsFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    updateCancelReasonRequested(
      state,
      action: PayloadAction<{
        values: {
          id?: any;
          reason?: string;
          // userType?: any;
          price?: string;
        };
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    updateCancelReasonSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.updateCancelReason = action.payload;
    },
    updateCancelReasonFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    createCancelReasonRequested(
      state,
      action: PayloadAction<{
        values: {
          reason: string;
          userType: any;
          price: string;
        };
      }>
    ) {
      state.loading = true;
      state.error = null;
    },

    createCancelReasonSuccess(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = null;
      state.createCancelReason = action.payload;
    },

    createCancelReasonFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    deleteCancelReasonRequested(
      state,
      action: PayloadAction<{
        item: any;
      }>
    ) {
      state.loading = false;
      state.error = null;
      state.cancelReason = action.payload;
    },
    deleteCancelReasonSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.cancelReason = action.payload;
    },

    deleteCancelReasonFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadCancelReasonsRequested,
  loadCancelReasonsSuccess,
  loadCancelReasonsFail,
  updateCancelReasonRequested,
  updateCancelReasonSuccess,
  updateCancelReasonFail,
  createCancelReasonRequested,
  createCancelReasonSuccess,
  createCancelReasonFail,
  deleteCancelReasonRequested,
  deleteCancelReasonSuccess,
  deleteCancelReasonFail,
} = cancelReasonSlice.actions;

export default cancelReasonSlice.reducer;
