import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//import { loadPChallengeFail, loadPChallengeRequested, loadPChallengeSuccess } from 'features/PChallenges/challengesSlice';

const initialState: any = {
  loading: false,
  error: null,
  passengers: [],
  paginationOption: {},
  passenger: {},
  passengerById: {},
  referrerCode: {},
  topUp: {},
  tripCount: 0,
  exportPdf:{}
};

const passengersSlice = createSlice({
  name: 'passengers',
  initialState,
  reducers: {
    loadPassengersRequested(
      state,

      action: PayloadAction<{ filters: any; perPage: number; page: number }>
    ) {
      state.loading = true;
      state.error = null;
    },
    loadPassengersSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.passengers = action.payload.records;
      state.paginationOption = {
        pageNumber: action.payload?.pageNumber,
        pageSize: 10,
        totalRecords: action.payload?.totalRecords,
      };
    },

    loadPassengersFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    loadPassengerRequested(state, action: PayloadAction<{ id: any }>) {
      state.loading = true;
      state.error = null;
      state.passengerById = null;
    },
    loadPassengerSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.passengerById = action.payload;
    },

    loadPassengerFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    deletePassengerRequested(
      state,
      action: PayloadAction<{
        item: any;
      }>
    ) {
      state.loading = false;
      state.error = null;
      state.passenger = action.payload;
    },

    deletePassengerSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.passenger = action.payload;
    },

    deletePassengerFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },

    loadPassengerReferrerCodeRequested(
      state,
      action: PayloadAction<{ id: any }>
    ) {
      state.loading = true;
      state.error = null;
    },
    loadPassengerReferrerCodeSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.referrerCode = action.payload.records;
    },

    loadPassengerReferrerCodeFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    /* user top up history */
    loadPassengerTopUpRequested(state, action: PayloadAction<{ id: any }>) {
      state.loading = true;
      state.error = null;
    },
    loadPassengerTopUpSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.topUp = action.payload.records;
    },

    loadPassengerTopUpFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    loadPChallengeRequested(state, action: PayloadAction<{ id: any }>) {
      state.driverloading = true;
      state.error = null;
    },
    loadPChallengeSuccess(state, action: PayloadAction<any>) {
      state.driverloading = false;
      state.error = null;
      state.driver = action.payload;
    },

    loadPChallengeFail(state, action: PayloadAction<string>) {
      state.driverloading = false;
      state.error = action.payload;
    },
    passengerTripCountRequested(
      state,
      action: PayloadAction<{ user_id: any }>
    ) {
      state.loading = true;
      state.error = null;
    },

    passengerTripCountSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.tripCount = action.payload;
    },

    passengerTripCountFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    loadPassengerExportPdfRequested(
      state,
      action: PayloadAction
    ) {
      state.driverloading = true;
      state.exportPdf = null;
      state.error = null;
     
    },
    loadPassengerExportPdfSuccess(state, action: PayloadAction<any>) {
      state.driverloading = false;
      state.error = null;
      state.exportPdf = action.payload;
      
    },

    loadPassengerExportPdfFail(state, action: PayloadAction<string>) {
      state.driverloading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadPassengersRequested,
  loadPassengersSuccess,
  loadPassengerRequested,
  loadPassengerSuccess,
  loadPassengerFail,
  loadPassengersFail,
  deletePassengerRequested,
  deletePassengerSuccess,
  deletePassengerFail,
  loadPassengerReferrerCodeRequested,
  loadPassengerReferrerCodeSuccess,
  loadPassengerReferrerCodeFail,
  loadPassengerTopUpRequested,
  loadPassengerTopUpSuccess,
  loadPassengerTopUpFail,
  loadPChallengeRequested,
  loadPChallengeSuccess,
  loadPChallengeFail,
  passengerTripCountRequested,
  passengerTripCountSuccess,
  passengerTripCountFail,
  loadPassengerExportPdfRequested,
  loadPassengerExportPdfSuccess,
  loadPassengerExportPdfFail
} = passengersSlice.actions;

export default passengersSlice.reducer;
