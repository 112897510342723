import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'axios';
import { apiURL } from 'config';

import { loadPlacesFail, loadPlacesRequested, loadPlacesSuccess } from './placeSlice';


function* loadplacesEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  // debugger
  try {
    const { data } = yield call(axios.get, `${apiURL}file/place?file_name=place` );
    yield put(loadPlacesSuccess(data));
  } catch (error: any) {
    yield put(loadPlacesFail(error.message));
  }
}

export function* placeSaga(): Generator<any, void, any> {
  yield takeLatest(loadPlacesRequested, loadplacesEffect);
}
