import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  error: null,
  places: [],
};

const placeSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    loadPlacesRequested(state, action: PayloadAction<{ id: any }>) {
      state.loading = true;
      state.error = null;
    },
    loadPlacesSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.places = action.payload;
    },

    loadPlacesFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    loadProviceRequested(state, action: PayloadAction<{ id: any }>) {
      state.loading = true;
      state.error = null;
    },
    loadProviceSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.places = action.payload;
    },

    loadProviceFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
loadPlacesRequested, loadPlacesSuccess, loadPlacesFail
} = placeSlice.actions;

export default placeSlice.reducer;
