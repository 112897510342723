import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
    loading: false,
    error: null,
    sheduleRides: [],
    sheduleRide: null,
    paginationOption: {},
    
  };

  const sheduleRidesSlice = createSlice({
    name: 'sheduleRides',
    initialState,
    reducers: {
      loadSheduleRidesRequested(state, action) {
        state.loading = true;
        state.error = null;
      },
      loadSheduleRidesSuccess(state, action: PayloadAction<any>) {
        state.loading = false;
        state.error = null;
        state.sheduleRides = action.payload.records;
        state.paginationOption = {
          pageNumber: action.payload?.pageNumber,
          pageSize: 10,
          totalRecords: action.payload?.totalRecords,
        };
      },
  
      loadSheduleRidesFail(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },
  
      loadSheduleRideRequested(state, action: PayloadAction<{ id: any }>) {
        state.sheduleRide = null;
        state.loading = true;
        state.error = null;
      },
      loadSheduleRideSuccess(state, action: PayloadAction<any>) {
        state.loading = false;
        state.error = null;
        state.sheduleRide = action.payload;
      },
  
      loadSheduleRideFail(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },
    },
});

export const {
    loadSheduleRidesRequested,
    loadSheduleRidesSuccess,
    loadSheduleRidesFail,
    loadSheduleRideRequested,
    loadSheduleRideSuccess,
    loadSheduleRideFail,
    
  } = sheduleRidesSlice.actions;
  
  export default sheduleRidesSlice.reducer;
