import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  error: null,
  permissions: [],
  updatePermission: null,
  createPermission: '',
  permissionDelete: {},
};

const permissionSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    loadPermissionsRequested(state, action) {
      state.loading = true;
      state.error = null;
    },
    loadPermissionsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.permissions = action.payload;
    },
    loadPermissionsFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    updatePermissionRequested(
      state,
      action: PayloadAction<{
        values: {
          type: string;
          value:string;
        };
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    updatePermissionSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.updatePermission = action.payload;
    },
    updatePermissionFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    createPermissionRequested(
      state,
      action: PayloadAction<{
        values: {
          type: string;
          value:string;
        };
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    createPermissionSuccess(state, action: PayloadAction<any>) {
      // console.log('permission create : ', action.payload);
      state.loading = false;
      state.error = null;
      state.createPermission = action.payload;
    },
    createPermissionFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    deletePermissionRequested(state) {
      state.loading = true;
      state.error = null;
    },
    deletePermissionSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.permissionDelete = action.payload;
    },
    deletePermissionFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadPermissionsRequested,
  loadPermissionsSuccess,
  loadPermissionsFail,
  updatePermissionRequested,
  updatePermissionSuccess,
  updatePermissionFail,
  createPermissionRequested,
  createPermissionSuccess,
  createPermissionFail,
  deletePermissionRequested,
  deletePermissionSuccess,
  deletePermissionFail,
} = permissionSlice.actions;
export default permissionSlice.reducer;
