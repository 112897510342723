const Provinces = [
  { name: 'Western Province', id: 'western_province' },
  { name: 'Central Province', id: 'central_province' },
  { name: 'Southern Province', id: 'southern_province' },
  { name: 'Northern Province', id: 'northern_province' },
  { name: 'Eastern Province', id: 'eastern_province' },
  { name: 'North Central Province', id: 'North_Central_Province' },
  {
    name: 'North Western Province',
    id: 'north_western_province',
  },

  { name: 'Uva Province', id: 'uva_province' },
  {
    name: 'Sabaragamuwa Province',
    id: 'sabaragamuwa_province',
  },
];
export default Provinces;
