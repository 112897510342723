import React from 'react';
import SelectComponent from 'react-select';

type Props = {
  name?: string;
  values?: any;
  placeholder?: any;
  options?: any;
  onChange?: any;
  getOptionLabel?: any;
  getOptionValue?: any;
  ref?: any;
  onKeyDown?: any;
  className?: any;
  defaulValue?: any;
  isDisabled?: boolean;
  required?: boolean;
  isClearable?: boolean;
};

function SelectNV(props: Props) {
  return (
    <>
      <SelectComponent
        required={props?.required}
        isDisabled={props?.isDisabled}
        ref={props?.ref}
        isClearable={props?.isClearable !== undefined ? props?.isClearable : true}
        isSearchable
        placeholder={props.placeholder}
        options={props.options}
       
        defaultValue={props.defaulValue}
        
        name={props.name}
        onChange={props.onChange}
        getOptionLabel={(option) => option[props.getOptionLabel]}
        getOptionValue={(option) => option[props.getOptionValue]}
        onKeyDown={props?.onKeyDown}
        theme={(theme) => ({
          ...theme,
          borderRadius: 5,

          colors: {
            ...theme.colors,

            text: '#000',
            primary25: '#98b5f5',
            primary50: '#fff',

            primary: '#000',
          },
        })}
        className={`text-xs ${props?.className ? props?.className : ''}`}
        styles={{
          control: () => ({
            display: 'flex',
            backgroundColor: '#f8f9fa',
            borderRadius: '4px',
          }),
        }}
      />
    </>
  );
}

export default SelectNV;
