import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  error: null,
  trip: [],
};

const tripSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    loadTripRequested(state, action: PayloadAction<{ id: any }>) {
      state.loading = true;
      state.error = null;
    },
    loadTripSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.trip = action.payload;
    },

    loadTripFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    loadDriverTripsRequested(
      state,
      action: PayloadAction<{ id: any; offset?: number; limit?: number }>
    ) {
      state.loading = true;
      state.error = null;
    },
    loadDriverTripsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.trip = action.payload;
    },

    loadDriverTripsFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    loadPassengerTripsRequested(state, action: PayloadAction<{ id: any; offset?: number; limit?: number }>) {
      state.loading = true;
      state.error = null;
    },
    loadPassengerTripsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.trip = action.payload;
    },
    loadPassengerTripsFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadTripRequested,
  loadTripSuccess,
  loadTripFail,
  loadDriverTripsRequested,
  loadDriverTripsSuccess,
  loadDriverTripsFail,
  loadPassengerTripsRequested,
  loadPassengerTripsSuccess,
  loadPassengerTripsFail,
} = tripSlice.actions;

export default tripSlice.reducer;
