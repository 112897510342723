const TripStatus = [
  { id: 'REQUESTED', name: 'REQUESTED' },
  { id: 'ACCEPTED', name: 'ACCEPTED' },
  { id: 'ARRIVED', name: 'ARRIVED' },
  { id: 'INPROGRESS', name: 'IN PROGRESS' },
  { id: 'COMPLETED', name: 'COMPLETED' },
  { id: 'CANCELLED', name: 'CANCELLED' },
  { id: 'EXPIRED', name: 'EXPIRED' },
  { id: 'PAYMENT_PENDING', name: 'PAYMENT PENDING' },
  { id: 'PAYMENT_COMPLETED', name: 'PAYMENT COMPLETED' },
  { id: 'REVIEW_PENDING', name: 'REVIEW PENDING' },
  { id: 'REVIEW_COMPLETED', name: 'REVIEW COMPLETED' },
];
export default TripStatus;
