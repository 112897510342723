import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  error: null,
  users: [],
  paginationOption: {},
  user: {},
  storeAdmin: null,
  updateAdmin: null,
  admins: [],
  userByPhoneNumber: {},
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    loadUsersRequested(
      state,

      action: PayloadAction<{ filters: any; perPage: number; page: number }>
    ) {
      state.loading = true;
      state.error = null;
    },
    loadUsersSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.users = action.payload.records;
      state.paginationOption = {
        pageNumber: action.payload?.pageNumber,
        pageSize: 10,
        totalRecords: action.payload?.totalRecords,
      };
    },

    loadUsersFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    deleteUerRequested(
      state,
      action: PayloadAction<{
        item: any;
      }>
    ) {
      state.loading = false;
      state.error = null;
      state.user = action.payload;
    },
    deleteUerSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.user = action.payload;
    },

    deleteUerFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },

    storeAdminRequested(
      state,
      action: PayloadAction<{
        values: {
          firstName: string;
          lastName: string;
          email: string;
          phoneNo: string;
          password: string;
          userType: string;
          roleIds: any[];
          province: string;
          district: string;
        };
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    storeAdminSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.storeAdmin = action.payload;
    },
    storeAdminFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    updateAdminRequested(
      state,
      action: PayloadAction<{
        values: {
          id: string;
          name: string;
        };
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    updateAdminSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.updateAdmin = action.payload;
    },

    updateAdminFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    loadAdminsRequested(
      state,
      action: PayloadAction<{ filters: any; perPage: number; page: number }>
    ) {
      state.loading = true;
      state.error = null;
    },

    loadAdminsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.admins = action.payload;
    },

    loadAdminsFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    loadUsersByPhoneNumberRequested(
      state,
      action: PayloadAction<{ phoneNumber: string; userType: string }>
    ) {
      state.loading = true;
      state.error = null;
    },

    loadUsersByPhoneNumberSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.userByPhoneNumber = action.payload;
    },

    loadUsersByPhoneNumberFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadUsersRequested,
  loadUsersSuccess,
  loadUsersFail,
  deleteUerRequested,
  deleteUerSuccess,
  deleteUerFail,
  storeAdminRequested,
  storeAdminSuccess,
  storeAdminFail,
  updateAdminRequested,
  updateAdminSuccess,
  updateAdminFail,
  loadAdminsRequested,
  loadAdminsSuccess,
  loadAdminsFail,
  loadUsersByPhoneNumberRequested,
  loadUsersByPhoneNumberSuccess,
  loadUsersByPhoneNumberFail,
} = usersSlice.actions;

export default usersSlice.reducer;
