import { takeEvery, put, call, takeLatest } from 'redux-saga/effects';
import axios from 'lib/axios';
import { apiURL, paymentURL, tripURL } from 'config';
import toast from 'react-hot-toast';

import {
  loadPassengersRequested,
  loadPassengersSuccess,
  loadPassengersFail,
  deletePassengerSuccess,
  deletePassengerFail,
  deletePassengerRequested,
  loadPassengerRequested,
  loadPassengerSuccess,
  loadPassengerFail,
  loadPassengerReferrerCodeSuccess,
  loadPassengerReferrerCodeFail,
  loadPassengerReferrerCodeRequested,
  loadPassengerTopUpRequested,
  loadPassengerTopUpSuccess,
  loadPassengerTopUpFail,
  loadPChallengeRequested,
  loadPChallengeSuccess,
  loadPChallengeFail,
  passengerTripCountSuccess,
  passengerTripCountFail,
  passengerTripCountRequested,
  loadPassengerExportPdfRequested,
  loadPassengerExportPdfSuccess,
  loadPassengerExportPdfFail,
} from './passengerSlice';

function* loadPassengersEffect(action: {
  type: string;
  payload: {
    filters: any;
    perPage: number;
    page: number;
  };
}): Generator<any, void, any> {
  const { filters } = action.payload;
  try {
    const { data } = yield call(
      axios.get,
      `/users/filter?userType=RIDER&page=${action.payload.page}&pageSize=${
        action.payload.perPage
      }&pageCount=${action.payload.page}${
        filters.query ? '&search=' + filters.query : ''
      }${
        filters?.provincePlace?.label
          ? '&province=' + filters?.provincePlace?.label
          : ''
      }${
        filters?.districtPlace?.label
          ? '&district=' + filters?.districtPlace?.label
          : ''
      }${filters?.cityPlace?.label ? '&city=' + filters?.cityPlace?.label : ''}`
    );

    yield put(loadPassengersSuccess(data));
  } catch (error: any) {
    yield put(loadPassengersFail(error.message));
  }
}

function* passengerEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.get,
      `${apiURL}user/${action?.payload?.id}`
    );
    yield put(loadPassengerSuccess(data));
  } catch (error: any) {
    yield put(loadPassengerFail(error.message));
  }
}

function* deletePassengerEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.put,
      `${apiURL}users/active?id=${action?.payload?.item?.id}`,
      action.payload.values
    );
    yield put(deletePassengerSuccess(data));

    if (data?.success) {
      toast.success(data?.message);
      yield put(loadPassengersRequested({ filters: '', perPage: 10, page: 1 }));
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(deletePassengerFail(error.message));
  }
}

function* driverReferrerCodeEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  const { id } = action.payload;

  try {
    const { data } = yield call(
      axios.get,
      `${paymentURL}referrer_code/filter?referrer_id=${id}`
    );
    yield put(loadPassengerReferrerCodeSuccess(data));
  } catch (error: any) {
    yield put(loadPassengerReferrerCodeFail(error.message));
  }
}
function* PassengerTopUpEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.get,
      `${paymentURL}transactions/filter?user_id=${action?.payload?.id}&transactionType=TOP_UP`
    );
    yield put(loadPassengerTopUpSuccess(data));
  } catch (error: any) {
    yield put(loadPassengerTopUpFail(error.message));
  }
}
function* loadPChallengeEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.get,
      `${paymentURL}challenges?user_id=${action?.payload?.id}`
    );
    yield put(loadPChallengeSuccess(data));
  } catch (error: any) {
    yield put(loadPChallengeFail(error.message));
  }
}

function* PassengerTripCountEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.get,
      `${tripURL}trip-app/trip/trip-count?user_id=${action?.payload?.user_id}&user_type=RIDER`
    );
    const total =
      (data?.CANCELLED || 0) +
      (data?.COMPLETED || 0) +
      (data?.EXPIRED || 0) +
      (data?.SYSTEM_CANCELED || 0);
    yield put(passengerTripCountSuccess(total));
  } catch (error: any) {
    yield put(passengerTripCountFail(error.message));
  }
}
function* passengerExportPdfEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  //const {values} = action.payload;

  try {
    const { data } = yield call(
      axios.get,
      `${apiURL}users/drivers-details/pdf-generate?userType=RIDER&accountDeleted=false`,
      { responseType: 'blob' }
    );

        // Create a URL for the PDF
        const url = window.URL.createObjectURL(new Blob([data]));

        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'passenger_export.pdf'); // Set the file name

        // Append to body
        document.body.appendChild(link);
        link.click(); // Simulate click to download

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    console.log(data);
    yield put(loadPassengerExportPdfSuccess(data));
  } catch (error: any) {
    yield put(loadPassengerExportPdfFail(error.message));
  }
}

export function* passengersSaga(): Generator<any, void, any> {
  yield takeEvery(loadPassengersRequested, loadPassengersEffect);
  yield takeLatest(deletePassengerRequested, deletePassengerEffect);
  yield takeLatest(loadPassengerRequested, passengerEffect);
  yield takeLatest(
    loadPassengerReferrerCodeRequested,
    driverReferrerCodeEffect
  );
  yield takeLatest(loadPassengerTopUpRequested, PassengerTopUpEffect);
  yield takeLatest(loadPChallengeRequested, loadPChallengeEffect);
  yield takeLatest(passengerTripCountRequested, PassengerTripCountEffect);
  yield takeLatest(loadPassengerExportPdfRequested, passengerExportPdfEffect);
}
