import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'lib/axios';
import { authURL } from 'config';
import toast from 'react-hot-toast';

import { loginRequested, loginSuccess, loginFailure } from './authSlice';

function* loginSaga(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(axios.post, `${authURL}`, action.payload);

    if (data?.success) {
      yield put(loginSuccess(data));
      toast.success(data?.message);
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(loginFailure(error.message));
  }
}

export function* authSaga(): Generator<any, void, any> {
  yield takeLatest(loginRequested, loginSaga);
}
