import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { isAuthorized as useAuthorized } from 'selectors/auth';
import { LazyPage, NotFound } from 'components';
import { Layout } from 'layout';
import * as routes from 'constants/routes';

const Router = () => {
  const isAuthorized = useSelector(useAuthorized);

  const location = useLocation();

  const PrivateRoute = ({ children }: any) => {
    if (!isAuthorized) {
      return <Navigate to={routes.LOGIN} state={{ from: location }} replace />;
    }

    return children;
  };

  return (
    <React.Fragment>
      <Routes>
        <Route
          path={routes.HOME}
          element={
            <PrivateRoute>
              <Layout />
            </PrivateRoute>
          }
        >
          <Route index element={<LazyPage page="Dashboard" />} />
          <Route path={routes.USERS} element={<LazyPage page="Users" />} />
          <Route path={routes.DRIVERS} element={<LazyPage page="Drivers" />} />
          <Route
            path={routes.DRIVER_DETAIL}
            element={<LazyPage page="Drivers/Detail" />}
          />
          <Route
            path={routes.DRIVER_CREATE}
            element={<LazyPage page="Drivers/Create" />}
          />
          <Route path={routes.CABS} element={<LazyPage page="Cabs" />} />
          <Route path={routes.BRAND} element={<LazyPage page="Brand" />} />
          <Route path={routes.D_S_OFFICE} element={<LazyPage page="DsOffice" />} />
          <Route path={routes.MODEL} element={<LazyPage page="Model" />} />
          <Route path={routes.ALERTS} element={<LazyPage page="Alerts" />} />
          <Route
            path={routes.ALERT_DETAIL}
            element={<LazyPage page="Alerts/Detail" />}
          />
          <Route path={routes.RIDES} element={<LazyPage page="Rides" />} />
          <Route
            path={routes.RIDE_DETAIL}
            element={<LazyPage page="Rides/Detail" />}
          />
           <Route path={routes.SHEDULE_RIDE} element={<LazyPage page="SheduleRides" />} />
          <Route
            path={routes.SHEDULE_RIDE_DETAIL}
            element={<LazyPage page="SheduleRides/Detail" />}
          />
          <Route path={routes.PRICING} element={<LazyPage page="Pricing" />} />
          <Route path={routes.OFFERS} element={<LazyPage page="Offers" />} />
          <Route
            path={routes.PASSENGERS}
            element={<LazyPage page="Passengers" />}
          />
          <Route
            path={routes.PASSENGERS_DETAIL}
            element={<LazyPage page="Passengers/Detail" />}
          />
          <Route path={routes.ROLE} element={<LazyPage page="Role" />} />
          <Route
            path={routes.PROMOTIONS}
            element={<LazyPage page="Promotions" />}
          />
          <Route
            path={routes.CANCELLATION}
            element={<LazyPage page="CancelReasons" />}
          />
          <Route path={routes.BANNER} element={<LazyPage page="Banners" />} />
          <Route
            path={routes.WITHDRAWAL}
            element={<LazyPage page="Withdrawal" />}
          />
          <Route
            path={routes.WITHDRAWAL_DETAILS}
            element={<LazyPage page="Withdrawal/Detail" />}
          />
          <Route
            path={routes.CHALLENGES}
            element={<LazyPage page="Challenges" />}
          />
          <Route path={routes.TOPUP} element={<LazyPage page="TopUp" />} />
          <Route
            path={routes.TOPUP_DETAILS}
            element={<LazyPage page="TopUp/Details" />}
          />
          <Route
            path={routes.PERMISSIONS}
            element={<LazyPage page="Permissions" />}
          />
          <Route
            path={routes.STANDPREMIUM}
            element={<LazyPage page="StandPremium" />}
          />
          <Route
            path={routes.PREMIUM_DRIVERS}
            element={<LazyPage page="PremiumDrivers" />}
          />
          <Route
            path={routes.CHALLENGES_USERS}
            element={<LazyPage page="ChallengesUser" />}
          />
          <Route
            path={routes.SUBSCRIPTION_PLAN}
            element={<LazyPage page="SubscriptionPlan" />}
          />
          <Route
            path={routes.SUBSCRIPTION_PLAN_USER}
            element={<LazyPage page="SubscriptionPlanUser" />}
          />
           <Route
            path={routes.SUBSCRIPTION_PLAN_USER_DETAILS}
            element={<LazyPage page="SubscriptionPlanUser/Details" />}
          />
          <Route
            path={routes.DRIVER_STAND_PREMIUM}
            element={<LazyPage page="Drivers/Detail/StandPremium" />}
          />
          <Route
            path={routes.DRIVER_CAB_VERIFICATION}
            element={<LazyPage page="Drivers/Detail/CabVerification" />}
          />
          <Route
            path={routes.DRIVER_CAB_UPDATE}
            element={<LazyPage page="Drivers/Detail/CabDetailsUpdate" />}
          />
          <Route
            path={routes.DRIVER_VERIFICATION}
            element={<LazyPage page="Drivers/Detail/DriverVerification" />}
          />
        </Route>

        <Route element={<LazyPage page="Login" />} path={routes.LOGIN} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </React.Fragment>
  );
};

export default Router;
