import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'lib/axios';
import { paymentURL } from 'config';
import toast from 'react-hot-toast';

import {
  createStandPremiumFail,
  createStandPremiumRequested,
  createStandPremiumSuccess,
  deleteStandPremiumFail,
  deleteStandPremiumRequested,
  deleteStandPremiumSuccess,
  loadStandPremiumsFail,
  loadStandPremiumsRequested,
  loadStandPremiumsSuccess,
  updateStandPremiumFail,
  updateStandPremiumRequested,
  updateStandPremiumSuccess,
} from './standPremiumSlice';

function* standPremiumsEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(axios.get, `${paymentURL}stand_premium_policy/status?is_active=true`);

    yield put(loadStandPremiumsSuccess(data));
  } catch (error: any) {
    yield put(loadStandPremiumsFail(error.message));
  }
}

function* updateStandPremiumEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.put,
      `${paymentURL}stand_premium_policy/${action.payload.values?.id}`,
      action.payload.values
    );
    yield put(updateStandPremiumSuccess(data));
    
    if (data?.success) {
      yield put(loadStandPremiumsRequested(data));
      toast.success(data?.message);
    }
  } catch (error: any) {
    yield put(updateStandPremiumFail(error.message));
  }
}

function* createStandPremiumEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.post,
      `${paymentURL}stand_premium_policy`,
      action.payload.values
    );

    yield put(createStandPremiumSuccess(data));

    if (data?.success) {
      toast.success(data?.message);
      yield put(loadStandPremiumsRequested(data));
    }
  } catch (error: any) {
    yield put(createStandPremiumFail(error.message));
  }
}

function* deleteStandPremiumEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.delete,
      `${paymentURL}stand_premium_policy/${action?.payload?.item?.id}`,
      action.payload.values
    );

    if (data?.success) {
      toast.success(data?.message);
      yield put(deleteStandPremiumSuccess(data));
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(deleteStandPremiumFail(error.message));
  }
}

export function* standPremiumSaga(): Generator<any, void, any> {
  yield takeLatest(loadStandPremiumsRequested, standPremiumsEffect);
  yield takeLatest(updateStandPremiumRequested, updateStandPremiumEffect);
  yield takeLatest(createStandPremiumRequested, createStandPremiumEffect);
  yield takeLatest(deleteStandPremiumRequested, deleteStandPremiumEffect);
}
