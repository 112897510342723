import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  error: null,
  cabs: [],
  paginationOption: {},
  cabType: [],
  cab: {},
  updateCab: {},
  updateCabImg: {},
  cabDocumentsVerification: {},
  nearByCabs: [],
};

const cabsSlice = createSlice({
  name: 'cabs',
  initialState,
  reducers: {
    loadCabsRequested(
      state,

      action
    ) {
      state.loading = true;
      state.error = null;
    },
    loadCabsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.cabs = action?.payload?.records;
      state.paginationOption = {
        pageNumber: action.payload?.pageNumber,
        pageSize: 10,
        totalRecords: action.payload?.totalRecords,
      };
    },

    loadCabsFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    loadCabRequested(
      state,

      action: PayloadAction<{ id: any }>
    ) {
      state.loading = true;
      state.error = null;
      state.cab = null;
    },
    loadCabSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.cab = action.payload;
    },

    loadCabFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    storeCabRequested(
      state,
      action: PayloadAction<{
        values: {
          registeredYear: string;
          vehicleNumber: string;
          numberOfSeats: number;
          cabType: any;
          userId: any;
          model: any;
          brand: any;
          taxDocumentUrl: string;
          insuranceDocumentUrl: string;
          vehicleInsuranceCompany: string;
          vehicleInsuranceNumber: string;
          vehicleInsuranceIssueDate: any;
          vehicleInsuranceExpireDate: any;
          vehicleTaxCompany: any;
          vehicleTaxNumber: any;
          vehicleTaxIssueDate: any;
          vehicleTaxExpireDate: any;
        };

        cabImages: {
          taxDocumentImage: any;
          insuranceDocumentImage: any;
          veichleBookImage: any;
        };
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    storeCabSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.storeCab = action.payload;
    },
    storeCabFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    storeCabImagesRequested(
      state,
      action: PayloadAction<{
        id: any;
        cabImages: {
          taxDocumentImage: any;
          insuranceDocumentImage: any;
          veichleBookImage: any;
        };
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    storeCabImagesSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.storeCabImages = action.payload;
    },
    storeCabImagesFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    updateCabRequested(
      state,
      action: PayloadAction<{
        values: {
          registeredYear?: string;
          vehicleNumber?: string;
          numberOfSeats?: any;
          cabType?: any;
          model?: any;
          brand?: any;
          taxDocumentUrl: string;
          insuranceDocumentUrl: string;
          vehicleInsuranceCompany: string;
          vehicleInsuranceNumber: string;
          vehicleInsuranceIssueDate: any;
          vehicleInsuranceExpireDate: any;
          vehicleTaxCompany: any;
          vehicleTaxNumber: any;
          vehicleTaxIssueDate: any;
          vehicleTaxExpireDate: any;
          userId: any;
        };

        id: any;
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    updateCabSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.updateCab = action.payload;
    },
    updateCabFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    updateCabDocumentRequested(
      state,
      action: PayloadAction<{
        id: any;
        taxDocumentImage: any;
        insuranceDocumentImage: any;
        veichleBookImage: any;
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    updateCabDocumentSuccess(state, action: any) {
      state.loading = false;
      state.error = null;
      state.updateCabImg = action.payload;
    },

    updateCabDocumentFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    cabDocumentsVerificationRequested(
      state,
      action: PayloadAction<{
        values: { documentType: string; isVerified: boolean; reason: string };
        id: any;
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    cabDocumentsVerificationSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.cabDocumentsVerification = action.payload;
    },
    cabDocumentsVerificationFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    cabTypeRequested(state, action) {
      state.loading = true;
      state.error = null;
    },
    cabTypeSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;

      const result: any = Object.entries(action?.payload).flatMap(
        ([key, values]: any) => {
          if (key === 'CAR' || key === 'TUK_TUK') {
            return values.map((value: any) => ({
              label: value.replace(/_/g, ' '),
              value,
            }));
          } else {
            return values.map((value: any) => ({ label: value, value: key }));
          }
        }
      );
      state.cabType = result;
    },

    cabTypeFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    deleteCabRequested(
      state,
      action: PayloadAction<{
        item: any;
      }>
    ) {
      state.loading = false;
      state.error = null;
      state.cab = action.payload;
    },
    deleteCabSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.cab = action.payload;
    },

    deleteCabFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },

    deleteDriverCabRequested(
      state,
      action: PayloadAction<{
        item: any;
      }>
    ) {
      state.loading = false;
      state.error = null;
      state.cab = action.payload;
    },
    deleteDriverCabSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.cab = action.payload;
    },

    deleteDriverCabFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    loadNearbyCabsRequested(
      state,
      action: PayloadAction<{
        position: any;
        cabType?: any;
      }>
    ) {
      state.loading = true;
      state.error = null;
      state.nearByCabs = [];
    },
    loadNearbyCabsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.nearByCabs = action.payload;
    },
    loadNearbyCabsFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadCabsRequested,
  loadCabsSuccess,
  loadCabsFail,
  loadCabFail,
  loadCabRequested,
  loadCabSuccess,
  storeCabRequested,
  storeCabFail,
  storeCabImagesFail,
  storeCabImagesRequested,
  storeCabImagesSuccess,
  storeCabSuccess,
  updateCabFail,
  updateCabRequested,
  updateCabSuccess,
  cabDocumentsVerificationFail,
  cabDocumentsVerificationRequested,
  cabDocumentsVerificationSuccess,
  cabTypeRequested,
  cabTypeSuccess,
  cabTypeFail,
  deleteCabRequested,
  deleteCabSuccess,
  deleteCabFail,
  deleteDriverCabSuccess,
  deleteDriverCabRequested,
  deleteDriverCabFail,
  updateCabDocumentRequested,
  updateCabDocumentSuccess,
  updateCabDocumentFail,
  loadNearbyCabsRequested,
  loadNearbyCabsSuccess,
  loadNearbyCabsFail,
} = cabsSlice.actions;

export default cabsSlice.reducer;
