import * as React from 'react';
// import { useNavigate } from 'react-router-dom';
import { TbEdit } from 'react-icons/tb';
// import { AiFillDelete } from 'react-icons/ai';
// import { MdArchive } from 'react-icons/md';
// import { FaEye } from 'react-icons/fa6';

type PermissionsMenuProps = {
  row?: any;
  viewRoute?: any;
  editRoute?: any;
  viewModal?: any;
  modalData?: any;
  editModal?: any;
  onDeleteItem?: any;
  deleteAction?: any;
  setSelectedItem?: any;
  setDeleteVisible?: any;
};
export default function PermissionsMenu(props: PermissionsMenuProps) {
//   const navigation = useNavigate();

  return (
    <div className="flex items-center cursor-pointer">
      {/* {props.viewRoute && (
        <FaEye
          className="w-6 h-6 mr-1 text-black"
          onClick={() =>
            navigation(
              props.viewRoute.replace(
                ':id',
                props.row?.id ? props.row?.id : props.row?.trip_id
              )
            )
          }
        />
      )} */}

      {/* {props.viewModal && (
        <FaEye
          className="w-6 h-6 mr-1 text-black"
          onClick={() => [props.viewModal(true), props.modalData(props.row)]}
        />
      )} */}

      {/* {props.editRoute && (
        <TbEdit
          className="w-6 h-6 mr-1 text-[#ff7b00]"
          onClick={() =>
            navigation(props.editRoute.replace(':id', props.row?.id))
          }
        />
      )} */}

      {props.editModal && (
        <TbEdit
          className="w-6 h-6 mr-1 text-[#7c7c7c]"
          onClick={() => [props.editModal(true), props.modalData(props.row)]}
        />
      )}

      {/* {props.deleteAction && (
        <MdArchive
          className="w-6 h-6 mr-1 text-sky-700"
          onClick={() => [
            props.setDeleteVisible(true),
            props.setSelectedItem(props?.row),
          ]}
        />
      )} */}
    </div>
  );
}
