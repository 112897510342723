import React, { useMemo, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import { Confirmation, Filteration } from 'components';
import { updateFilters } from 'features';
import { Dropdown } from 'primereact/dropdown';

import ActionMenu from './ActionMenu';
import NoData from './NoData';
import PermissionsMenu from './PermissionsMenu';

type ResourceTableProps = {
  columns?: any;
  data?: any;
  loading?: boolean;
  loadData?: any;
  deleteAction?: any;
  isPagination?: boolean;
  createRoute?: any;
  viewRoute?: any;
  editRoute?: any;
  tableMenu?: any;
  actionMenu?: any;
  viewModal?: any;
  modalData?: any;
  breadCrumbRoutes?: any;
  editModal?: any;
  createModal?: any;
  enableFilters?: any;
  pagination?: any;
  updateSelector?: any;
  createSelector?: any;
  rowIndex?: any;
  checkboxSelection?: boolean;
  checkboxeditmodal?: any;
  key?: any;
};

export default function ResourceTable(props: ResourceTableProps) {
  const dispatch = useDispatch();
  const { filters } = useSelector((state: any) => state.state);

  // console.log(filters)
  // console.log(props?.pagination)

  //states
  const [perPage, setPerPage] = useState(props?.pagination?.pageSize || 10);
  const [page, setPage] = useState(props?.pagination?.pageNumber || 1);
  const [first, setFirst] = useState(props?.pagination?.pageNumber || 1);
  //
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const handlePage = (event: any) => {
    setPage(event?.page + 1);
    setPerPage(event.rows);
    setFirst(event.first);
  };

  const actionTemplate = (row: any) => {
    return (
      <React.Fragment>
        <ActionMenu
          row={row}
          viewRoute={props.viewRoute}
          editRoute={props.editRoute}
          viewModal={props.viewModal}
          modalData={props.modalData}
          editModal={props.editModal}
          setSelectedItem={setSelectedItem}
          setDeleteVisible={setDeleteVisible}
          deleteAction={props.deleteAction}
        />
      </React.Fragment>
    );
  };

  const permissionsColumn = (row: any) => {
    return (
      <React.Fragment>
        <PermissionsMenu
          row={row}
          editModal={props.checkboxeditmodal}
          modalData={props.modalData}
        />
      </React.Fragment>
    );
  };

  //columns
  const tableColumns = props.columns?.map((col: any, i: any) => {
    return (
      <Column
        key={i}
        field={col.field}
        header={col.header}
        body={col.body}
        style={col.style}
        selectionMode={col.selection}
        editor={col.editor}
        onCellEditComplete={col.onCellEditComplete}
        headerStyle={col.headerStyle}
        align={col.align ? col.align : 'left'}
        alignHeader={col.headerAlign ? col.headerAlign : 'left'}
      />
    );
  });

  //with action menu
  const modifiedColumns = useMemo(() => {
    return [
      ...tableColumns,
      props.checkboxSelection && (
        <Column key={10} header="Permissions" body={permissionsColumn} />
      ),
      <Column  key={11} header="ACTIONS" body={actionTemplate} />,
    ];
  }, []);

  const resourceColumns = props.actionMenu ? modifiedColumns : tableColumns;

  //cell class
  const CellClass = () => {
    return 'hover:text-indigo-900';
  };

  //use effect for dispatch actions
  useLayoutEffect(() => {
    if (props.loadData) {
      dispatch(props.loadData({ filters, perPage, page }));
    }
  }, [
    dispatch,
    perPage,
    page,
    first,
    filters?.driverStatus,
    filters?.cabType,
    filters?.brand,
    filters?.model,
    filters?.province,
    filters?.district,
    filters?.query,
    filters?.city,
    filters?.defaultCabtype,
    filters?.tripStatus,
    filters?.place,
    filters?.provincePlace,
    filters?.districtPlace,
    filters?.cityPlace,
    filters?.verify,
    filters?.premiumStatus,
    filters?.userType,
    filters?.cabVerification,
    filters?.districtAll,
    filters?.dateRange,
    filters?.active
  ]);

  useLayoutEffect(() => {
    dispatch(updateFilters(props?.enableFilters));
  }, [dispatch]);

  const onDeleteItem = React.useCallback(async (item: any) => {
    await dispatch(props.deleteAction({ item: item }));
    await dispatch(props.loadData({ filters, perPage, page }));
    setDeleteVisible(false);
  }, []);

  useMemo(() => {
    if (props?.createSelector || props?.updateSelector) {
      props?.editModal(false);
      props?.createModal(false);
    }
  }, [props?.createSelector, props?.updateSelector]);

  const paginatorTemplate = {
    layout:
      'RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport',
    RowsPerPageDropdown: (options: any) => {
      const dropdownOptions = [
        { label: 5, value: 5 },
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: 100, value: 100 },
      ];
   

      return (
        <React.Fragment>
          <span
            className="mx-1"
            style={{ color: 'var(--text-color)', userSelect: 'none' }}
          >
            Items Per Page:{' '}
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
            className="w-24 h-10 border text-xs border-[#f9f8fc] rounded-md"
            panelClassName="text-xs"
          />
        </React.Fragment>
      );
    },
    PageLinks: (options: any) => {
      const isSelected = options.page === props?.pagination?.pageNumber - 1;
      const buttonClassName = isSelected
        ? `${options.className} text-green-900 bg-[#e3e3e8]`
        : options.className;
      // console.log('options', options);
      return (
        <button
          type="button"
          className={buttonClassName}
          onClick={options.onClick}
        >
          {options.page + 1}
        </button>
      );
    },
    CurrentPageReport: (options: any) => {
      return (
        <span
          style={{
            color: 'var(--text-color)',
            userSelect: 'none',
            width: '120px',
            textAlign: 'center',
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  return (
    <React.Fragment>
      <div className="py-4">
        {props.tableMenu && (
          <Filteration
            createRoute={props.createRoute}
            createModal={props.createModal}
            breadCrumbRoutes={props.breadCrumbRoutes}
          />
        )}
      </div>
      <div className="overflow-hidden shadow-sm">
        <DataTable
          value={props?.data}
          dataKey={props?.key ? props?.key : 'trip_id'}
          breakpoint="960px"
          emptyMessage={NoData}
          className="text-[12px] tracking-wider font-medium border-[1px]"
          cellClassName={CellClass}
          rowHover
          loading={props.loading}
          lazy
          // style={style}
        >
          {resourceColumns}
        </DataTable>
        {props.isPagination && (
          <Paginator
            rows={perPage}
            totalRecords={props?.pagination?.totalRecords}
            rowsPerPageOptions={[10, 20, 50, 100, 150, 200]}
            onPageChange={handlePage}
            first={first}
            className="w-full justify-end font-semibold text-xs tracking-widest cursor-pointer rounded-t-[0px] bg-gray-100"
            template={paginatorTemplate}
          />
        )}
      </div>

      <Confirmation
        setVisible={setDeleteVisible}
        visible={deleteVisible}
        message="Are you sure you want to archive this item"
        accept={() => onDeleteItem(selectedItem)}
      />
    </React.Fragment>
  );
}
