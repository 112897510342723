import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'lib/axios';
import { apiURL } from 'config';
import toast from 'react-hot-toast';

import {
  loadPermissionsRequested,
  loadPermissionsSuccess,
  loadPermissionsFail,
  updatePermissionRequested,
  updatePermissionSuccess,
  updatePermissionFail,
  createPermissionRequested,
  createPermissionSuccess,
  createPermissionFail,
  //   deletePermissionSuccess,
  //   deletePermissionFail,
  //   deletePermissionRequested,
} from './permissionSlice';

function* PermissionEffect(action: {
  type: string;
  payload: string;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(axios.get, `${apiURL}role/permissions/get-all`);
    yield put(loadPermissionsSuccess(data));
  } catch (error: any) {
    yield put(loadPermissionsFail(error.message));
  }
}

function* updatePermissionEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.put,
      `${apiURL}role/permissions/${action.payload.values.id}/edit`,
      {
        type: action.payload.values.type,
        value: action.payload.values.value,
        subPermissions: action.payload.values.subPermissions,
      }
    );

    yield put(updatePermissionSuccess(data));
    toast.success(data?.message);
  } catch (error: any) {
    yield put(updatePermissionFail(error.message));
  }
}

function* createPermissionEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  // debugger;
  try {
    const { data } = yield call(
      axios.post,
      `${apiURL}role/permissions/create-permission`,
      action.payload.values
    );
    yield put(createPermissionSuccess(data));
    toast.success(data?.message);
  } catch (error: any) {
    yield put(createPermissionFail(error.message));
  }
}

// function* deletePermissionEffect(action: {
//   type: string;
//   payload: any;
// }): Generator<any, void, any> {
//   try {
//     const { data } = yield call(
//       axios.delete,
//       `${apiURL}role/permissions/${action.payload.item.id}/delete`
//     );
//     yield put(deletePermissionSuccess(data));
//     toast.success(data);
//   } catch (error: any) {
//     yield put(deletePermissionFail(error.message));
//   }
// }
export function* permissionSaga(): Generator<any, void, any> {
  yield takeLatest(loadPermissionsRequested, PermissionEffect);
  yield takeLatest(updatePermissionRequested, updatePermissionEffect);
  yield takeLatest(createPermissionRequested, createPermissionEffect);
}
