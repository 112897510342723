import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'axios';
import { paymentURL } from 'config';
import toast from 'react-hot-toast';

import {
  loadSubscriptionPlanRequested,
  loadSubscriptionPlanSuccess,
  loadSubscriptionPlanFail,
  storeSubscriptionPlanRequested,
  storeSubscriptionPlanSuccess,
  storeSubscriptionPlanFail,
  updateSubscriptionPlanRequested,
  updateSubscriptionPlanSuccess,
  updateSubscriptionPlanFail,
  deleteSubscriptionPlanRequested,
  deleteSubscriptionPlanSuccess,
  deleteSubscriptionPlanFail,
} from './subcriptionPlanSlice';

function* subcriptionPlanEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  const { filters, perPage, page } = action.payload;
  try {
    const response = yield call(
      axios.get,
      `${paymentURL}subscription-plan?${perPage ? `pageSize=${perPage}` : ''}${
        page ? `&pageCount=${page}` : ''
      }${filters?.active ? `&active=${filters?.active?.value}` : ''}${
        filters?.cabType ? `&cabType=${filters?.cabType?.value}` : ''
      }${
        filters?.provincePlace
          ? `&provinces=${filters?.provincePlace?.value}`
          : ''
      }${
        filters?.districtPlace
          ? `&districts=${filters?.districtPlace?.value}`
          : ''
      }${filters?.cityPlace ? `&cities=${filters?.cityPlace?.value}` : ''}${
        filters?.query ? `&search=${filters?.query}` : ''
      }`
    );
    yield put(loadSubscriptionPlanSuccess(response.data));
  } catch (error: any) {
    console.log(error);
    yield put(loadSubscriptionPlanFail(error.message));
  }
}

function* storeSubscriptionEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.post,
      `${paymentURL}subscription-plan`,
      action.payload.values
    );
    if (data?.success) {
      toast.success(data?.message);
      yield put(storeSubscriptionPlanSuccess(data));
    } else {
      toast.error(data?.message);
      yield put(storeSubscriptionPlanFail(data?.message));
    }
  } catch (error: any) {
    console.log(error);
    yield put(storeSubscriptionPlanFail(error.message));
  }
}
function* updateSubscriptionEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.put,
      `${paymentURL}subscription-plan`,
      action.payload.values
    );

    yield put(updateSubscriptionPlanSuccess(data));
  } catch (error: any) {
    console.log(error);
    yield put(updateSubscriptionPlanFail(error.message));
  }
}

function* deleteSubscriptionEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.put,
      `${paymentURL}subscription-plan=${action?.payload?.item?.id}`,
      action.payload.values
    );

    if (data?.success) {
      toast.success(data?.message);
      yield put(deleteSubscriptionPlanSuccess(data));
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    console.log(error);
    yield put(deleteSubscriptionPlanFail(error.message));
  }
}

export function* subscriptionPlanSaga(): Generator<any, void, any> {
  yield takeLatest(loadSubscriptionPlanRequested, subcriptionPlanEffect);
  yield takeLatest(storeSubscriptionPlanRequested, storeSubscriptionEffect);
  yield takeLatest(updateSubscriptionPlanRequested, updateSubscriptionEffect);
  yield takeLatest(deleteSubscriptionPlanRequested, deleteSubscriptionEffect);
}
