import * as React from 'react';

export default function Badge({ status, className }: any) {
  const checkStatus = (status: any) => {
    if (status === 'CREATED') {
      return {
        color: 'bg-orange-100 text-orange-900',
        name: 'Created',
      };
    } else if (status === 'VIEWED') {
      return {
        color: 'bg-yellow-200 text-yellow-900',
        name: 'Viewed',
      };
    } else if (status === 'NEW') {
      return {
        color: 'bg-orange-100 text-orange-900',
        name: 'New',
      };
    } else if (status === 'REQUESTED') {
      return {
        color: 'bg-yellow-200 text-yellow-900',
        name: 'Requested',
      };
    } else if (status === 'ACCEPTED') {
      return {
        color: 'bg-green-100 text-green-900',
        name: 'Accepted',
      };
    } else if (status === 'ARRIVED') {
      return {
        color: 'bg-teal-200 text-teal-900',
        name: 'Arrived',
      };
    } else if (status === 'INPROGRESS') {
      return {
        color: 'bg-lime-200 text-lime-900',
        name: 'In progress',
      };
    } else if (status === 'IN_PROGRESS') {
      return {
        color: 'bg-lime-200 text-lime-900',
        name: 'In progress',
      };
    } else if (status === 'COMPLETED') {
      return {
        color: 'bg-green-200 text-green-900',
        name: 'Completed',
      };
    } else if (status === 'CANCELLED') {
      return {
        color: 'bg-red-200 text-red-900',
        name: 'Cancelled',
      };
    } else if (status === 'EXPIRED') {
      return {
        color: 'bg-red-200 text-red-900',
        name: 'Expired',
      };
    } else if (status === 'PAYMENT_PENDING') {
      return {
        color: 'bg-yellow-200 text-yellow-900',
        name: 'Payment Pending',
      };
    } else if (status === 'PAYMENT_COMPLETED') {
      return {
        color: 'bg-green-200 text-green-900',
        name: 'Payment Completed',
      };
    } else if (status === 'REVIEW_PENDING') {
      return {
        color: 'bg-yellow-200 text-yellow-900',
        name: 'Review Pending',
      };
    } else if (status === 'REVIEW_COMPLETED') {
      return {
        color: 'bg-green-300 text-green-900',
        name: 'Review Completed',
      };
    } else if (status === 'RESOLVED') {
      return {
        color: 'bg-green-200 text-green-900',
        name: 'Resolved',
      };
    } else if (status === 'ACKNOWLEDGED') {
      return {
        color: 'bg-blue-100 text-blue-900',
        name: 'Acknowledged',
      };
    } else if (status === 'EMERGENCY') {
      return {
        color: 'bg-red-200 text-red-900',
        name: 'Emergency',
      };
    } else if (status === 'ROAD_ACCIDENT') {
      return {
        color: 'bg-red-200 text-red-900',
        name: 'Road Accident',
      };
    } else if (status === 'MISBEHAVIOUR_BY_CO_PASSENGER') {
      return {
        color: 'bg-orange-200 text-orange-900',
        name: 'MISBEHAVIOUR BY CO-PASSENGER',
      };
    } else if (status === 'MEDICAL_ASSISTANCE') {
      return {
        color: 'bg-blue-100 text-blue-900',
        name: 'MEDICAL ASSISTANCE',
      };
    } else if (status === 'BRAKE_DOWN') {
      return {
        color: 'bg-yellow-200 text-yellow-900',
        name: 'Break Down',
      };
    } else if (status === 'DISPUTE_WITH_DRIVER') {
      return {
        color: 'bg-blue-200 text-blue-900',
        name: 'DISPUTE WITH DRIVER',
      };
    } else if (status === 'PENDING') {
      return {
        color: 'bg-yellow-200 text-yellow-900',
        name: 'PENDING',
      };
    } else if (status === 'REJECTED') {
      return {
        color: 'bg-red-200 text-red-900',
        name: 'REJECTED',
      };
    } else if (status === 'APPROVED') {
      return {
        color: 'bg-green-100 text-green-900',
        name: 'APPROVED',
      };
    } else if (status === 'PAID') {
      return {
        color: 'bg-blue-200 text-blue-900',
        name: 'PAID',
      };
    } else if (status === 'Active') {
      return {
        color: 'bg-green-100 text-green-900',
        name: 'Active',
      };
    }else if (status === 'ACTIVE') {
      return {
        color: 'bg-green-100 text-green-900',
        name: 'Active',
      };
    }
     else if (status === 'Archive') {
      return {
        color: 'bg-gray-200 text-gray-900',
        name: 'Archive',
      };
    } else if (status === true) {
      return {
        color: 'bg-green-100 text-green-900',
        name: 'Active',
      };
    } else if (status === false) {
      return {
        color: 'bg-red-200 text-red-900',
        name: 'Archive',
      };
    } else if (status === 'verified') {
      return {
        color: 'bg-green-100 text-green-900',
        name: 'Verified',
      };
    } else if (status === 'notVerified') {
      return {
        color: 'bg-red-100 text-red-900',
        name: 'Not Verified',
      };
    } else if (status === 'INITIAL') {
      return {
        color: 'bg-blue-100 text-blue-900',
        name: 'Initial',
      };
    } else if (status === 'VERIFIED') {
      return {
        color: 'bg-green-100 text-green-900',
        name: 'VERIFIED',
      };
    } else if (status === 'SYSTEM_CANCELED') {
      return {
        color: 'bg-red-200 text-red-900',
        name: 'System Canceled',
      };
    } else if (status === 'AVAILABLE') {
      return {
        color: 'bg-green-100 text-green-900',
        name: 'AVAILABLE',
      };
    }
  };

  const statusInfo = checkStatus(status);

  return (
    <React.Fragment>
      <span
        className={`uppercase text-[10px] tracking-widest font-bold rounded-md p-[6px] ${
          statusInfo?.color
        } ${className && className}`}
      >
        {statusInfo?.name.replace(/[_ ]/g, ' ')}
      </span>
    </React.Fragment>
  );
}
