import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { TbEdit } from 'react-icons/tb';
// import { AiFillDelete } from 'react-icons/ai';
// import { MdArchive } from 'react-icons/md';
import { FaRegEye } from 'react-icons/fa6';
import { FaRegTrashAlt } from 'react-icons/fa';

type ActionMenuProps = {
  row?: any;
  viewRoute?: any;
  editRoute?: any;
  viewModal?: any;
  modalData?: any;
  editModal?: any;
  onDeleteItem?: any;
  deleteAction?: any;
  setSelectedItem?: any;
  setDeleteVisible?: any;
};
export default function ActionMenu(props: ActionMenuProps) {
  const navigation = useNavigate();
  // console.log(props);

  return (
    <div className="flex items-center cursor-pointer justify-start gap-1">
      {props.viewRoute && (
        <FaRegEye
          className="w-6 h-6 mr-1 text-[#7c7c7c]"
          onClick={(event) => {
            const url = props.viewRoute.replace(
              ':id',
              props.row?.userId || props.row?.trip_id || props.row?.id
            );

            if (event.ctrlKey || event.metaKey) {
              // Open in a new tab if Ctrl (or Cmd) key is pressed
              window.open(url, '_blank');
            } else {
              // Normal navigation
              navigation(url);
            }
          }}
        />
      )}

      {/* {props.viewRoute && (
        <FaRegEye 
          className="w-6 h-6 mr-1 text-[#7c7c7c]"
          onClick={() =>
            navigation(
              props.viewRoute.replace(
                ':id',
                props.row?.userId ? props?.row?.userId : props?.row?.trip_id ?  props.row?.trip_id :  props.row?.id
              )
            )
          }
        />
      )} */}

      {props.viewModal && (
        <FaRegEye
          className="w-6 h-6 mr-1 text-[#7c7c7c]"
          onClick={() => [props.viewModal(true), props.modalData(props.row)]}
        />
      )}

      {props.editRoute && (
        <TbEdit
          className="w-6 h-6 mr-1 text-[#7c7c7c]"
          onClick={() =>
            navigation(props.editRoute.replace(':id', props.row?.id))
          }
        />
      )}

      {props.editModal && (
        <TbEdit
          className="w-6 h-6 mr-1 text-[#7c7c7c]"
          onClick={() => [props.editModal(true), props.modalData(props.row)]}
        />
      )}

      {props.deleteAction && (
        <FaRegTrashAlt
          className="w-5 h-5 mr-1 text-[#7c7c7c]"
          onClick={() => [
            props.setDeleteVisible(true),
            props.setSelectedItem(props?.row),
          ]}
        />
      )}
    </div>
  );
}
