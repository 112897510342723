import React from 'react';
import { Dialog } from 'primereact/dialog';

const Modal: React.FC<{
  isOpen: boolean;
  setISOpen: any;
  title: any;
  content: any;
}> = ({ isOpen, setISOpen, title, content }) => {
  return (
    <div>
      <Dialog
        visible={isOpen}
        onHide={() => setISOpen(false)}
        breakpoints={{ '960px': '75vw', '640px': '100vw' }}
        header={title}
        headerClassName="text-indigo-600 h-auto"
      >
        {content}
      </Dialog>
    </div>
  );
};

export default Modal;
