import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'lib/axios';
import { tripURL } from 'config';

import {
  loadTripRequested,
  loadTripSuccess,
  loadTripFail,
  loadDriverTripsFail,
  loadDriverTripsSuccess,
  loadDriverTripsRequested,
  loadPassengerTripsSuccess,
  loadPassengerTripsFail,
  loadPassengerTripsRequested,
} from './tripSlice';

function* tripEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  const { id } = action.payload;
  try {
    const { data } = yield call(axios.get, `${tripURL}trip-app/trip/${id}`);
    yield put(loadTripSuccess(data));
  } catch (error: any) {
    yield put(loadTripFail(error.message));
  }
}

function* driverTripEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.get,
      `${tripURL}trip-app/driver/${action?.payload?.id}/trip-histories?${
        action?.payload?.offset ? `offset=${action?.payload?.offset}` : ''
      }${action.payload.limit ? `&limit=${action?.payload?.limit}` : ''}`
    );
    yield put(loadDriverTripsSuccess(data));
  } catch (error: any) {
    yield put(loadDriverTripsFail(error.message));
  }
}

function* passengerTripEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  // console.log('passenger trip',action.payload)
  try {
    const { data } = yield call(
      axios.get,
      `${tripURL}trip-app/rider/${action?.payload?.id}/trip-histories?${
        action?.payload?.offset ? `offset=${action?.payload?.offset}` : ''
      }${action.payload.limit ? `&limit=${action?.payload?.limit}` : ''}`
    );
    // console.log('passenger trip',data)
    yield put(loadPassengerTripsSuccess(data));
  } catch (error: any) {
    yield put(loadPassengerTripsFail(error.message));
  }
}

export function* tripsSaga(): Generator<any, void, any> {
  yield takeLatest(loadTripRequested, tripEffect);
  yield takeLatest(loadDriverTripsRequested, driverTripEffect);
  yield takeLatest(loadPassengerTripsRequested, passengerTripEffect);
}
