import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'lib/axios';
import { paymentURL } from 'config';
import toast from 'react-hot-toast';

import {
  loadWithdrawalApproveRequested,
  loadwithdrawalRequestsSuccess,
  loadwithdrawalRequestsFail,
  loadWithdrawalRequestsRequested,
  loadWithdrawalApproveSuccess,
  loadWithdrawalApproveFail,
  loadWithdrawalSuccess,
  loadWithdrawalFail,
  loadWithdrawalRequested,
} from './withdrawalSlice';

function* loadwithdrawalRequestsEffect(action: {
  type: string;
  payload: {
    perPage: number;
    page: number;
    filters: any;
  };
}): Generator<any, void, any> {
  const { filters, perPage, page } = action.payload;

  try {
    
    const response = yield call(
      axios.get,
      `${paymentURL}payment_requests/filter?&offset=${page}&limit=${perPage}${filters?.userType ? `&user_type=${filters?.userType?.value}` :''}`
    );
    // console.log('payment url ', response);
    yield put(loadwithdrawalRequestsSuccess(response.data));
  } catch (error: any) {
    yield put(loadwithdrawalRequestsFail(error.message));
  }
}

function* withdrawalApproveEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    // console.log('payload', action?.payload);
    const { data } = yield call(
      axios.put,
      `${paymentURL}payment_requests/${action?.payload?.id}/paymentReqUpdate`,
      action.payload.values
    );

    if (data?.success) {
      toast.success(data?.message);
      yield put(loadWithdrawalApproveSuccess(data));
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(loadWithdrawalApproveFail(error.message));
  }
}

function* withdrawalEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.get,
      `${paymentURL}payment_requests/${action?.payload?.id}`
    );
    yield put(loadWithdrawalSuccess(data));
  } catch (error: any) {
    yield put(loadWithdrawalFail(error.message));
  }
}

export function* withdrawalSaga(): Generator<any, void, any> {
  yield takeLatest(
    loadWithdrawalRequestsRequested,
    loadwithdrawalRequestsEffect
  );
  yield takeLatest(loadWithdrawalApproveRequested, withdrawalApproveEffect);
  yield takeLatest(loadWithdrawalRequested, withdrawalEffect);
}
