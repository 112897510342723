import { takeLatest, put, call, takeEvery } from 'redux-saga/effects';
import axios from 'lib/axios';
import { apiURL } from 'config';
import toast from 'react-hot-toast';

import {
  loadModelsFail,
  loadModelsRequested,
  loadModelsSuccess,
  updateModelRequested,
  updateModelSuccess,
  updateModelFail,
  storeModelSuccess,
  storeModelFail,
  storeModelRequested,
  deleteModelSuccess,
  deleteModelFail,
  deleteModelRequested,
  loadNonPaginatedModelsSuccess,
  loadNonPaginatedModelsRequested,
  loadNonPaginatedModelsFail,
} from './modelSlice';

function* loadModelSaga(action: {
  type: string;
  payload: {
    filters: any;
    perPage: number;
    page: number;
  };
}): Generator<any, void, any> {
  const { filters } = action.payload;
  try {
    const response = yield call(
      axios.get,
      `${apiURL}vehicleModels/model-filter?pageSize=${action?.payload?.perPage ?? 100}&pageCount=${
        action?.payload?.page ?? 1
      }${filters?.query ? '&search=' + filters?.query : ''}${
        filters?.defaultCabtype?.id
          ? '&cabType=' + filters?.defaultCabtype?.id
          : ''
      }${
        filters.brand?.vehicleBrandName
          ? '&vehicleBrandName=' + filters.brand?.vehicleBrandName
          : ''
      }`
    );
    yield put(loadModelsSuccess(response.data));
  } catch (error: any) {
    yield put(loadModelsFail(error.message));
  }
}

function* loadNonpaginationEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  const { filters } = action.payload;
  try {
    const response = yield call(
      axios.get,
      `${apiURL}vehicleModels?pageSize=${action?.payload?.perPage ?? 100}&pageCount=${
        action?.payload?.page ?? 1
      }${filters?.query ? '&search=' + filters?.query : ''}`
    );
    yield put(loadNonPaginatedModelsSuccess(response.data));
  } catch (error: any) {
    yield put(loadNonPaginatedModelsFail(error.message));
  }
}

function* updateModelEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.put,
      `${apiURL}vehicleModel`,
      action.payload
    );
    yield put(updateModelSuccess(data));

    if (data?.success) {
      toast.success(data?.message);
      yield put(loadModelsRequested({ filters: '', perPage: 1000, page: 1 }));
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(updateModelFail(error.message));
  }
}

function* storeModelEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const response = yield call(
      axios.post,
      `${apiURL}vehicleModel`,
      action.payload
    );
    yield put(storeModelSuccess(response.data));
    if (response?.data) {
      toast.success(response?.data?.message);
      yield put(loadModelsRequested({ filters: '', perPage: 100, page: 1  }));
    }
  } catch (error: any) {
    yield put(storeModelFail(error.message));
  }
}

function* deleteModelEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.put,
      `${apiURL}vehicleModel/active?id=${action?.payload?.item?.id}`,
      action.payload.values
    );
    if (data?.success) {
      toast.success(data?.message);
      yield put(deleteModelSuccess(data));
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(deleteModelFail(error.message));
  }
}

export function* modelsSaga(): Generator<any, void, any> {
  yield takeEvery(loadModelsRequested, loadModelSaga);
  yield takeEvery(loadNonPaginatedModelsRequested, loadNonpaginationEffect);
  yield takeLatest(updateModelRequested, updateModelEffect);
  yield takeLatest(storeModelRequested, storeModelEffect);
  yield takeLatest(deleteModelRequested, deleteModelEffect);
}








