import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  error: null,
  topUps: [],
  topUpById: {},
  paginationOption: {},
  topUp: null,
  createdTopUp: null,
};

const topUpSlice = createSlice({
  name: 'topUp',
  initialState,
  reducers: {
    loadTopUpRequested(
      state,
      action: PayloadAction<{ filters: any; perPage: number; page: number }>
    ) {
      state.loading = true;
      state.error = null;
    },
    loadTopUpSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.topUps = action.payload;
      state.paginationOption = {
        pageNumber: action.payload?.pageNumber,
        pageSize: 10,
        totalRecords: action.payload?.totalRecords,
      };
    },

    loadTopUpFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    loadTopUpByIdRequested(state, action: PayloadAction<{ id: any }>) {
      state.loading = true;
      state.error = null;
    },
    loadTopUpByIdSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.topUpById = action.payload;
    },

    loadTopUpByIdFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    createTopUpRequest(
      state,
      action: PayloadAction<{
        values: {
          userType: string;
          cabId?: string;
          userId: string;
          amount: any;
        };
      }>
    ) {
      state.createdTopUp = null;
      state.loading = true;
      state.error = null;
      state.topUp = null;
    },

    createTopUpSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.createdTopUp = action.payload;
    },

    createTopUpFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadTopUpRequested,
  loadTopUpSuccess,
  loadTopUpFail,
  loadTopUpByIdRequested,
  loadTopUpByIdSuccess,
  loadTopUpByIdFail,
  createTopUpRequest,
  createTopUpSuccess,
  createTopUpFail,
} = topUpSlice.actions;

export default topUpSlice.reducer;
