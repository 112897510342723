import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  error: null,
  offers: [],
  createOffer: '',
  updateOffer: null,
  offer: {},
};

const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    loadOffersRequested(state, action) {
      state.loading = true;
      state.error = null;
    },
    loadOffersSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.offers = action.payload;
    },

    loadOffersFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    createOfferssRequested(
      state,
      action: PayloadAction<{
        values: {
          cabType: string;
          userType: string;
          title: string;
          description: string;
          startDate: string;
          endDate: string;
        };
        offerImage: any;
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    createOfferssSuccess(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = null;
      state.createOffer = action.payload;
    },
    createOfferssFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    updateOfferRequested(
      state,
      action: PayloadAction<{
        id: any;
        values: {
          cabType: string;
          userType: string;
          title: string;
          description: string;
          startDate: string;
          endDate: string;
        };
        offerImage: any;
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    updateOfferSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.updateOffer = action.payload;
    },
    updateOfferFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    deleteOfferRequested(
      state,
      action: PayloadAction<{
        item: any;
      }>
    ) {
      state.loading = false;
      state.error = null;
      state.offer = action.payload;
    },
    deleteOfferSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.offer = action.payload;
    },

    deleteOfferFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadOffersRequested,
  loadOffersSuccess,
  loadOffersFail,
  createOfferssRequested,
  createOfferssSuccess,
  createOfferssFail,
  updateOfferRequested,
  updateOfferSuccess,
  updateOfferFail,
  deleteOfferRequested,
  deleteOfferSuccess,
  deleteOfferFail,
} = offersSlice.actions;

export default offersSlice.reducer;
