import { takeLatest, put, call, takeEvery } from 'redux-saga/effects';
import axios from 'lib/axios';
import { apiURL } from 'config';
import toast from 'react-hot-toast';

import {
  loadAlertsRequested,
  loadAlertsSuccess,
  loadAlertsFail,
  loadActivityLogsSuccess,
  loadActivityLogsFail,
  loadActivityLogsRequested,
  storeActivityLogRequested,
  storeActivityLogSuccess,
  storeActivityLogFail,
  loadAlertsSummaryRequested,
  loadAlertsSummarySuccess,
  loadAlertsSummaryFail
} from './alertSlice';

function* loadAlertsEffect(action: {
  type: string;
  payload: {
    filters: any;
    perPage: number;
    page: number;
  };
}): Generator<any, void, any> {
  try {
    const response = yield call(
      axios.get,
      `${apiURL}alert/get-all?offset=${action.payload.page}&limit=${action.payload.perPage}`
    );

    yield put(loadAlertsSuccess(response.data));
  } catch (error: any) {
    yield put(loadAlertsFail(error.message));
  }
}

function* loadActivityLogsEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.get,
      `${apiURL}alert/${action?.payload?.id}/activity-log`
    );

    yield put(loadActivityLogsSuccess(data));
  } catch (error: any) {
    yield put(loadActivityLogsFail(error.message));
  }
}

function* storeActivityLogsEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.post,
      `${apiURL}alert/${action?.payload?.alert_id}/status?user_id=${action?.payload.user_id}&action=${action?.payload.action}&resolve_reason=${action?.payload.resolved_reason}`
    );

    if (data?.success) {
      toast.success(data?.message);
      yield put(storeActivityLogSuccess(data));
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(storeActivityLogFail(error.message));
  }
}
function* loadAlertsSummaryEffect(action: {
  type: string;
  payload: {
    actionType: any;
  };
}): Generator<any, void, any> {
  try {
    const response = yield call(
      axios.get,
      `${apiURL}alert/count?actionType=${action.payload.actionType}`
    );

    yield put(loadAlertsSummarySuccess({data: response.data, actionType:action.payload.actionType}));
  } catch (error: any) {
    yield put(loadAlertsSummaryFail(error.message));
  }
}

export function* alertsSaga(): Generator<any, void, any> {
  yield takeEvery(loadAlertsRequested, loadAlertsEffect);
  yield takeLatest(loadActivityLogsRequested, loadActivityLogsEffect);
  yield takeLatest(storeActivityLogRequested, storeActivityLogsEffect);
  yield takeEvery(loadAlertsSummaryRequested, loadAlertsSummaryEffect);
}
