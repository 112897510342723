import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'lib/axios';
import toast from 'react-hot-toast';
import { paymentURL } from 'config';

import {
  createTopUpFail,
  createTopUpRequest,
  createTopUpSuccess,
  loadTopUpByIdFail,
  loadTopUpByIdRequested,
  loadTopUpByIdSuccess,
  loadTopUpFail,
  loadTopUpRequested,
  loadTopUpSuccess,
} from './topUpSlice';



function* loadTopUpEffect(action: {
  type: string;
  payload: {
    perPage: number;
    page: number;
    filters: any;
  };
}): Generator<any, void, any> {
  const { filters, perPage, page } = action.payload;
  // console.log(filters);
  try {
    const response = yield call(
      axios.get,
      `${paymentURL}transactions/filter?transactionType=TOP_UP&offset=${page}&limit=${perPage}${
        filters?.userType ? `&user_type=${filters?.userType?.value}` : ''
      }`
    );
    yield put(loadTopUpSuccess(response.data));
  } catch (error: any) {
    yield put(loadTopUpFail(error.message));
  }
}

function* topUpEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.get,
      `${paymentURL}transactions/${action?.payload?.id}`
    );
    yield put(loadTopUpByIdSuccess(data));
  } catch (error: any) {
    yield put(loadTopUpByIdFail(error.message));
  }
}

function* adminTopUpEffect(action: {
  payload: any;
}): Generator<any, void, any> {
  // console.log(action?.payload);
  try {
    const { data } = yield call(
      axios.post,
      `${paymentURL}wallets/admin/top_up?user_id=${action?.payload?.values?.id}&amount=${action?.payload?.values?.amount}&user_type=${action?.payload?.values?.userType}${action?.payload?.values?.cabId ? `&cab_id=${action?.payload?.values?.cabId}` : ''}`,
    );

    if(data?.success){
      toast.success(data?.message);
    } else{
      toast.error(data?.message);
    }
    yield put(createTopUpSuccess(data));
  } catch (error: any) {
    yield put(createTopUpFail(error.message));
    toast.error('Something went wrong! Check the user and try again.')
  }
}

export function* topUpSaga(): Generator<any, void, any> {
  yield takeLatest(loadTopUpRequested, loadTopUpEffect);
  yield takeLatest(loadTopUpByIdRequested, topUpEffect);
  yield takeLatest(createTopUpRequest, adminTopUpEffect);
}
