import * as React from 'react';

export interface IButtonProps {
  name: any;
  onClick?: () => void;
  type?: any;
  disabled?: boolean;
  loading?: boolean;
  className?: any;
}

export default function Button(props: IButtonProps) {
  return (
    <div>
      <button
        className={
          props.className
            ? props.className
            : `text-xs text-white bg-gray-800 text-center font-[500] p-2 w-[100px] rounded-md cursor-pointer`
        }
        onClick={props.onClick}
        type={props.type}
        disabled={props.disabled}
      >
        {props.name}
      </button>
    </div>
  );
}
