import React, { useMemo } from 'react';

export interface IUploadProps {
  name: string;
  label?: string;
  onChange?: any;
  values?: any;
  errors?: any;
  touches?: any;
  onBlur?: any;
  disabled?: boolean;
  autoFocus?: any;
  className?: any;
  placeholder?: any;
}

export default function Upload(props: IUploadProps) {
  const isInvalid = useMemo(() => {
    return props?.touches[props?.name] && props?.errors[props?.name];
  }, [props?.touches, props?.errors, props?.name]);

  return (
    <div className="flex flex-col justify-start items-start gap-2">
      <label
        htmlFor={props.name}
        className="text-[11px] font-bold text-indigo-900"
      >
        {props.label}
      </label>

      <input
        disabled={props.disabled}
        className="w-full text-black text-sm bg-gray-100 file:cursor-pointer cursor-pointer file:border-0 file:py-2 file:px-4 file:mr-4 file:bg-gray-800 file:hover:bg-gray-700 file:text-white rounded"
        id={props?.name}
        placeholder={props.placeholder}
        name={props?.name}
        onChange={props?.onChange}
        autoFocus={props?.autoFocus}
        onBlur={props?.onBlur}
        type="file"
        accept="image/*"
      />

      {isInvalid ? (
        <p className="text-red-500 font-[600] text-[12px]">
          {props?.errors[props?.name]}
        </p>
      ) : null}
    </div>
  );
}
