import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'lib/axios';
import { apiURL, locationURL, paymentURL, tripURL } from 'config';
import toast from 'react-hot-toast';
//import * as XLSX from 'xlsx';

import {
  loadDriversRequested,
  loadDriversSuccess,
  loadDriversFail,
  loadDriverSuccess,
  loadDriverFail,
  loadDriverRequested,
  updateDriverFail,
  updateDriverRequested,
  updateDriverSuccess,
  storeDriverRequested,
  storeDriverSuccess,
  storeDriverFail,
  driverDocumentsVerificationSuccess,
  driverDocumentsVerificationFail,
  driverDocumentsVerificationRequested,
  driverVerificationSuccess,
  driverVerificationFail,
  driverVerificationRequested,
  deleteDriverSuccess,
  deleteDriverFail,
  deleteDriverRequested,
  updateDriverDocumentSuccess,
  updateDriverDocumentFail,
  updateDriverDocumentRequested,
  loadDriverWithdrawalSuccess,
  loadDriverWithdrawalFail,
  loadDriverWithdrawalRequested,
  loadDriverTopUpSuccess,
  loadDriverTopUpFail,
  loadDriverTopUpRequested,
  loadDriverReferrerCodeSuccess,
  loadDriverReferrerCodeFail,
  loadDriverReferrerCodeRequested,
  loadNonPaginationDriversSuccess,
  loadNonPaginationDriversFail,
  loadNonPaginationDriversRequested,
  updateStandPremiumDocumentRequested,
  loadStandPremiumDocumentFail,
  loadStandPremiumDocumentSuccess,
  loadStandPremiumDocumentRequest,
  updateStandPremiumDetailsSuccess,
  updateStandPremiumDocumentSuccess,
  updateStandPremiumDocumentFail,
  updateStandPremiumDetailsFail,
  updateStandPremiumDetailsRequested,
  verifyStandPremiumDetailsFail,
  verifyStandPremiumDetailsSuccess,
  verifyStandPremiumDetailsRequested,
  loadPremiumDriversSuccess,
  loadPremiumDriversRequested,
  loadDriverAttendanceRequested,
  loadDriverAttendanceSuccess,
  loadDriverAttendanceFail,
  loadDriverPayableAmountRequested,
  loadDriverPayableAmountSuccess,
  loadDriverPayableAmountFail,
  loadDriversSummarySuccess,
  loadDriversSummaryFail,
  loadDriversSummaryRequested,
  loadDriverTripCountFail,
  loadDriverTripCountSuccess,
  loadDriverTripCountRequested,
  createOfferRequest,
  createOfferSuccess,
  createOfferFail,
  loadDriverTransactionSummaryRequested,
  loadDriverTransactionSummarySuccess,
  loadDriverTransactionSummaryFail,
  loadDriverExportPdfRequested,
  loadDriverExportPdfFail,
  loadDriverExportPdfSuccess,
  loadDriverExportExcelRequested,
  loadDriverExportExcelFail,
  loadDriverExportExcelSuccess,
  loadDriverLiveLocationRequested,
  loadDriverLiveLocationSuccess,
  loadDriverLiveLocationFail,
} from './driverSlice';

function* driversEffect(action: {
  type: string;
  payload: {
    filters: any;
    perPage: number;
    page: number;
  };
}): Generator<any, void, any> {
  const { filters } = action.payload;
  try {
    console.log(action.payload);

    const { data } = yield call(
      axios.get,

      `${apiURL}users/filter?userType=DRIVER&page=${
        action.payload.page
      }&pageSize=${action.payload.perPage}&pageCount=${action.payload.page}${
        filters.query ? '&search=' + filters.query : ''
      }${
        filters?.provincePlace
          ? '&province=' + filters?.provincePlace?.value
          : ''
      }${
        filters?.districtPlace
          ? '&district=' + filters?.districtPlace?.value
          : ''
      }${
        filters?.cityPlace?.value ? '&city=' + filters?.cityPlace?.value : ''
      }${filters.cabType?.value ? '&cabType=' + filters?.cabType?.value : ''}${
        filters?.driverStatus?.id
          ? '&driverStatus=' + filters?.driverStatus?.id
          : ''
      }${filters.verify?.id ? '&verified=' + filters?.verify?.id : ''}`
    );

    yield put(loadDriversSuccess(data));
  } catch (error: any) {
    yield put(loadDriversFail(error.message));
  }
}

function* premiumDriversEffect(action: {
  type: string;
  payload: {
    filters: any;
    perPage: number;
    page: number;
  };
}): Generator<any, void, any> {
  const { filters } = action.payload;
  try {
    const { data } = yield call(
      axios.get,

      `${apiURL}stand_premium/filter?page=${action.payload.page}&pageSize=${
        action.payload.perPage
      }&pageCount=${action.payload.page}${
        filters.premiumStatus?.id ? '&status=' + filters?.premiumStatus?.id : ''
      }${
        filters?.provincePlace?.label
          ? '&province=' + filters?.provincePlace?.label
          : ''
      }${
        filters?.districtPlace?.label
          ? '&district=' + filters?.districtPlace?.label
          : ''
      }&pageCount=${action.payload.page}${
        filters.premiumStatus?.id ? '&status=' + filters?.premiumStatus?.id : ''
      }`
    );

    yield put(loadPremiumDriversSuccess(data));
  } catch (error: any) {
    yield put(loadPremiumDriversSuccess(error.message));
  }
}

function* nonPaginationDriversEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(axios.get, `${apiURL}drivers`);

    yield put(loadNonPaginationDriversSuccess(data));
  } catch (error: any) {
    yield put(loadNonPaginationDriversFail(error.message));
  }
}

function* driverEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.get,
      // `${apiURL}admin/driver/${action?.payload?.id}`
      `${apiURL}driver/${action?.payload?.id}`
    );
    yield put(loadDriverSuccess(data));
  } catch (error: any) {
    yield put(loadDriverFail(error.message));
  }
}

function* updateDriverEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  action.payload.values.allowedVehicleTypes = [
    action.payload.values.allowedVehicleTypes.value,
  ];
  try {
    const { data } = yield call(
      axios.put,
      `${apiURL}driver/${action?.payload?.id}/driver-profile-update`,
      action.payload.values
    );

    if (data?.success) {
      toast.success(data?.message);
      yield put(updateDriverSuccess(data));
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(updateDriverFail(error.message));
  }
}

function* updateDriverDocumentEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  const {
    addressVerifyDoc,
    addressBackImg,
    licenceVerifyDoc,
    licenceBackImg,
    driverProfileImg,
  } = action.payload;
  try {
    const formData = new FormData();
    formData.append('driverProfileImg', driverProfileImg);
    formData.append('addressVerifyDoc', addressVerifyDoc);
    formData.append('addressBackImg', addressBackImg);
    formData.append('licenceVerifyDoc', licenceVerifyDoc);
    formData.append('licenceBackImg', licenceBackImg);

    const { data } = yield call(
      axios.put,
      `${apiURL}driver/update?id=${action?.payload?.id}`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );

    if (data?.success) {
      toast.success(data?.message);
      yield put(updateDriverDocumentSuccess(data));
      yield put(loadDriversRequested({ filters: '', perPage: 10, page: 1 }));
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(updateDriverDocumentFail(error.message));
  }
}

function* storeDriverEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  const {
    values,
    addressVerifyDoc,
    addressBackImg,
    licenceVerifyDoc,
    licenceBackImg,
    driverProfileImg,
  } = action.payload;
  try {
    const formData = new FormData();
    formData.append('addressVerifyDoc', addressVerifyDoc);
    formData.append('addressBackImg', addressBackImg);
    formData.append('licenceVerifyDoc', licenceVerifyDoc);
    formData.append('licenceBackImg', licenceBackImg);
    formData.append('driverProfileImg', driverProfileImg);
    formData.append('driver', JSON.stringify(values));

    const { data } = yield call(axios.post, `${apiURL}driver/admin`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (data?.success) {
      toast.success(data?.message);
      yield put(storeDriverSuccess(data));
      yield put(loadDriversRequested({ filters: '', perPage: 10, page: 1 }));
    } else {
      toast.error(data?.message);
      yield put(storeDriverFail(data?.message));
    }
  } catch (error: any) {
    yield put(storeDriverFail(error.message));
  }
}
function* updateStandPremiumDocumentEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  // console.log('updateStandPremiumDocumentEffect', action);
  try {
    const formData = new FormData();
    formData.append(
      'membershipFrontImage',
      action?.payload?.membershipFrontImage
    );
    // console.log('formData', formData);
    const { data } = yield call(
      axios.post,
      `${apiURL}stand_premium/doc/upload?user_id=${action?.payload?.user_id}&cab_id=${action?.payload?.cab_id}&stand_premium_policy_id=${action?.payload?.standPremiumId}`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );

    if (data?.success) {
      toast.success(data?.message);
      yield put(updateStandPremiumDocumentSuccess(data));
      yield put(loadDriverRequested({ id: action?.payload?.user_id }));
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(updateStandPremiumDocumentFail(error.message));
  }
}

function* loadStandPremiumDocumentEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.get,
      `${apiURL}stand_premium/user_id?user_id=${action?.payload?.user_id}&image=${action?.payload?.image}`
    );
    // console.log(action);
    yield put(loadStandPremiumDocumentSuccess(data));
  } catch (error: any) {
    yield put(loadStandPremiumDocumentFail);
  }
}

function* updateStandPremiumDetailsEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  // console.log(action.payload);
  try {
    const formData = new FormData();
    formData.append('standPremium', action?.payload?.standPremium);
    const { data } = yield call(
      axios.put,
      `${apiURL}stand_premium/update?id=${action?.payload?.id}`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
    if (data?.success) {
      toast.success(data?.message);
      yield put(updateStandPremiumDetailsSuccess(data));
      yield put(loadDriverRequested({ id: action?.payload?.user_id }));
      yield put(
        loadStandPremiumDocumentRequest({
          user_id: action?.payload?.user_id,
          image: true,
        })
      );
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(updateStandPremiumDetailsFail(error.message));
  }
}

function* driverDocumentsVerificationEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.put,
      `${apiURL}users/${action?.payload?.id}/document_verify`,
      action.payload.values
    );

    if (data?.success) {
      toast.success(data?.message);
      yield put(driverDocumentsVerificationSuccess(data));
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(driverDocumentsVerificationFail(error.message));
  }
}

function* standPremiumVerificationEffect(action: {
  type: string;
  payload: {
    id: string;
    reasonForStatus: any;
    status: any;
    user_id: any;
  };
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.put,
      `${apiURL}stand_premium/${action?.payload?.id}/document_verify`,
      {
        reasonForStatus: action?.payload?.reasonForStatus,
        status: action?.payload?.status,
      }
    );
    if (data?.success) {
      toast.success(data?.message);
      yield put(verifyStandPremiumDetailsSuccess(data));
      yield put(loadDriverRequested({ id: action?.payload?.user_id }));
      yield put(
        loadStandPremiumDocumentRequest({
          user_id: action?.payload?.user_id,
          image: true,
        })
      );
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(verifyStandPremiumDetailsFail(error.message));
  }
}

function* driverVerificationEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.put,
      `${apiURL}driver/${action?.payload?.id}/verified`
    );

    if (data?.success) {
      toast.success(data?.message);
      yield put(driverVerificationSuccess(data));
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(driverVerificationFail(error.message));
  }
}

function* deleteDriverEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.put,
      `${apiURL}users/active?id=${action?.payload?.item?.id}`,
      action.payload.values
    );

    if (data?.success) {
      toast.success(data?.message);
      yield put(deleteDriverSuccess(data));
    } else {
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(deleteDriverFail(error.message));
  }
}

function* driverWithdrawalEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  const { id } = action.payload;

  try {
    const { data } = yield call(
      axios.get,
      `${paymentURL}payment_requests/filter?user_id=${id}`
    );
    yield put(loadDriverWithdrawalSuccess(data));
  } catch (error: any) {
    yield put(loadDriverWithdrawalFail(error.message));
  }
}

function* driverTopUpEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  const { id } = action.payload;

  try {
    const { data } = yield call(
      axios.get,
      `${paymentURL}transactions/filter?user_id=${id}&transactionType=TOP_UP`
    );
    yield put(loadDriverTopUpSuccess(data));
  } catch (error: any) {
    yield put(loadDriverTopUpFail(error.message));
  }
}

function* driverReferrerCodeEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  const { id } = action.payload;

  try {
    const { data } = yield call(
      axios.get,
      `${paymentURL}referrer_code/filter?referrer_id=${id}`
    );
    yield put(loadDriverReferrerCodeSuccess(data));
  } catch (error: any) {
    yield put(loadDriverReferrerCodeFail(error.message));
  }
}

function* driverAttendanceEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  const { id, date } = action.payload;

  try {
    const { data } = yield call(
      axios.get,
      `${apiURL}driver/totalOnlineTimeSlots?driverId=${id}&date=${date}`
    );
    console.log(data);
    yield put(loadDriverAttendanceSuccess(data));
  } catch (error: any) {
    yield put(loadDriverAttendanceFail(error.message));
  }
}
function* loadDriversSummaryEffect(action: {
  type: string;
  payload: {
    actionType: any;
  };
}): Generator<any, void, any> {
  try {
    // debugger
    const response = yield call(
      axios.get,
      `${apiURL}driver/count${
        action.payload.actionType ? `?status=${action.payload.actionType}` : ''
      }`
    );

    yield put(
      loadDriversSummarySuccess({
        data: response.data,
        actionType: action.payload.actionType,
      })
    );
  } catch (error: any) {
    yield put(loadDriversSummaryFail(error.message));
  }
}

function* driverPayableAmountEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  const { id } = action.payload;

  try {
    const { data } = yield call(axios.get, `${paymentURL}wallets/${id}`);
    yield put(loadDriverPayableAmountSuccess(data));
  } catch (error: any) {
    yield put(loadDriverPayableAmountFail(error.message));
  }
}

function* driverTripCountEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axios.get,
      `${tripURL}trip-app/trip/trip-count?user_id=${action?.payload?.user_id}&user_type=DRIVER`
    );
    const total =
      (data?.CANCELLED || 0) +
      (data?.COMPLETED || 0) +
      (data?.EXPIRED || 0) +
      (data?.SYSTEM_CANCELED || 0);
    yield put(loadDriverTripCountSuccess(total));
  } catch (error: any) {
    yield put(loadDriverTripCountFail(error.message));
  }
}

function* driverOfferEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  console.log(action.payload);
  const { values } = action.payload;

  try {
    const { data } = yield call(
      axios.put,
      `${paymentURL}wallets/trip-offer-top-up?user_id=${values.user_id}&cab_id=${values.cab_id}&amount=${values.amount}&phone_no=${values.phone_no}&message=${values.message}`
    );
    if (data?.success) {
      yield put(createOfferSuccess(data));
      toast.success(data?.message);
    } else {
      yield put(createOfferFail(data.message));
      toast.error(data?.message);
    }
  } catch (error: any) {
    yield put(createOfferFail(error.message));
  }
}

function* driverTransactionSummaryEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  const { id } = action.payload;

  try {
    const { data } = yield call(
      axios.get,
      `${paymentURL}transactions/filter?user_id=${id}`
    );
    console.log(data);
    yield put(loadDriverTransactionSummarySuccess(data));
  } catch (error: any) {
    yield put(loadDriverTransactionSummaryFail(error.message));
  }
}

function* driverExportPdfEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  //const {values} = action.payload;

  try {
    const { data } = yield call(
      axios.get,
      `${apiURL}users/drivers-details/pdf-generate?userType=DRIVER&accountDeleted=false`,
      { responseType: 'blob' }
    );

    // Create a URL for the PDF
    const url = window.URL.createObjectURL(new Blob([data]));

    // Create a link element
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'driver_export.pdf'); // Set the file name

    // Append to body
    document.body.appendChild(link);
    link.click(); // Simulate click to download

    // Cleanup
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    console.log(data);
    yield put(loadDriverExportPdfSuccess(data));
  } catch (error: any) {
    yield put(loadDriverExportPdfFail(error.message));
  }
}
function* driverExportExcelEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  //const {values} = action.payload;

  try {
    const { data } = yield call(
      axios.get,
      `${apiURL}users/drivers-details/pdf-generate?userType=DRIVER&accountDeleted=false`,
      { responseType: 'arraybuffer' }
    );

    // Create a URL for the PDF
    const url = window.URL.createObjectURL(new Blob([data]));

    // Create a link element
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'driver_export.pdf'); // Set the file name

    // Append to body
    document.body.appendChild(link);
    link.click(); // Simulate click to download

    // Cleanup
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    console.log(data);
    yield put(loadDriverExportExcelSuccess(data));
  } catch (error: any) {
    yield put(loadDriverExportExcelFail(error.message));
  }
}

function* driverLiveLocationEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  const { id } = action.payload;
 
  try {
    const { data } = yield call(
      axios.get,
      `${locationURL}locations/getLocationByUserId?userId=${id}&userType=DRIVER`
    );
    console.log(data);
    yield put(loadDriverLiveLocationSuccess(data));
  } catch (error: any) {
    yield put(loadDriverLiveLocationFail(error.message));
  }
}

export function* driversSaga(): Generator<any, void, any> {
  yield takeLatest(loadDriversRequested, driversEffect);
  yield takeLatest(loadPremiumDriversRequested, premiumDriversEffect);
  yield takeLatest(
    loadNonPaginationDriversRequested,
    nonPaginationDriversEffect
  );
  yield takeLatest(updateDriverRequested, updateDriverEffect);
  yield takeLatest(loadDriverRequested, driverEffect);
  yield takeLatest(storeDriverRequested, storeDriverEffect);
  yield takeLatest(updateDriverDocumentRequested, updateDriverDocumentEffect);
  yield takeLatest(
    driverDocumentsVerificationRequested,
    driverDocumentsVerificationEffect
  );
  yield takeLatest(driverVerificationRequested, driverVerificationEffect);
  yield takeLatest(deleteDriverRequested, deleteDriverEffect);
  yield takeLatest(loadDriverWithdrawalRequested, driverWithdrawalEffect);
  yield takeLatest(loadDriverTopUpRequested, driverTopUpEffect);
  yield takeLatest(loadDriverReferrerCodeRequested, driverReferrerCodeEffect);
  yield takeLatest(
    updateStandPremiumDocumentRequested,
    updateStandPremiumDocumentEffect
  );
  yield takeLatest(
    loadStandPremiumDocumentRequest,
    loadStandPremiumDocumentEffect
  );
  yield takeLatest(
    updateStandPremiumDetailsRequested,
    updateStandPremiumDetailsEffect
  );
  yield takeLatest(
    verifyStandPremiumDetailsRequested,
    standPremiumVerificationEffect
  );
  yield takeLatest(loadDriverAttendanceRequested, driverAttendanceEffect);
  yield takeLatest(loadDriverPayableAmountRequested, driverPayableAmountEffect);
  yield takeLatest(loadDriversSummaryRequested, loadDriversSummaryEffect);
  yield takeLatest(loadDriverTripCountRequested, driverTripCountEffect);
  yield takeLatest(createOfferRequest, driverOfferEffect);
  yield takeLatest(
    loadDriverTransactionSummaryRequested,
    driverTransactionSummaryEffect
  );
  yield takeLatest(loadDriverExportPdfRequested, driverExportPdfEffect);
  yield takeLatest(loadDriverExportExcelRequested, driverExportExcelEffect);
  yield takeLatest(loadDriverLiveLocationRequested, driverLiveLocationEffect);
}
