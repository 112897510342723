import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface stateState {
  filters: any;
}

const initialState: stateState = {
  filters: {},
};

const stateSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    updateFilters: (
      state,
      action: PayloadAction<{
        driverStatus: any;
        cabType: any;
        brand: any;
        model: any;
        province: any;
        district: any;
        query: any;
        userType: any;
      }>
    ) => {
      // debugger
      console.log(action.payload);
      state.filters = action.payload;
    },
    clearFilters: (state) => {
      state.filters = {};
    },
  },
});

export const { updateFilters, clearFilters } = stateSlice.actions;

export default stateSlice.reducer;
