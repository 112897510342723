import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  error: null,
  banners: [],
  createBanner: '',
  banner: {},
};

const bannersSlice = createSlice({
  name: 'banner',
  initialState,
  reducers: {
    loadBannersRequested(state, action) {
      state.loading = true;
      state.error = null;
    },
    loadBannersSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.banners = action.payload;
    },

    loadBannersFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    createBannerRequested(
      state,
      action: PayloadAction<{
        bannerImage: any;
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    createBannerSuccess(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = null;
      state.createBanner = action.payload;
    },
    createBannerFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    deleteBannerRequested(
      state,
      action: PayloadAction<{
        item: any;
      }>
    ) {
      state.loading = true;
      state.error = null;
      state.banner = action.payload;
    },
    deleteBannerSuccess(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = null;
      state.banner = action.payload;
    },
    deleteBannerFail(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadBannersRequested,
  loadBannersSuccess,
  loadBannersFail,
  createBannerRequested,
  createBannerSuccess,
  createBannerFail,
  deleteBannerRequested,
  deleteBannerSuccess,
  deleteBannerFail,
} = bannersSlice.actions;

export default bannersSlice.reducer;
