import * as routes from 'constants/routes';
import { MdWbIridescent, MdCrisisAlert, MdPriceChange } from 'react-icons/md';
import { FaUsersGear, FaTrophy, FaBuilding } from 'react-icons/fa6';
import { RiHomeGearLine } from 'react-icons/ri';
import { IoCarSportSharp } from 'react-icons/io5';
import { FaCar } from 'react-icons/fa';
import { TbBrandBumble, TbBoxModel } from 'react-icons/tb';
import { BiSolidOffer } from 'react-icons/bi';
import { IoIosPeople } from 'react-icons/io';
import { RiAdminFill } from 'react-icons/ri';
import { AiFillSound } from 'react-icons/ai';
import { MdCancel } from 'react-icons/md';
import { BsDatabaseFillLock } from 'react-icons/bs';
import { GiVerticalBanner } from 'react-icons/gi';
import { BiMoneyWithdraw } from 'react-icons/bi';
import { LiaMedalSolid } from 'react-icons/lia';
import { GiCash } from 'react-icons/gi';
import { FaUserLock } from 'react-icons/fa';
import { MdWorkspacePremium } from 'react-icons/md';
import { TbUserStar } from 'react-icons/tb';
//<FontAwesomeIcon icon="fa-solid fa-trophy" />

const Columns = [
  {
    name: 'Home',
    icon: RiHomeGearLine,
    to: routes.HOME,
  },

  {
    name: 'Drivers',
    icon: IoCarSportSharp,
    to: routes.DRIVERS,
  },
  {
    name: 'Premium Drivers',
    icon: TbUserStar,
    to: routes.PREMIUM_DRIVERS,
  },
  {
    name: 'Passengers',
    icon: IoIosPeople,
    to: routes.PASSENGERS,
  },
  {
    name: 'Subscription Plan',
    icon: MdWorkspacePremium,
    to: routes.SUBSCRIPTION_PLAN,
  },
  {
    name: 'Subscription Plan User',
    icon: MdWorkspacePremium,
    to: routes.SUBSCRIPTION_PLAN_USER,
  },

  {
    name: 'Pricing',
    icon: MdPriceChange,
    to: routes.PRICING,
  },

  {
    name: 'Role',
    icon: RiAdminFill,
    to: routes.ROLE,
  },

  {
    name: 'Alerts',
    icon: MdCrisisAlert,
    to: routes.ALERTS,
  },

  {
    name: 'Rides History',
    icon: MdWbIridescent,
    to: routes.RIDES,
  },
  {
    name: 'Schedule Ride',
    icon: MdWbIridescent,
    to: routes.SHEDULE_RIDE,
  },

  {
    name: 'Admin',
    icon: FaUsersGear,
    to: routes.USERS,
  },
  {
    name: 'Cabs',
    icon: FaCar,
    to: routes.CABS,
  },
  {
    name: 'Challenges',
    icon: LiaMedalSolid,
    to: routes.CHALLENGES,
  },
  {
    name: 'Challenge Users',
    icon: FaTrophy,
    to: routes.CHALLENGES_USERS,
  },
  {
    name: 'Withdrawal Requests',
    icon: BiMoneyWithdraw,
    to: routes.WITHDRAWAL,
  },
  {
    name: 'TopUp',
    icon: GiCash,
    to: routes.TOPUP,
  },
  {
    name: 'Permissions',
    icon: FaUserLock,
    to: routes.PERMISSIONS,
  },

  {
    name: 'Master Data',
    icon: BsDatabaseFillLock,
    subMenu: [
      {
        name: 'Brand',
        icon: TbBrandBumble,
        to: routes.BRAND,
      },
      {
        name: 'Model',
        icon: TbBoxModel,
        to: routes.MODEL,
      },
      {
        name: 'Banner',
        icon: GiVerticalBanner,
        to: routes.BANNER,
      },
      {
        name: 'Offers',
        icon: BiSolidOffer,
        to: routes.OFFERS,
      },
      {
        name: 'Promotions',
        icon: AiFillSound,
        to: routes.PROMOTIONS,
      },
      {
        name: 'Cancellation',
        icon: MdCancel,
        to: routes.CANCELLATION,
      },
      {
        name: 'Stand Premium',
        icon: MdWorkspacePremium,
        to: routes.STANDPREMIUM,
      },
      {
        name: 'D.S Office',
        icon:  FaBuilding,
        to: routes.D_S_OFFICE,
      },
    ],
  },
];
export default Columns;
